.main-slider {
	padding-top: 0;
	padding-bottom: 0;
	.fw-slider-header {
		background-color: $white;
		position: absolute;
		width: 427px;
		height: 96px;
		bottom: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		h5 {
			margin-bottom: 0;
			color: $darkblue;
			@include letter-spacing(160);
		}
		@include bdown(md) {
			width: 350px;
			height: 80px;
		}
		@include bdown(sm) {
			width: 300px;
		}
		@include bdown(xs) {
			width: 250px;
			height: 70px;
		}
	}
	.fw-slider-social {
		position: absolute;
		right: 0;
		top: 20%;
		width: 34px;
		height: 207px;
		display: flex;
		flex-direction: column;
		row-gap: 0.5rem;
		z-index: 5;

		&-icon {
			background-color: $olive;
			display: flex;
			justify-content: center;
			align-items: center;
			color: $white;
			font-size: rem(14);
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			&:hover {
				filter: brightness(120%);
			}
		}
	}
}

.services {
	padding-top: 0;
	padding-bottom: 0;
	&-wrapper {
		display: flex;
		@include bdown(md) {
			display: block;
		}

		&-left {
			flex-basis: 35%;
			background-color: $olive;
			background-image: url(../../images/icons/marxlayne.svg);
			color: $white;
			background-repeat: no-repeat;
			background-position: bottom;
			padding-top: 3rem;
			padding-left: 1rem;
			padding-right: 1rem;
			padding-bottom: 6rem;
			@include bdown(md) {
				padding-bottom: 4rem;
				
			}

			&.service-left-paddings {
				padding: 3rem 1rem;
				display: flex;
				align-items: center;
				h2 {
					@include letter-spacing(-47);
				}
				.services-wrapper-left-container {
					max-width: 525px;
				}
			}

			&-container {
				max-width: 405px;
				margin-left: auto;
				margin-right: auto;
			}
			&-image {
				text-align: end;
				@include bdown(md) {
					width: 50%;
					margin-left: auto;
				}
			}
		}
		&-right {
			flex-basis: 65%;
			background-color: rgba($color: $gray, $alpha: 0.4);
			padding: 3rem 2rem 2rem;

			@include bdown(md) {
				flex-basis: 60%;
			}
			@include bdown(xs) {
				padding-left: 1rem;
				padding-right: 1rem;
			}
			.service-right-paddings {
				max-width: 820px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin: auto;
			}
			&-header {
				color: $darkblue;
				h5 {
					@include letter-spacing(160);
				}
			}
			&-wrapper {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				gap: 1rem;
				@include bdown(nm) {
					grid-template-columns: repeat(2, 1fr);
				}
				@include bdown(xs) {
					grid-template-columns: repeat(1, 1fr);
				}
			}
			&-item {
				position: relative;
				&::before {
					content: "";
					background: rgba($color: $olive, $alpha: 0.72);
					position: absolute;
					height: 100%;
					width: 100%;
					opacity: 0;
					top: 0;
					left: 0;
					@include transition;
				}
				&:hover {
					opacity: 1 !important;
					&::before {
						opacity: 1 !important;
					}
				}
				img {
					width: 100%;
					height: 100%;
				}
				&-header {
					position: absolute;
					padding-right: 1rem;
					top: 50%;
					right: 0;
					max-width: 200px;
					transform: translateY(-50%);
				}
			}
			&-link {
				text-align: end;
				margin-top: 2rem;
				color: $grayalt;
				font-size: rem(18);
				&::after {
					content: "\e902";
					font-family: $icomoon;
					vertical-align: middle;
					display: inline-block;
					font-family: $icomoon;
					font-size: rem(10);
					@include transition;
					padding-left: 0.2rem;
				}
			}
		}
	}
	&-logo-inner {
		h5 {
			position: relative;
			z-index: 5;
			font-size: rem(29);
			transition: all 0.4s linear;
			margin-bottom: 0;
			padding: 0.5rem;
		}

		&:hover {
			background-color: $olive;
			h5 {
				color: $white;
			}
			&:after {
				opacity: 1;
			}
		}
		&:after {
			content: "";
			opacity: 0;
			display: block;
			background-image: url(../../images/inner-logo.svg);
			transition: all 0.4s linear;
			background-size: 80% auto;
			background-position: 50%;
			position: absolute;
			left: 0;
			width: 100%;
			top: 0;
			height: 100%;
			background-repeat: no-repeat;
		}
		@include bdown(md){
			height: 0;
			padding-top: 25%;
			padding-bottom: 25%;
		}
	}
}

.industries {
	background-color: $gray;
	&-header {
		text-align: center;
		color: $darkblue;
	}
}

.response {
	padding-top: 0;
	padding-bottom: 0;
	&-wrapper {
		background-image: url(../../images/response-slider/background.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		padding-top: 3rem;
		padding-bottom: 3rem;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}
}

.reasons {
	background: linear-gradient(to top, #fdfdfd, $gray);
	padding-top: 0;
	padding-bottom: 0;

	&-wrapper {
		max-width: 1920px;
		padding-left: 4rem;
		display: flex;
		justify-content: space-between;
		margin: auto;
		@include bdown(hg) {
			padding-left: 2rem;
		}
		@include bdown(md) {
			flex-wrap: wrap;
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}
	}
	&-list {
		display: flex;
		column-gap: 1rem;
		@include bdown(nm) {
			display: block;
		}
		@include bdown(md) {
			margin: auto;
			order: 1;
		}
		@include bdown(sm) {
			display: block;
			margin: auto;
		}
		&-col {
			border-right: 1px solid #e2e2e2;
			display: flex;
			// align-items: center;
			padding-top: 3rem;
			padding-bottom: 3rem;
			@include bdown(hg) {
				// align-items: flex-start;
			}
			@include bdown(nm) {
				padding-top: 2rem;
				padding-bottom: 2rem;
				border-right: none;
			}
			@include bdown(md) {
				padding-top: 1rem;
				padding-bottom: 1rem;
			}

			@include bdown(sm) {
				display: block;
				border-right: none;
				padding-top: 0;
				padding-bottom: 0;
			}
			&:last-child {
				border-right: none;
			}
			&-inside {
				display: flex;
				flex-direction: column;
				row-gap: 2rem;
				@include bdown(nm) {
					flex-direction: row;
				}
				@include bdown(sm) {
					display: block;
				}
			}
		}
		&-item {
			max-width: 335px;
			display: flex;
			align-items: flex-start;
			margin-right: 1rem;
			margin-left: 1rem;
			column-gap: 1rem;
			flex-basis: 50%;
			@include bdown(sm) {
				margin-top: 1rem;
				margin-bottom: 1rem;
			}
			&-image {
				margin-top: 0.5rem;
				flex-basis: 15%;
				display: flex;
				justify-content: center;
				color: #1b1b1b;
			}
			&-info {
				flex-basis: 85%;
				p {
					margin-bottom: 0;
				}
				&-header {
					p {
						color: $darkblue;
						font-size: rem(28);
						@include letter-spacing(-47);
					}
				}
				&-text {
					font-size: rem(17);
					color: #939393;
				}
			}
		}
	}
	&-image {
		flex-grow: 1;
		@include bdown(md) {
			flex-grow: 0;
			margin-left: auto;
		}
		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
			@include bdown(md) {
				height: auto;
				width: auto;
			}
		}
	}
}

.process {
	background-color: $olive;
	background-image: url(../../images/icons/marxlayne.svg);
	background-repeat: no-repeat;
	background-position: right 4rem top 4rem;
	padding-bottom: 0;
	&-header-wrapper {
		max-width: 500px;

		&-text {
			font-size: rem(17);
			color: $gray2;
		}
	}
}

.combine-header {
	h2,
	h3 {
		display: inline-block;
		@include letter-spacing(-47);
	}
	h2 {
		margin-right: 0.5rem;
	}
}

.team {
	background-color: $darkblue;
	padding-top: 9rem;
	@include bdown(md) {
		padding-top: 7rem;
	}
	@include bdown(xs) {
		padding-top: 5rem;
	}
	&-flexbox {
		display: flex;
		justify-content: space-between;
		column-gap: 1rem;
		padding-left: 2rem;
		padding-right: 0.5rem;
		@include bdown(lg) {
			padding-left: 1rem;
		}
		@include bdown(md) {
			display: block;
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}
		.process-header-wrapper {
			flex-basis: 18%;
			@include bdown(md) {
				padding-left: 2rem;
				margin-bottom: 2rem;
			}
			@include bdown(xs) {
				padding-left: 1rem;
			}
		}
	}
}

.news {
	position: relative;
	background-image: url(../../images/news-bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;

	&.studies {
		background-image: none;
		background-color: $gray3;
		&::before {
			opacity: 0.02;
		}
		.news-wrapper-col1 {
			flex-basis: 40%;
			.news-wrapper-col1-item-image {
				position: relative;
				display: flex;
				img {
					flex-grow: 1;
				}
			}
			.news-wrapper-col1-item-btn {
				position: absolute;
				right: 0;
				bottom: 0;
				&:hover {
					border: 1px solid $base;
					background-color: $base;
					color: $white;
					&::after {
						color: $white;
					}
				}
			}
		}
		.news-wrapper-col2 {
			flex-basis: 60%;
		}
	}
	&::before {
		content: "";
		background-image: url(../../images/vertical-marx.svg);
		opacity: 0.01;
		background-repeat: no-repeat;
		position: absolute;
		width: 25%;
		bottom: 0;
		top: 0;
		left: 0;
	}
	&-wrapper {
		position: relative;
		z-index: 5;
		display: flex;
		justify-content: space-between;
		column-gap: 1rem;
		@include bdown(sm) {
			display: block;
		}
		&-col1 {
			display: flex;
			flex-basis: 45%;
			flex-grow: 1;
			margin-bottom: 1rem;
			&-item {
				flex-grow: 1;
				display: flex;
				&-link {
					display: flex;
					flex-direction: column;
					flex-grow: 1;
				}
				&-info {
					position: relative;
					flex-grow: 1;
					background-color: $white;
					padding: 2rem;
					&-header {
						color: $darkblue;
						font-size: rem(24);
					}
					&-date {
						font-size: rem(15);
						font-style: italic;
						color: $olive;
					}
					&-text {
						font-size: rem(17);
						color: $base;
					}
					&-btn {
						position: absolute;
						left: 50%;
						bottom: 0;
						transform: translate(-50%, 50%);
						&:hover {
							border: 1px solid $base;
							background-color: $base;
							color: $white;
							&::after {
								color: $white;
							}
						}
					}
				}
			}
		}
		&-col2 {
			flex-basis: 45%;
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 1rem;
			gap: 1rem;
			@include bdown(sm) {
				justify-content: space-between;
			}
			@include bdown(xs) {
				display: block;
			}

			&-item {
				flex-basis: 45%;
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				@include bdown(xs) {
					margin-bottom: 1rem;
				}

				&:hover {
					.news-wrapper-col2-item-image {
						opacity: 1 !important;
						&::before,
						&::after {
							opacity: 1 !important;
						}
					}
					.news-wrapper-col2-item-info {
						background-color: $base;
						&-text {
							p {
								color: $white;
							}
						}
						&-date {
							color: $yellow;
						}
					}
				}
				&-image {
					position: relative;
					display: flex;
					&::before {
						content: "";
						background: rgba($color: $olive, $alpha: 0.72);
						position: absolute;
						height: 100%;
						width: 100%;
						opacity: 0;
						top: 0;
						left: 0;
						@include transition;
					}
					&::after {
						content: "\e904";
						font-family: $icomoon;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 73px;
						height: 73px;
						background-color: $green;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						opacity: 0;
						color: $white;
						font-size: rem(30);
						@include transition;
					}

					img {
						flex-grow: 1;
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				&-info {
					flex-grow: 1;
					@include transition;
					background-color: $white;
					padding: 1rem;
					p {
						@include transition;
						margin-bottom: 0.5rem;
					}
					&-text {
						p {
							color: $darkblue;
							@include letter-spacing(0);
						}
					}
					&-date {
						font-size: rem(15);
						font-style: italic;
						color: $olive;
					}
				}
			}
		}
	}
}

.history {
	position: relative;
	background-image: url(../../images/history-slider/history-background.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 7rem;
	padding-bottom: 5rem;

	&:before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: rgba($color: $base, $alpha: 0.44);
	}
	&-header {
		position: absolute;
		top: 0;
		left: 0;
		padding-top: 1.8rem;
		padding-bottom: 1.8rem;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		width: 35%;
		background-color: $base;
		color: $white;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		@include bdown(md) {
			width: 40%;
		}
		@include bdown(xs) {
			width: fit-content;
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
		h5 {
			margin-bottom: 0;
			@include letter-spacing(160);
		}
	}
	&-wrapper {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		z-index: 5;
		max-width: 1550px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 1rem;
		padding-right: 1rem;
		@include bdown(md) {
			display: block;
		}
		@include bdown(xs) {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}
		&-leftside {
			flex-basis: 55%;
			@include bdown(md) {
				max-width: 80%;
				margin: auto;
			}
			@include bdown(sm) {
				max-width: 100%;
			}
		}

		&-rightside {
			flex-basis: 45%;
			max-width: 400px;
			text-align: end;
			color: $white;
			@include bdown(md) {
				margin-left: auto;
				margin-right: 3rem;
				margin-top: 3rem;
			}
			&-text {
				font-size: rem(30);
				p {
					line-height: 1.1;
					@include letter-spacing(-47);
					margin-bottom: 0.5rem;
				}
			}
			&-sign {
				font-size: rem(24);
				color: $yellow2;
				p {
					@include letter-spacing(-77);
				}
			}
		}
	}
}

.inside-history {
	background-color: rgba($color: $gray, $alpha: 0.4);
	&-wrapper {
		max-width: 1920px;
		margin: auto;
	}
	&-flexbox {
		display: flex;
		justify-content: space-between;
		@include bdown(md) {
			display: block;
		}
		&-col1 {
			flex-basis: 40%;

			display: flex;
			img {
				flex-grow: 1;
				width: 100%;
				object-fit: cover;
			}
		}
		&-col2 {
			flex-basis: 60%;
			padding: 3rem 5rem;
			@include bdown(nm) {
				padding: 2rem;
			}
			@include bdown(xs) {
				padding: 2rem 1rem;
			}
			h2 {
				@include letter-spacing(-47);
				color: $darkblue;
			}
			h5 {
				@include letter-spacing(160);
				color: $darkblue;
			}
			&-container {
				max-width: 750px;
				margin: auto;
			}
			&-header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 5rem;
				h2 {
					margin-bottom: 0;
				}
				@include bdown(sm) {
					display: block;
					margin-bottom: 2rem;
					h2 {
						margin-bottom: 2rem;
					}
				}
			}
		}
	}
}

.responsibility {
	position: relative;
	background-color: $olive;
	padding-top: 6rem;
	&-wrapper {
		color: $white;
		display: flex;
		justify-content: space-between;
		column-gap: 1rem;
		margin-bottom: 2rem;
		@include bdown(sm) {
			display: block;
		}
		&-header {
			flex-basis: 35%;
			display: flex;
			align-items: center;
			@include bdown(sm) {
				margin-top: 2rem;
				margin-bottom: 2rem;
			}
		}
		&-text {
			flex-basis: 65%;
		}
	}
}

.opportunities {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 5rem;
	padding-bottom: 5rem;
	@include bdown(md) {
		background-image: none !important;
		background-color: $gray;
	}
	&-wrapper {
		margin-top: 2rem;
		max-width: 700px;
		h2,
		h3,
		h5 {
			color: $darkblue;
		}

		h2,
		h3 {
			@include letter-spacing(-47);
		}
		h2 {
			margin-bottom: 0.5rem;
		}
		h5 {
			@include letter-spacing(160);
			margin-bottom: 1.5rem;
		}
		&-link {
			max-width: 560px;
			display: flex;
			justify-content: space-between;

			@include bdown(xs) {
				display: inline-block;
				a {
					margin-top: 2rem;
				}
			}
		}
	}
}

.expertise {
	padding-bottom: 3rem;
	&-bg {
		background-color: $olive;
		background-image: url(../../images/icons/marxlayne.svg);
		background-repeat: no-repeat;
		background-position: bottom left 10%;
	}
	&-wrapper {
		color: $white;
		padding-top: 3rem;
		padding-bottom: 3rem;
		padding-left: 1rem;
		padding-right: 1rem;
		max-width: 1350px;
		margin: auto;
		display: flex;
		justify-content: space-between;
		column-gap: 1rem;
		@include bdown(sm) {
			display: block;
		}
		@include bdown(xs) {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}
		&-header {
			flex-basis: 30%;
			display: flex;
			flex-direction: column;

			justify-content: center;
			h2 {
				@include letter-spacing(-47);
				margin-bottom: 0;
			}
		}
		&-info {
			flex-basis: 70%;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}
	.masonry > div > a {
		height: 0;
		padding-top: 50%;
	}

	.services-wrapper-right-item {
		&:hover {
			.services-wrapper-right-item-header {
				color: $white;
				&::after,
				&::before {
					opacity: 1;
				}
			}
		}
		
		.services-wrapper-right-item-header {
			max-width: none;
			position: absolute;
			z-index: 5;
			// padding-right: 1rem;
			padding-left: .5rem;
			padding-right: .5rem;
			top: 50%;
			left: 0;
			right: 0;
			// height: 117px;
			transform: translateY(-50%);
			display: flex;
			// justify-content: flex-end;
			align-items: center;
			justify-content: center;
			@include transition;
			// @include bdown(md) {
			// 	padding-left: 90px;
			// }
			h5 {
				z-index: 5;
				margin-bottom: 0;
			}
			// &::before {
			// 	content: "";
			// 	position: absolute;
			// 	top: 50%;
			// 	background-color: rgba($color: $base, $alpha: 0.69);
			// 	left: 0;
			// 	right: 0;
			// 	height: 117px;
			// 	transform: translateY(-50%);
			// 	opacity: 0;
			// 	@include transition;
			// 	@include bdown(md) {
			// 		height: 80px;
			// 	}
			// }
			// &::after {
			// 	content: "\e904";
			// 	font-family: $icomoon;
			// 	display: flex;
			// 	justify-content: center;
			// 	align-items: center;
			// 	width: 117px;
			// 	height: 117px;
			// 	background-color: $green;
			// 	position: absolute;
			// 	top: 50%;
			// 	left: 0%;
			// 	transform: translateY(-50%);
			// 	opacity: 0;
			// 	color: $white;
			// 	font-size: rem(32);
			// 	@include transition;
			// 	@include bdown(md) {
			// 		width: 80px;
			// 		height: 80px;
			// 	}
			// }
		}
	}
}

.areas {
	background-color: $base;
}

.case {
	padding-top: 0;
	padding-bottom: 0;
	&-wrapper {
		padding-top: 2rem;
		padding-bottom: 2rem;
		position: relative;
		background-color: $gray;

		&::before {
			content: "";
			background-image: url(../../images/vertical-marx.svg);
			opacity: 0.02;
			background-repeat: no-repeat;
			background-size: contain;
			position: absolute;
			width: 25%;
			bottom: 50%;
			top: 0;
			left: 0;
		}
	}
	&-list {
		position: relative;
		z-index: 5;
		max-width: 1055px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-bottom: 2rem;

		&-container {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 0.5rem;
			font-size: rem(18);
			padding-left: 0.5rem;
			padding-right: 0.5rem;
			@include transition;
			&:after {
				content: "";
				position: absolute;
				width: 2px;
				top: 20%;
				bottom: 20%;
				right: 0;
				background-color: $yellow;
				transform: translateX(50%);
			}
			&:last-child {
				&:after {
					content: "";
					background-color: transparent;
				}
			}
			a {
				transition: none !important;
			}
			&.case-list-active-link {
				background-color: $yellow;
				color: $white;
			}
			&:hover {
				background-color: $yellow;
				color: $white;
			}
		}
	}
	&-new {
		max-width: 1700px;
		margin-left: auto;
		margin-right: auto;
		.flex-wrapper {
			display: flex;
			@include bdown(md) {
				display: block;
			}
		}
		&-dual {
			max-width: 1250px;
			margin-left: auto;
			margin-right: auto;
			display: flex;
			justify-content: space-between;
			column-gap: 1rem;
			padding-top: 2rem;
			margin-bottom: 2rem;
			@include bdown(sm) {
				display: block;
			}
			&-link {
				display: flex;
				flex-direction: column;
				max-width: 570px;
				flex-grow: 1;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 2rem;

				&-image {
					position: relative;
					display: flex;
					img {
						width: 100%;
					}
				}
				&-btn {
					position: absolute;
					right: 0;
					bottom: 0;
					&:hover {
						border: 1px solid $base;
						background-color: $base;
						color: $white;
						&::after {
							color: $white;
						}
					}
				}
				&-date {
					position: absolute;
					right: 0;
					bottom: 0;
					background-color: $olive;
					color: $white;
					font-size: rem(15);
					font-style: italic;
					padding: 0.4rem 2rem;
				}
				&-tag {
					position: absolute;
					left: 0;
					top: 0;
					display: flex;
					column-gap: 1rem;
					color: $white;
					font-size: rem(15);
					font-style: italic;
					display: flex;
					column-gap: 1rem;
					&-block {
						background-color: $darkblue;
						padding: 0.4rem 2rem;
						@include bdown (xs) {
							padding-left: 1rem;
							padding-right: 1rem;
						}
					}
				}
			}
		}
		&-banner {
			margin-right: 1rem;
			text-align: center;
			@include bdown(md) {
				margin-right: 0;
			}
			@include bdown(sm) {
				max-width: 570px;
				margin: auto;
			}
			&-item {
				color: $white;
				background-color: $olive;
				writing-mode: vertical-lr;
				transform: rotate(180deg);
				max-width: 141px;
				height: 628px;
				padding: 0 2rem;
				display: flex;
				align-items: center;
				justify-content: center;
				h1 {
					margin-bottom: 0;
				}
				@include bdown(nm) {
					padding: 0 1.5rem;
				}
				@include bdown(md) {
					writing-mode: horizontal-tb;
					transform: rotate(0deg);
					height: auto;
					max-width: 100%;
					padding: 1rem;
				}
			}
		}
	}
}

.advantage {
	position: relative;
	background-image: url(../../images/background-advantage.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 11rem;
	padding-bottom: 11rem;
	&::after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: rgba($color: $base, $alpha: 0.91);
	}
	&.advantage-olive {
		&::after {
			content: "";
			background-color: rgba($color: $olive, $alpha: 0.91);
		}
	}

	@include bdown(md) {
		padding-top: 8rem;
		padding-bottom: 8rem;
	}
	&-wrapper {
		position: relative;
		z-index: 5;
		color: $white;
		display: flex;
		align-items: center;
		justify-content: space-between;
		column-gap: 1rem;
		@include bdown(md) {
			display: block;
		}
		.btn-large {
			width: 443px;
			height: 120px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: rem(33);
			&::after {
				font-size: rem(30);
			}
			@include bdown(nm) {
				width: 350px;
				height: 80px;
				font-size: rem(25);
				&::after {
					font-size: rem(20);
				}
			}
			@include bdown(sm) {
				width: auto;

				max-width: 350px;
				height: 70px;
			}
		}
		&-info {
			p {
				font-size: rem(28);
				@include letter-spacing(20);
				margin-bottom: 0;
				&:first-child {
					font-size: rem(59);
					@include letter-spacing(-47);
				}
			}
			@include bdown(md) {
				margin-bottom: 2rem;
				p {
					font-size: rem(20);

					&:first-child {
						font-size: rem(45);
					}
				}
			}
		}
	}
}

.thoughts {
	position: relative;
	background-color: $gray;
	&::before {
		content: "";
		background-image: url(../../images/vertical-marx.svg);
		opacity: 0.02;
		background-repeat: no-repeat;
		background-size: contain;
		position: absolute;
		width: 25%;
		bottom: 50%;
		top: 0;
		left: 0;
	}
	&-link {
		&-image {
			position: relative;
			&::before {
				content: "";
				background: rgba($color: $olive, $alpha: 0.72);
				position: absolute;
				height: 100%;
				width: 100%;
				opacity: 0;
				top: 0;
				left: 0;
				@include transition;
			}
			&::after {
				content: "\e904";
				font-family: $icomoon;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 73px;
				height: 73px;
				background-color: $green;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				opacity: 0;
				color: $white;
				font-size: rem(30);
				@include transition;
			}
			img {
				max-height: rem(350);
				object-fit: cover
			}
		}
		&-info {
			@include transition;
		}
		&:hover {
			.thoughts-link-image {
				opacity: 1 !important;
				&::before,
				&::after {
					opacity: 1 !important;
				}
			}
			.thoughts-link-info {
				background-color: $base;
				&-text,
				&-header {
					p,
					h5 {
						color: $white;
					}
				}
				&-date {
					color: $yellow;
				}
			}
		}
	}
}

.thoughts-inside {
	position: relative;
	background-color: $gray;
	padding-top: 14rem;
	&::before {
		content: "";
		background-image: url(../../images/vertical-marx.svg);
		opacity: 0.02;
		background-repeat: no-repeat;
		background-size: contain;
		position: absolute;
		width: 25%;
		bottom: 20%;
		top: 0;
		left: 0;
	}
	&-back-link {
		position: relative;
		font-size: rem(14);
		color: rgba($color: $base, $alpha: 0.5);
		&::before {
			content: "\e901";
			color: rgba($color: $base, $alpha: 0.5);
			padding-right: 0.4rem;
			vertical-align: middle;
			display: inline-block;
			font-family: $icomoon;
			font-size: rem(10);
			@include transition;
		}
	}
	&-wrapper {
		position: relative;
		z-index: 5;
		max-width: 1400px;
		margin: auto;
	}
	&-flexbox {
		display: flex;
		justify-content: center;
		column-gap: 1rem;
		margin-bottom: 2rem;
		@include bdown(md) {
			display: block;
		}
		&-transform {
			flex-grow: 1;
			transform: translateX(-5%);
			@include bdown(md) {
				transform: translateX(0);
			}
		}
		&-col1 {
			flex-basis: 58%;
			display: flex;
			img {
				flex-grow: 1;
				width: 100%;
				object-fit: cover;
			}
		}
		&-col2 {
			flex-basis: 42%;
			padding: 3rem 3rem 2rem 1rem;
			@include bdown(md) {
				padding: 3rem 1rem 0 1rem;
			}
			&-date {
				background-color: $olive;
				color: $white;
				font-size: rem(15);
				font-style: italic;
				max-width: fit-content;
				padding: 0.2rem 1.7rem;
				margin-bottom: 2rem;
				p {
					margin-bottom: 0;
				}
			}
			&-header {
				font-size: rem(35);
				color: $olive;
				line-height: 1;
				@include letter-spacing(0);
			}
		}
		@include bup(md){
			.media-wrapper {
				padding-bottom: 32%;
			}
		}
	}
	&-container {
		padding: 1rem;
		max-width: 1000px;
		margin-left: auto;
		margin-right: auto;
	}
	&-share {
		font-size: rem(30);
		color: $olive;
		@include letter-spacing(0);
		p {
			margin-bottom: 0;
		}
		@include bdown(md) {
			margin-bottom: 2rem;
		}
	}
	&-social {
		display: flex;
		column-gap: 1rem;
		align-items: center;
		justify-content: space-between;
		@include bdown(md) {
			justify-content: flex-start;
		}
		&-icon {
			background-color: $olive;
			display: flex;
			justify-content: center;
			align-items: center;
			color: $white;
			font-size: rem(25);
			height: 59px;
			width: 59px;
			&:hover {
				filter: brightness(120%);
			}
			@include bdown(md) {
				height: 50px;
				width: 50px;
			}
			@include bdown(xs) {
				height: 43px;
				width: 43px;
			}
		}
	}
	&-arrows {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 3rem;
		@include bdown(sm) {
			align-items: flex-start;
		}
		&-wrapper {
			display: flex;
			align-items: center;
			column-gap: 1rem;
			max-width: 550px;
			text-align: start;
			@include bdown(sm) {
				flex-wrap: wrap;
				&:last-child {
					justify-content: flex-end;
				}
			}
			p {
				flex-basis: 83%;
				margin-bottom: 0;
				font-size: rem(24);
				color: $darkblue;
				@include letter-spacing(0);
				@include bdown(sm) {
					order: 1;
				}
			}
			&:last-child {
				text-align: end;
			}
			.olive-prev-arrow {
				background-color: $olive;
				width: 75px;
				height: 73px;
				display: flex;
				align-items: center;
				justify-content: center;
				&::before {
					content: "\e901";
					font-family: $icomoon;
					color: $white;
					font-size: rem(20);
				}
				&:hover {
					filter: brightness(120%);
				}
				@include bdown(sm) {
					width: 65px;
					height: 63px;
					margin-bottom: 1rem;
				}
				@include bdown(xs) {
					width: 60px;
					height: 58px;
				}
			}

			.olive-next-arrow {
				background-color: $olive;
				width: 75px;
				height: 73px;
				display: flex;
				align-items: center;
				justify-content: center;
				&::before {
					content: "\e902";
					font-family: $icomoon;
					color: $white;
					font-size: rem(20);
				}
				&:hover {
					filter: brightness(120%);
				}
				@include bdown(sm) {
					width: 65px;
					height: 63px;
					margin-bottom: 1rem;
				}
				@include bdown(xs) {
					width: 60px;
					height: 58px;
				}
			}
		}
	}
}

.allnews {
	background-color: $gray;
	position: relative;
	&::after {
		content: "";
		background-image: url(../../images/background-news.jpg);
		background-repeat: no-repeat;

		position: absolute;
		right: 0;
		top: 0;
		left: 0;
		bottom: 0;
	}
	&-wrapper {
		position: relative;
		z-index: 5;
	}
}

.contact {
	padding-top: 0;
	padding-bottom: 0;
	&.talk {
		.contact-wrapper {
			align-items: flex-start;
			background-color: $gray;
			&-leftside {
				&-phone {
					h3 {
						margin-top: 2rem;
					}
				}
			}
			.contact-wrapper-rightside-container {
				padding-left: 1rem;
				max-width: 980px;
				.contact-form-row-item {
					max-width: 100%;
				}
				.contact-form-row-text {
					flex-basis: 12%;
				}
			}
		}
	}
	&-wrapper {
		display: flex;
		@include bdown(md) {
			display: block;
		}
		&-leftside {
			flex-basis: 33%;
			background-color: $olive;
			color: $white;
			padding: 3rem 1rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			p {
				margin-bottom: 0;
			}
			h2 {
				margin-bottom: 0.5rem;
				@include letter-spacing(-47);
			}
			&-phone {
				margin-bottom: 3rem;
				p {
					font-size: rem(43);
				}
			}
			&-adress {
				margin-bottom: 2rem;
			}
		}
		&-rightside {
			position: relative;

			flex-basis: 67%;
			&-social {
				position: absolute;
				left: 0;
				top: 20%;
				width: 34px;
				height: 207px;
				display: flex;
				flex-direction: column;
				row-gap: 0.5rem;
				z-index: 5;
				&-icon {
					background-color: $yellow;
					display: flex;
					justify-content: center;
					align-items: center;
					color: $white;
					font-size: rem(14);
					padding-top: 0.5rem;
					padding-bottom: 0.5rem;
					@include bdown(sm) {
						width: 34px;
						height: 34px;
					}
					&:hover {
						filter: brightness(120%);
					}
				}
				@include bdown(sm) {
					top: 0;
					right: 10%;
					left: auto;
					height: 34px;
					width: 207px;
					flex-direction: row;
					column-gap: 0.5rem;
				}
			}
			&-container {
				padding: 2rem 1rem 1rem 2.5rem;
				max-width: 935px;
				margin-left: auto;
				margin-right: auto;
				@include bdown(sm) {
					padding-left: 1rem;
				}
				@include bdown(xs) {
					padding-top: 3rem;
				}
				.contact-form {
					&-row {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						column-gap: 0.5rem;
						margin-bottom: 1.5rem;
						&:last-child {
							justify-content: flex-end;
						}
						@include bdown(sm) {
							display: block;
						}
						&-text {
							flex-basis: 15%;
							text-align: end;
							p {
								font-size: rem(22);
								font-style: italic;
								color: $olive;
								margin-bottom: 0;
								@include bdown(sm) {
									text-align: left;
									margin-top: 1rem;
									margin-bottom: 0.5rem;
								}
							}
						}
						&-item {
							flex-grow: 1;
							max-width: 725px;
							.selector-wrapper {
								position: relative;
								flex-basis: 42%;
								&:after {
									content: "\f078";
									font-family: "Font Awesome 5 Free";
									color: $white;
									font-weight: 600;
									position: absolute;
									height: rem(60);
									width: 58px;
									right: 3px;
									top: 3px;
									background-color: $yellow;
									display: flex;
									align-items: center;
									justify-content: center;
									font-size: rem(36);
									pointer-events: none;
									@include bdown(md) {
										width: 43px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.team-inside {
	padding-top: 0;
	padding-bottom: 0;
	&-wrapper {
		background-color: $darkblue;
		padding: 4rem 2rem;
		display: flex;
		column-gap: 1rem;
		justify-content: space-between;
		align-items: flex-start;
		@include bdown(md) {
			display: block;
		}
		&-leftside {
			flex-basis: 18%;
			margin-bottom: 2rem;
			p {
				color: $gray2;
				font-size: rem(17);
				margin-bottom: 2rem;
			}
			.btn {
				@include bdown(nm) {
					padding-left: 0.5rem !important;
					padding-right: 0.5rem !important;
				}
				&-large {
					@include bdown(nm) {
						min-width: 245px !important;
					}
				}
			}
		}
		&-rightside {
			flex-basis: 80%;
		}
	}
	&-card {
		&-image {
			display: flex;
			img {
				width: 100%;
				height: 100%;
			}
		}
		&-info {
			background-color: $whitealt;
			padding: 1rem;
			flex-grow: 1;
			p {
				margin-bottom: 0;
			}
			&-text {
				font-size: rem(25);
				color: $darkblue;
				@include letter-spacing(25);
			}
			&-sign {
				font-size: rem(17);
				color: $gray2;
			}
		}
	}
}

.internship {
	&-back-link {
		position: relative;
		font-size: rem(14);
		color: rgba($color: $base, $alpha: 0.5);
		&::before {
			color: $base;
			content: "\e901";
			padding-right: 0.4rem;
			vertical-align: middle;
			display: inline-block;
			font-family: $icomoon;
			font-size: rem(10);
			@include transition;
		}
	}
	.form-wrapper {
		max-width: 1050px;
		margin-left: auto;
		margin-right: auto;
		&-header {
			h2 {
				@include letter-spacing(-47);
				color: $darkblue;
				text-align: center;
			}
		}
	}
	&-form {
		&-row {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			column-gap: 1rem;
			margin-bottom: 1.5rem;

			@include bdown(sm) {
				display: block;
			}
			&-text {
				flex-basis: 17%;
				text-align: end;
				p {
					font-size: rem(22);
					font-style: italic;
					color: $olive;
					margin-bottom: 0;
					@include bdown(sm) {
						text-align: left;
						margin-top: 1rem;
						margin-bottom: 0.5rem;
					}
				}
			}
			&-item {
				flex-grow: 1;
				max-width: 870px;
				.selector-wrapper {
					position: relative;
					flex-basis: 42%;
					&:after {
						content: "\f078";
						font-family: "Font Awesome 5 Free";
						color: $white;
						font-weight: 600;
						position: absolute;
						height: 54px;
						width: 58px;
						right: 3px;
						top: 3px;
						background-color: $yellow;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: rem(36);
						pointer-events: none;
						@include bdown(md) {
							height: 41px;
							width: 43px;
						}
					}
				}
			}
		}
		&-upload {
			position: relative;
			cursor: pointer;
			display: inline-block;
			&.has-danger {
				border-bottom: 1px solid #f00 !important;
				// border-color: #f00 !important;
			}
			&-invis {
				position: absolute;
				width: 100%;
				height: 100%;
				opacity: 0;
				cursor: pointer;
				font-size: rem(0.01);
			}
		}
	}
}

.job {
	background-color: rgba($color: olive, $alpha: 0.15);
	&-vacancy {
		max-width: 900px;
		margin-bottom: 4rem;
		&.inside {
			max-width: none;
		}
		h2 {
			@include letter-spacing(-47);
			color: $darkblue;
			margin-bottom: 0;
		}

		h5 {
			@include letter-spacing(160);
			margin-bottom: 1.5rem;
			color: $olive;
		}
	}
}

.examples {
	background-color: $base;
	.hide-card {
		opacity: 0;
		@include transition;
	}
	&-flexbox {
		display: flex;
		column-gap: 1rem;
		justify-content: center;
		flex-wrap: wrap;
		&-item {
			position: relative;
			width: 230px;
			background-color: $grayalt;
			color: $white;
			font-size: rem(14);
			padding-top: 0.65rem;
			padding-bottom: 0.65rem;
			padding-left: 1rem;
			padding-right: 2.5rem;
			margin-bottom: 1rem;
			cursor: pointer;
			&:before {
				content: "\f068";
				font-family: $fontawesome;
				position: absolute;
				width: 37px;
				height: 37px;
				background-color: $olive;
				right: 0.5rem;
				top: 50%;
				transform: translateY(-50%);
				border-radius: 5px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: 600;
				font-size: rem(20);
				color: $white;
				@include bdown(md) {
					width: 30px;
					height: 30px;
				}
			}

			&.active {
				background-color: $yellow;
				color: $base;
				@include transition;
				&:before {
					content: "\f067";
				}
			}
			p {
				margin-bottom: 0;
				@include letter-spacing(20);
			}
		}
	}
}

.relations {
	background-color: rgba($color: olive, $alpha: 0.15);
	&-flexbox {
		display: flex;
		column-gap: 1rem;
		justify-content: space-between;
		@include bdown(md) {
			flex-direction: column;
		}
		&-col1 {
			flex-basis: 70%;
			@include bdown(md) {
				order: 1;
			}

			h2 {
				@include letter-spacing(-47);
				color: $darkblue;
			}
			h5 {
				@include letter-spacing(160);

				color: $darkblue;
			}
			.btn-olive-inverted {
				@include bdown(xxs) {
					padding-left: 0.5rem;
					padding-right: 0.5rem;
				}
			}
		}
		&-col2 {
			flex-basis: 30%;
			display: flex;
			flex-direction: column;
			align-items: center;
			row-gap: 1rem;
			@include bdown(md) {
				margin-bottom: 2rem;
			}
			&-text {
				max-width: 400px;
				text-align: center;
				p {
					@include letter-spacing(20);
					color: $black;
				}
			}
		}
	}
}

.hospitality {
	padding-top: 0;
	padding-bottom: 0;
	
	
	&-wrapper {
		max-width: 1920px;
		margin: auto;
	}
	&-flexbox {
		display: flex;
		justify-content: space-between;
		@include bdown(md) {
			display: block;
		}
		&-col1 {
			flex-basis: 40%;

			display: flex;
			img {
				flex-grow: 1;
				width: 100%;
				object-fit: cover;
			}
		}
		&-col2 {
			background-color: rgba($color: $gray, $alpha: 0.4);
			flex-basis: 60%;
			padding: 3rem 5rem;
			@include bdown(nm) {
				padding: 2rem;
			}
			@include bdown(xs) {
				padding: 2rem 1rem;
			}
			h2 {
				@include letter-spacing(-47);
				color: $darkblue;
			}
			h5 {
				@include letter-spacing(160);
				color: $darkblue;
			}
			&-container {
				max-width: 750px;
				margin: auto;
			}
			&-header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 5rem;
				h2 {
					margin-bottom: 0;
				}
				@include bdown(sm) {
					display: block;
					margin-bottom: 2rem;
					h2 {
						margin-bottom: 2rem;
					}
				}
			}
		}
	}
}

.case-strategy {
	padding-top: 0;
	padding-bottom: 0;
	margin-top: 3rem;
	
	&-wrapper {
		max-width: 1920px;
		margin: auto;
	}
	&-flexbox {
		display: flex;
		justify-content: space-between;
		@include bdown(md) {
			display: block;
		}
		&-col1 {
			flex-basis: 40%;

			display: flex;
			img {
				flex-grow: 1;
				width: 100%;
				object-fit: cover;
			}
		}
		&-col2 {
			background-color: rgba($color: $gray, $alpha: 0.4);
			flex-basis: 60%;
			padding: 3rem 5rem;
			@include bdown(nm) {
				padding: 2rem;
			}
			@include bdown(xs) {
				padding: 2rem 1rem;
			}
			h2 {
				@include letter-spacing(-47);
				color: $darkblue;
			}
			h5 {
				@include letter-spacing(160);
				color: $darkblue;
			}
			&-container {
				max-width: 750px;
				margin: auto;
			}
			&-header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 5rem;
				h2 {
					margin-bottom: 0;
				}
				@include bdown(sm) {
					display: block;
					margin-bottom: 2rem;
					h2 {
						margin-bottom: 2rem;
					}
				}
			}
		}
	}
}

.decades {
	padding-top: 0;
	padding-bottom: 0;
	&-flexbox {
		display: flex;
		justify-content: space-between;
		@include bdown(md) {
			display: block;
		}
		&-col1 {
			flex-basis: 60%;
			background-color: $base;
			padding: 3rem 2rem 3rem 5rem;
			color: $white;
			@include bdown(sm) {
				padding-left: 2rem;
			}
			@include bdown(xs) {
				padding-left: 1rem;
				padding-right: 1rem;
			}
		}
		&-col2 {
			background-color: $olive;
			flex-basis: 40%;
			padding: 3rem 1rem;
		}
	}
}

.digital {
	padding-top: 0;
	padding-bottom: 0;
	&-flexbox {
		display: flex;
		justify-content: space-between;
		background-color: rgba($color: #e8e8e8, $alpha: 0.55);
		@include bdown(md) {
			display: block;
		}
		&-col1 {
			flex-basis: 60%;

			&-paddings {
				padding: 3rem 2rem 3rem 5rem;
				ul {
					margin-bottom: 0;
					li {
						list-style-type: none;
						margin-left: 0;
					}
				}
				@include bdown(nm) {
					padding: 2rem;
				}
				@include bdown(xs) {
					padding: 2rem 1rem;
				}
				h2 {
					@include letter-spacing(-47);
				}
				h5 {
					@include letter-spacing(160);
				}
			}
		}
		&-col2 {
			flex-basis: 40%;
			display: flex;
			img {
				flex-grow: 1;
				object-fit: cover;
			}
		}
	}
}

.insight {
	background-color: $base;
	&-back-link {
		position: relative;
		font-size: rem(14);
		color: rgba($color: $gray, $alpha: 0.5);
		&::before {
			content: "\e901";
			color: rgba($color: $gray, $alpha: 0.5);
			padding-right: 0.4rem;
			vertical-align: middle;
			display: inline-block;
			font-family: $icomoon;
			font-size: rem(10);
			@include transition;
		}
	}
	&-block {
		color: $white;
		margin-bottom: 4rem;
		p {
			margin-left: 1rem;
		}
		h2 {
			@include letter-spacing(-47);
			color: $yellow3;
		}
		h5 {
			@include letter-spacing(160);
			color: $yellow3;
		}
	}
}

.case-gallery {
	padding-top: 0;
	padding-bottom: 0;
	background-color: $base;
	&-block {
		color: $white;
		position: relative;
		padding: 3rem 1rem;
		display: flex;
		justify-content: space-between;
		column-gap: 1rem;
		max-width: 1650px;
		margin-left: auto;
		margin-right: auto;
		h5 {
			margin-bottom: 0;
			&:last-child {
				text-align: end;
			}
			@include bdown(sm) {
				margin-bottom: 1rem;
			}
		}
		a {
			display: flex;
			align-items: center;
			column-gap: 1rem;
			@include bdown(sm) {
				flex-direction: column-reverse;
				align-items: flex-start;
			}
			&:last-child {
				@include bdown(sm) {
					flex-direction: column;
					align-items: flex-end;
				}
			}
			.olive-prev-arrow {
				background-color: $olive;
				width: 75px;
				height: 73px;
				display: flex;
				align-items: center;
				justify-content: center;

				&::before {
					content: "\e901";
					font-family: $icomoon;
					color: $white;
					font-size: rem(20);
				}
				&:hover {
					filter: brightness(120%);
				}

				@include bdown(sm) {
					width: 65px;
					height: 63px;
				}

				@include bdown(xs) {
					width: 60px;
					height: 58px;
				}
			}
			.olive-next-arrow {
				background-color: $olive;
				width: 75px;
				height: 73px;
				display: flex;
				align-items: center;
				justify-content: center;

				&::before {
					content: "\e902";
					font-family: $icomoon;
					color: $white;
					font-size: rem(20);
				}

				&:hover {
					filter: brightness(120%);
				}

				@include bdown(sm) {
					width: 65px;
					height: 63px;
				}

				@include bdown(xs) {
					width: 60px;
					height: 58px;
				}
			}
		}
	}
}

.wysiwyg {
	h2 {
		margin-bottom: 0;
	}
	ul {
		li {
			position: relative;
			list-style-type: none;
			margin-left: 2rem;
			margin-bottom: 0.3rem;
			&::before {
				content: "";
				position: absolute;
				width: 1.3rem;
				height: 0.15rem;
				left: -2rem;
				top: 15px;
				transform: translateY(-50%);
				background-color: $yellow;
				@include bdown(md) {
					top: 11px;
				}
			}
		}
	}
}

.wysiwyg-news {
	h5 {
		font-size: rem(23);
		color: #9fa87d;
		@include letter-spacing(20);
	}
}
