form {
	position: relative;
	input,
	select,
	textarea {
		&.has-danger {
			border-bottom: 1px solid #f00 !important;
			// border-color: #f00 !important;
		}
	}
	.input-wrapper {
		&.has-danger {
			border-color: #f00 !important;
			&:before,
			&:after {
				background-color: #f00 !important;
			}
		}
	}
	input[type="radio"].has-danger,
	input[type="checkbox"].has-danger {
		& + label {
			&:before {
				border-color: #f00 !important;
			}
		}
	}
	::placeholder {
		color: $base;
	}
	input,
	select,
	textarea {
		font-size: 1rem;
		color: $base;
		font-family: inherit;
		padding: 1em 1em;
	}
	input,
	textarea,
	select,
	.select-wrapper {
		width: 100%;
		border: none;
		background-color: $gray3;
	}
	input {
		min-height: 3rem;
	}
	.select-wrapper {
		min-height: 3rem;
		position: relative;
		&:before {
			position: absolute;
			right: 1em;
			top: 50%;
			transform: translateY(-50%);
			font-size: 1.5em;
			z-index: 10;
			color: $purple;
			pointer-events: none;
		}
		&:after {
			position: absolute;
			display: block;
			content: "";
			right: 0;
			top: 0;
			width: 1.6em;
			height: 100%;
			font-size: 2em;
			z-index: 5;
			pointer-events: none;
		}
	}
	select {
		min-height: 3rem;
		width: 100%;
		border-radius: 0;
		appearance: none;
		&:disabled {
			color: $gray;
		}
		option [disabled="disabled"] {
			color: $gray;
		}
		&:focus-visible {
			outline: transparent;
		}
	}
	p {
		&.question {
			@include font-count(24, 33);
		}
		&.c-info {
			@include font-count(16, 24);
			@include letter-spacing(100);
		}
	}
	label {
		text-align: left;
		display: block;
		padding-bottom: 0.2em;
		&.file_upload {
			display: inline-flex;
			align-items: flex-start;
			input[type="file"] {
				position: absolute;
				visibility: hidden;
				opacity: 0;
				display: none;
			}
			mark {
				background-color: transparent;
				color: #fff;
				opacity: 1;
				font-size: 1rem;
				margin-left: 0.5em;
				@include transition;
			}
			.btn {
				cursor: pointer;
				&.empty {
					color: #fff;
				}
				&:not(.empty):hover {
					& + mark {
						opacity: 0;
					}
				}
			}
		}
	}
	.row {
		padding-top: 0px;
		padding-bottom: 0px;
       
		.col {
			&.file-upload-container {
				text-align: left;
				&.has-danger {
					border: 1px solid #f00;
					mark {
						color: #f00;
					}
				}
			}
			@include bdown(md) {
				margin-bottom: 15px;
			}
		}
	}
	.checkbox input {
		display: none;
	}
	.checkbox label {
		position: relative;
		padding-left: 4em;
		line-height: 3em;
		cursor: pointer;
		&:before {
			content: "";
			position: absolute;
			display: block;
			left: 0;
			top: 0;
			width: 3em;
			height: 3em;
			background-color: #fff;
		}
		&:after {
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			padding-left: 0.4em;
			content: "";
			@include font-count(30, 48);
			color: $purple;
		}
	}

	.checkbox input:checked + label {
		&:after {
			content: "V";
		}
	}
}
