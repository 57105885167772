$amalfi: "Amalfi Coast", sans-serif;
$ropa: "Ropa Sans", sans-serif;
$icomoon: 'icomoon' !important;
$fontawesome: "Font Awesome 5 Free";

$blackalt: #2b2b33;
$darkgray: #3b3b3b;
$purple: #720067;
$pink: #ea80fc;
$blue: #00b0ff;
$red: #f20042;
$scarlet: #f44336;
$orange: #ff9100;


$darkblue: #282a2d;
$base: #363636;
$black: #000000;
$white: #fff;
$whitealt: #FEFEFE;
$green: #ABB582;
$olive: #60654E;
$gray: #F3F4F7;
$gray1: #A1A1A1;
$gray2: #939393;
$gray3: #EDEDED;
$grayalt: #6F6F6F;
$yellow: #B2B384;
$yellow2: #C5CCAA;
$yellow3: #FBFFD1;
$beige: #CFCFCF;



$box: 1230px;

//breakpoints
$breakpoints: (
	"hd": 1919px,
	"hg": 1600px,
	"lg": 1400px,
	"nm": 1250px,
	"md": 991px,
	"sm": 768px,
	"xs": 480px,
	"xxs": 385px,
) !default;

$bfs: 20;
