@import "vendor/slick.css";
@import "vendor/all.min.css";
@import "vendor/fancybox.css";
@import "vendor/toastr.css";
@import "vendor/justifiedGallery.min.css";
@import "vendor/icomoon.css";
.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-3 {
  flex-grow: 3;
}

.flex-grow-4 {
  flex-grow: 4;
}

.flex-grow-5 {
  flex-grow: 5;
}

@media screen and (min-width: 991px) {
  .sized-5 {
    max-width: 5%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-5,
  .sized-mh-5 {
    max-width: 5%;
  }
}

@media screen and (max-width: 479px) {
  .sized-mobile-v-5,
  .sized-mv-5 {
    max-width: 5%;
  }
}

.basis-5 {
  flex-basis: 5% !important;
}
@media screen and (min-width: 991px) {
  .basis-5 {
    max-width: 5%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .basis-mobile-h-5 {
    flex-basis: 5% !important;
  }
}

@media screen and (max-width: 479px) {
  .basis-mobile-v-5 {
    flex-basis: 5% !important;
  }
}

@media screen and (min-width: 991px) {
  .sized-10 {
    max-width: 10%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-10,
  .sized-mh-10 {
    max-width: 10%;
  }
}

@media screen and (max-width: 479px) {
  .sized-mobile-v-10,
  .sized-mv-10 {
    max-width: 10%;
  }
}

.basis-10 {
  flex-basis: 10% !important;
}
@media screen and (min-width: 991px) {
  .basis-10 {
    max-width: 10%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .basis-mobile-h-10 {
    flex-basis: 10% !important;
  }
}

@media screen and (max-width: 479px) {
  .basis-mobile-v-10 {
    flex-basis: 10% !important;
  }
}

@media screen and (min-width: 991px) {
  .sized-15 {
    max-width: 15%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-15,
  .sized-mh-15 {
    max-width: 15%;
  }
}

@media screen and (max-width: 479px) {
  .sized-mobile-v-15,
  .sized-mv-15 {
    max-width: 15%;
  }
}

.basis-15 {
  flex-basis: 15% !important;
}
@media screen and (min-width: 991px) {
  .basis-15 {
    max-width: 15%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .basis-mobile-h-15 {
    flex-basis: 15% !important;
  }
}

@media screen and (max-width: 479px) {
  .basis-mobile-v-15 {
    flex-basis: 15% !important;
  }
}

@media screen and (min-width: 991px) {
  .sized-20 {
    max-width: 20%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-20,
  .sized-mh-20 {
    max-width: 20%;
  }
}

@media screen and (max-width: 479px) {
  .sized-mobile-v-20,
  .sized-mv-20 {
    max-width: 20%;
  }
}

.basis-20 {
  flex-basis: 20% !important;
}
@media screen and (min-width: 991px) {
  .basis-20 {
    max-width: 20%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .basis-mobile-h-20 {
    flex-basis: 20% !important;
  }
}

@media screen and (max-width: 479px) {
  .basis-mobile-v-20 {
    flex-basis: 20% !important;
  }
}

@media screen and (min-width: 991px) {
  .sized-25 {
    max-width: 25%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-25,
  .sized-mh-25 {
    max-width: 25%;
  }
}

@media screen and (max-width: 479px) {
  .sized-mobile-v-25,
  .sized-mv-25 {
    max-width: 25%;
  }
}

.basis-25 {
  flex-basis: 25% !important;
}
@media screen and (min-width: 991px) {
  .basis-25 {
    max-width: 25%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .basis-mobile-h-25 {
    flex-basis: 25% !important;
  }
}

@media screen and (max-width: 479px) {
  .basis-mobile-v-25 {
    flex-basis: 25% !important;
  }
}

@media screen and (min-width: 991px) {
  .sized-30 {
    max-width: 30%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-30,
  .sized-mh-30 {
    max-width: 30%;
  }
}

@media screen and (max-width: 479px) {
  .sized-mobile-v-30,
  .sized-mv-30 {
    max-width: 30%;
  }
}

.basis-30 {
  flex-basis: 30% !important;
}
@media screen and (min-width: 991px) {
  .basis-30 {
    max-width: 30%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .basis-mobile-h-30 {
    flex-basis: 30% !important;
  }
}

@media screen and (max-width: 479px) {
  .basis-mobile-v-30 {
    flex-basis: 30% !important;
  }
}

@media screen and (min-width: 991px) {
  .sized-35 {
    max-width: 35%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-35,
  .sized-mh-35 {
    max-width: 35%;
  }
}

@media screen and (max-width: 479px) {
  .sized-mobile-v-35,
  .sized-mv-35 {
    max-width: 35%;
  }
}

.basis-35 {
  flex-basis: 35% !important;
}
@media screen and (min-width: 991px) {
  .basis-35 {
    max-width: 35%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .basis-mobile-h-35 {
    flex-basis: 35% !important;
  }
}

@media screen and (max-width: 479px) {
  .basis-mobile-v-35 {
    flex-basis: 35% !important;
  }
}

@media screen and (min-width: 991px) {
  .sized-40 {
    max-width: 40%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-40,
  .sized-mh-40 {
    max-width: 40%;
  }
}

@media screen and (max-width: 479px) {
  .sized-mobile-v-40,
  .sized-mv-40 {
    max-width: 40%;
  }
}

.basis-40 {
  flex-basis: 40% !important;
}
@media screen and (min-width: 991px) {
  .basis-40 {
    max-width: 40%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .basis-mobile-h-40 {
    flex-basis: 40% !important;
  }
}

@media screen and (max-width: 479px) {
  .basis-mobile-v-40 {
    flex-basis: 40% !important;
  }
}

@media screen and (min-width: 991px) {
  .sized-45 {
    max-width: 45%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-45,
  .sized-mh-45 {
    max-width: 45%;
  }
}

@media screen and (max-width: 479px) {
  .sized-mobile-v-45,
  .sized-mv-45 {
    max-width: 45%;
  }
}

.basis-45 {
  flex-basis: 45% !important;
}
@media screen and (min-width: 991px) {
  .basis-45 {
    max-width: 45%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .basis-mobile-h-45 {
    flex-basis: 45% !important;
  }
}

@media screen and (max-width: 479px) {
  .basis-mobile-v-45 {
    flex-basis: 45% !important;
  }
}

@media screen and (min-width: 991px) {
  .sized-50 {
    max-width: 50%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-50,
  .sized-mh-50 {
    max-width: 50%;
  }
}

@media screen and (max-width: 479px) {
  .sized-mobile-v-50,
  .sized-mv-50 {
    max-width: 50%;
  }
}

.basis-50 {
  flex-basis: 50% !important;
}
@media screen and (min-width: 991px) {
  .basis-50 {
    max-width: 50%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .basis-mobile-h-50 {
    flex-basis: 50% !important;
  }
}

@media screen and (max-width: 479px) {
  .basis-mobile-v-50 {
    flex-basis: 50% !important;
  }
}

@media screen and (min-width: 991px) {
  .sized-55 {
    max-width: 55%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-55,
  .sized-mh-55 {
    max-width: 55%;
  }
}

@media screen and (max-width: 479px) {
  .sized-mobile-v-55,
  .sized-mv-55 {
    max-width: 55%;
  }
}

.basis-55 {
  flex-basis: 55% !important;
}
@media screen and (min-width: 991px) {
  .basis-55 {
    max-width: 55%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .basis-mobile-h-55 {
    flex-basis: 55% !important;
  }
}

@media screen and (max-width: 479px) {
  .basis-mobile-v-55 {
    flex-basis: 55% !important;
  }
}

@media screen and (min-width: 991px) {
  .sized-60 {
    max-width: 60%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-60,
  .sized-mh-60 {
    max-width: 60%;
  }
}

@media screen and (max-width: 479px) {
  .sized-mobile-v-60,
  .sized-mv-60 {
    max-width: 60%;
  }
}

.basis-60 {
  flex-basis: 60% !important;
}
@media screen and (min-width: 991px) {
  .basis-60 {
    max-width: 60%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .basis-mobile-h-60 {
    flex-basis: 60% !important;
  }
}

@media screen and (max-width: 479px) {
  .basis-mobile-v-60 {
    flex-basis: 60% !important;
  }
}

@media screen and (min-width: 991px) {
  .sized-65 {
    max-width: 65%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-65,
  .sized-mh-65 {
    max-width: 65%;
  }
}

@media screen and (max-width: 479px) {
  .sized-mobile-v-65,
  .sized-mv-65 {
    max-width: 65%;
  }
}

.basis-65 {
  flex-basis: 65% !important;
}
@media screen and (min-width: 991px) {
  .basis-65 {
    max-width: 65%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .basis-mobile-h-65 {
    flex-basis: 65% !important;
  }
}

@media screen and (max-width: 479px) {
  .basis-mobile-v-65 {
    flex-basis: 65% !important;
  }
}

@media screen and (min-width: 991px) {
  .sized-70 {
    max-width: 70%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-70,
  .sized-mh-70 {
    max-width: 70%;
  }
}

@media screen and (max-width: 479px) {
  .sized-mobile-v-70,
  .sized-mv-70 {
    max-width: 70%;
  }
}

.basis-70 {
  flex-basis: 70% !important;
}
@media screen and (min-width: 991px) {
  .basis-70 {
    max-width: 70%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .basis-mobile-h-70 {
    flex-basis: 70% !important;
  }
}

@media screen and (max-width: 479px) {
  .basis-mobile-v-70 {
    flex-basis: 70% !important;
  }
}

@media screen and (min-width: 991px) {
  .sized-75 {
    max-width: 75%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-75,
  .sized-mh-75 {
    max-width: 75%;
  }
}

@media screen and (max-width: 479px) {
  .sized-mobile-v-75,
  .sized-mv-75 {
    max-width: 75%;
  }
}

.basis-75 {
  flex-basis: 75% !important;
}
@media screen and (min-width: 991px) {
  .basis-75 {
    max-width: 75%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .basis-mobile-h-75 {
    flex-basis: 75% !important;
  }
}

@media screen and (max-width: 479px) {
  .basis-mobile-v-75 {
    flex-basis: 75% !important;
  }
}

@media screen and (min-width: 991px) {
  .sized-80 {
    max-width: 80%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-80,
  .sized-mh-80 {
    max-width: 80%;
  }
}

@media screen and (max-width: 479px) {
  .sized-mobile-v-80,
  .sized-mv-80 {
    max-width: 80%;
  }
}

.basis-80 {
  flex-basis: 80% !important;
}
@media screen and (min-width: 991px) {
  .basis-80 {
    max-width: 80%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .basis-mobile-h-80 {
    flex-basis: 80% !important;
  }
}

@media screen and (max-width: 479px) {
  .basis-mobile-v-80 {
    flex-basis: 80% !important;
  }
}

@media screen and (min-width: 991px) {
  .sized-85 {
    max-width: 85%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-85,
  .sized-mh-85 {
    max-width: 85%;
  }
}

@media screen and (max-width: 479px) {
  .sized-mobile-v-85,
  .sized-mv-85 {
    max-width: 85%;
  }
}

.basis-85 {
  flex-basis: 85% !important;
}
@media screen and (min-width: 991px) {
  .basis-85 {
    max-width: 85%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .basis-mobile-h-85 {
    flex-basis: 85% !important;
  }
}

@media screen and (max-width: 479px) {
  .basis-mobile-v-85 {
    flex-basis: 85% !important;
  }
}

@media screen and (min-width: 991px) {
  .sized-90 {
    max-width: 90%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-90,
  .sized-mh-90 {
    max-width: 90%;
  }
}

@media screen and (max-width: 479px) {
  .sized-mobile-v-90,
  .sized-mv-90 {
    max-width: 90%;
  }
}

.basis-90 {
  flex-basis: 90% !important;
}
@media screen and (min-width: 991px) {
  .basis-90 {
    max-width: 90%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .basis-mobile-h-90 {
    flex-basis: 90% !important;
  }
}

@media screen and (max-width: 479px) {
  .basis-mobile-v-90 {
    flex-basis: 90% !important;
  }
}

@media screen and (min-width: 991px) {
  .sized-95 {
    max-width: 95%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-95,
  .sized-mh-95 {
    max-width: 95%;
  }
}

@media screen and (max-width: 479px) {
  .sized-mobile-v-95,
  .sized-mv-95 {
    max-width: 95%;
  }
}

.basis-95 {
  flex-basis: 95% !important;
}
@media screen and (min-width: 991px) {
  .basis-95 {
    max-width: 95%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .basis-mobile-h-95 {
    flex-basis: 95% !important;
  }
}

@media screen and (max-width: 479px) {
  .basis-mobile-v-95 {
    flex-basis: 95% !important;
  }
}

@media screen and (min-width: 991px) {
  .sized-100 {
    max-width: 100%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-100,
  .sized-mh-100 {
    max-width: 100%;
  }
}

@media screen and (max-width: 479px) {
  .sized-mobile-v-100,
  .sized-mv-100 {
    max-width: 100%;
  }
}

.basis-100 {
  flex-basis: 100% !important;
}
@media screen and (min-width: 991px) {
  .basis-100 {
    max-width: 100%;
  }
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .basis-mobile-h-100 {
    flex-basis: 100% !important;
  }
}

@media screen and (max-width: 479px) {
  .basis-mobile-v-100 {
    flex-basis: 100% !important;
  }
}

.wrap-2 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}
.wrap-2 .col {
  flex: 0 0 calc(100% / 2);
}
.wrap-2 > * {
  flex: 0 0 calc(100% / 2);
  padding: 15px;
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-2 .col {
    flex: 0 0 49%;
  }
  .wrap-2 > * {
    flex: 0 0 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-2 .col {
    flex: 0 0 100%;
  }
  .wrap-2 > * {
    flex: 0 0 100%;
  }
}
.wrap-3 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}
.wrap-3 .col {
  flex: 0 0 calc(100% / 3);
}
.wrap-3 > * {
  flex: 0 0 calc(100% / 3);
  padding: 15px;
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-3 .col {
    flex: 0 0 49%;
  }
  .wrap-3 > * {
    flex: 0 0 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-3 .col {
    flex: 0 0 100%;
  }
  .wrap-3 > * {
    flex: 0 0 100%;
  }
}
.wrap-4 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}
.wrap-4 .col {
  flex: 0 0 calc(100% / 4);
}
.wrap-4 > * {
  flex: 0 0 calc(100% / 4);
  padding: 15px;
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-4 .col {
    flex: 0 0 49%;
  }
  .wrap-4 > * {
    flex: 0 0 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-4 .col {
    flex: 0 0 100%;
  }
  .wrap-4 > * {
    flex: 0 0 100%;
  }
}
.wrap-5 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}
.wrap-5 .col {
  flex: 0 0 calc(100% / 5);
}
.wrap-5 > * {
  flex: 0 0 calc(100% / 5);
  padding: 15px;
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-5 .col {
    flex: 0 0 49%;
  }
  .wrap-5 > * {
    flex: 0 0 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-5 .col {
    flex: 0 0 100%;
  }
  .wrap-5 > * {
    flex: 0 0 100%;
  }
}
.wrap-6 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}
.wrap-6 .col {
  flex: 0 0 calc(100% / 6);
}
.wrap-6 > * {
  flex: 0 0 calc(100% / 6);
  padding: 15px;
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-6 .col {
    flex: 0 0 49%;
  }
  .wrap-6 > * {
    flex: 0 0 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-6 .col {
    flex: 0 0 100%;
  }
  .wrap-6 > * {
    flex: 0 0 100%;
  }
}
.wrap-7 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}
.wrap-7 .col {
  flex: 0 0 calc(100% / 7);
}
.wrap-7 > * {
  flex: 0 0 calc(100% / 7);
  padding: 15px;
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-7 .col {
    flex: 0 0 49%;
  }
  .wrap-7 > * {
    flex: 0 0 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-7 .col {
    flex: 0 0 100%;
  }
  .wrap-7 > * {
    flex: 0 0 100%;
  }
}
.wrap-8 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}
.wrap-8 .col {
  flex: 0 0 calc(100% / 8);
}
.wrap-8 > * {
  flex: 0 0 calc(100% / 8);
  padding: 15px;
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-8 .col {
    flex: 0 0 49%;
  }
  .wrap-8 > * {
    flex: 0 0 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-8 .col {
    flex: 0 0 100%;
  }
  .wrap-8 > * {
    flex: 0 0 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-1 .col {
    flex: 0 0 calc(100% / 1);
  }
  .wrap-mobile-h-1 > * {
    flex: 0 0 calc(100% / 1);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-1 .col {
    flex: 0 0 calc(100% / 1);
  }
  .wrap-mobile-v-1 > * {
    flex: 0 0 calc(100% / 1);
    padding: 15px;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-2 .col {
    flex: 0 0 calc(100% / 2);
  }
  .wrap-mobile-h-2 > * {
    flex: 0 0 calc(100% / 2);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-2 .col {
    flex: 0 0 calc(100% / 2);
  }
  .wrap-mobile-v-2 > * {
    flex: 0 0 calc(100% / 2);
    padding: 15px;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-3 .col {
    flex: 0 0 calc(100% / 3);
  }
  .wrap-mobile-h-3 > * {
    flex: 0 0 calc(100% / 3);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-3 .col {
    flex: 0 0 calc(100% / 3);
  }
  .wrap-mobile-v-3 > * {
    flex: 0 0 calc(100% / 3);
    padding: 15px;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-4 .col {
    flex: 0 0 calc(100% / 4);
  }
  .wrap-mobile-h-4 > * {
    flex: 0 0 calc(100% / 4);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-4 .col {
    flex: 0 0 calc(100% / 4);
  }
  .wrap-mobile-v-4 > * {
    flex: 0 0 calc(100% / 4);
    padding: 15px;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-5 .col {
    flex: 0 0 calc(100% / 5);
  }
  .wrap-mobile-h-5 > * {
    flex: 0 0 calc(100% / 5);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-5 .col {
    flex: 0 0 calc(100% / 5);
  }
  .wrap-mobile-v-5 > * {
    flex: 0 0 calc(100% / 5);
    padding: 15px;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-6 .col {
    flex: 0 0 calc(100% / 6);
  }
  .wrap-mobile-h-6 > * {
    flex: 0 0 calc(100% / 6);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-6 .col {
    flex: 0 0 calc(100% / 6);
  }
  .wrap-mobile-v-6 > * {
    flex: 0 0 calc(100% / 6);
    padding: 15px;
  }
}
.m-0 {
  margin: 0rem;
}

.mt-0 {
  margin-top: 0rem;
}

.mb-0 {
  margin-bottom: 0rem;
}

.ml-0 {
  margin-left: 0rem;
}

.mr-0 {
  margin-right: 0rem;
}

.ms-0 {
  margin-left: 0rem;
  margin-right: 0rem;
}

.mv-0 {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.pt-0 {
  padding-top: 0rem;
}

.pb-0 {
  padding-bottom: 0rem;
}

.pl-0 {
  padding-left: 0rem;
}

.pr-0 {
  padding-right: 0rem;
}

.ps-0 {
  padding-left: 0rem;
  padding-right: 0rem;
}

.pv-0 {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.p-0 {
  padding: 0rem;
}

.row.gap-0 {
  gap: 0rem;
}

@media screen and (min-width: 991px) {
  .rml-0 {
    margin-left: 0rem;
  }
  .rmr-0 {
    margin-right: 0rem;
  }
  .rmt-0 {
    margin-top: 0rem;
  }
  .rmb-0 {
    margin-bottom: 0rem;
  }
  .rmv-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .rms-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .rpl-0 {
    padding-left: 0rem;
  }
  .rpr-0 {
    padding-right: 0rem;
  }
  .rpt-0 {
    padding-top: 0rem;
  }
  .rpb-0 {
    padding-bottom: 0rem;
  }
  .rpv-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .rps-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
}
.m-1 {
  margin: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.ml-1 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.ms-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mv-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pr-1 {
  padding-right: 1rem;
}

.ps-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.pv-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.p-1 {
  padding: 1rem;
}

.row.gap-1 {
  gap: 1rem;
}

@media screen and (min-width: 991px) {
  .rml-1 {
    margin-left: 1rem;
  }
  .rmr-1 {
    margin-right: 1rem;
  }
  .rmt-1 {
    margin-top: 1rem;
  }
  .rmb-1 {
    margin-bottom: 1rem;
  }
  .rmv-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .rms-1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .rpl-1 {
    padding-left: 1rem;
  }
  .rpr-1 {
    padding-right: 1rem;
  }
  .rpt-1 {
    padding-top: 1rem;
  }
  .rpb-1 {
    padding-bottom: 1rem;
  }
  .rpv-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .rps-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.m-2 {
  margin: 2rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.ml-2 {
  margin-left: 2rem;
}

.mr-2 {
  margin-right: 2rem;
}

.ms-2 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mv-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pl-2 {
  padding-left: 2rem;
}

.pr-2 {
  padding-right: 2rem;
}

.ps-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.pv-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.p-2 {
  padding: 2rem;
}

.row.gap-2 {
  gap: 2rem;
}

@media screen and (min-width: 991px) {
  .rml-2 {
    margin-left: 2rem;
  }
  .rmr-2 {
    margin-right: 2rem;
  }
  .rmt-2 {
    margin-top: 2rem;
  }
  .rmb-2 {
    margin-bottom: 2rem;
  }
  .rmv-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .rms-2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .rpl-2 {
    padding-left: 2rem;
  }
  .rpr-2 {
    padding-right: 2rem;
  }
  .rpt-2 {
    padding-top: 2rem;
  }
  .rpb-2 {
    padding-bottom: 2rem;
  }
  .rpv-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .rps-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.m-3 {
  margin: 3rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.ml-3 {
  margin-left: 3rem;
}

.mr-3 {
  margin-right: 3rem;
}

.ms-3 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.mv-3 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.pt-3 {
  padding-top: 3rem;
}

.pb-3 {
  padding-bottom: 3rem;
}

.pl-3 {
  padding-left: 3rem;
}

.pr-3 {
  padding-right: 3rem;
}

.ps-3 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.pv-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.p-3 {
  padding: 3rem;
}

.row.gap-3 {
  gap: 3rem;
}

@media screen and (min-width: 991px) {
  .rml-3 {
    margin-left: 3rem;
  }
  .rmr-3 {
    margin-right: 3rem;
  }
  .rmt-3 {
    margin-top: 3rem;
  }
  .rmb-3 {
    margin-bottom: 3rem;
  }
  .rmv-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .rms-3 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .rpl-3 {
    padding-left: 3rem;
  }
  .rpr-3 {
    padding-right: 3rem;
  }
  .rpt-3 {
    padding-top: 3rem;
  }
  .rpb-3 {
    padding-bottom: 3rem;
  }
  .rpv-3 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .rps-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
.m-4 {
  margin: 4rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.ml-4 {
  margin-left: 4rem;
}

.mr-4 {
  margin-right: 4rem;
}

.ms-4 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mv-4 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.pt-4 {
  padding-top: 4rem;
}

.pb-4 {
  padding-bottom: 4rem;
}

.pl-4 {
  padding-left: 4rem;
}

.pr-4 {
  padding-right: 4rem;
}

.ps-4 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.pv-4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.p-4 {
  padding: 4rem;
}

.row.gap-4 {
  gap: 4rem;
}

@media screen and (min-width: 991px) {
  .rml-4 {
    margin-left: 4rem;
  }
  .rmr-4 {
    margin-right: 4rem;
  }
  .rmt-4 {
    margin-top: 4rem;
  }
  .rmb-4 {
    margin-bottom: 4rem;
  }
  .rmv-4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .rms-4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .rpl-4 {
    padding-left: 4rem;
  }
  .rpr-4 {
    padding-right: 4rem;
  }
  .rpt-4 {
    padding-top: 4rem;
  }
  .rpb-4 {
    padding-bottom: 4rem;
  }
  .rpv-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .rps-4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
.m-5 {
  margin: 5rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.ml-5 {
  margin-left: 5rem;
}

.mr-5 {
  margin-right: 5rem;
}

.ms-5 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.mv-5 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.pt-5 {
  padding-top: 5rem;
}

.pb-5 {
  padding-bottom: 5rem;
}

.pl-5 {
  padding-left: 5rem;
}

.pr-5 {
  padding-right: 5rem;
}

.ps-5 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.pv-5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.p-5 {
  padding: 5rem;
}

.row.gap-5 {
  gap: 5rem;
}

@media screen and (min-width: 991px) {
  .rml-5 {
    margin-left: 5rem;
  }
  .rmr-5 {
    margin-right: 5rem;
  }
  .rmt-5 {
    margin-top: 5rem;
  }
  .rmb-5 {
    margin-bottom: 5rem;
  }
  .rmv-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .rms-5 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .rpl-5 {
    padding-left: 5rem;
  }
  .rpr-5 {
    padding-right: 5rem;
  }
  .rpt-5 {
    padding-top: 5rem;
  }
  .rpb-5 {
    padding-bottom: 5rem;
  }
  .rpv-5 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .rps-5 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}
html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: inherit;
}

ul li {
  list-style-type: none;
}

button,
input,
select,
textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

img,
embed,
iframe,
object,
audio,
video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
  text-align: left;
}

body {
  margin: 0;
  padding: 0;
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
}

a {
  color: inherit;
}

a:not(:disabled), a:not(.disabled), button:not(:disabled), button:not(.disabled) {
  cursor: pointer;
}

button {
  line-height: inherit;
  font-size: inherit;
}

textarea:focus, input:focus {
  outline: none;
}

section {
  padding: 3rem 0;
}

.container {
  padding: 0 15px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1230px;
}
.container.super-slim {
  max-width: 800px;
}
.container.slim {
  max-width: 900px;
}
.container.narrow {
  max-width: 1080px;
}
.container.wider {
  max-width: 1450px;
}
.container.wide {
  max-width: 1600px;
}
.container.widest {
  max-width: 1875px;
}
.container.fw {
  max-width: none !important;
}
@media screen and (max-width: 1249px) {
  .container {
    padding: 0 calc(15px + 1%);
  }
}
@media screen and (max-width: 990px) {
  .container {
    padding: 0 calc(15px + 2%);
  }
}

.row {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}
@media screen and (max-width: 990px) {
  .row {
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
  }
  .row.eq-h {
    height: auto !important;
  }
}
@media screen and (max-width: 990px) {
  .row.no-collapse {
    display: flex;
  }
  .row.no-collapse .col {
    margin-bottom: 0;
  }
  .row.no-collapse .col[class^=basis], .row.no-collapse .col[class*=basis] {
    flex-grow: 0;
  }
}

.col {
  padding: 0 15px;
  flex: 1 0 0;
  box-sizing: border-box;
}
@media screen and (min-width: 991px) {
  .col[class^=basis], .col[class*=basis] {
    flex-grow: 0;
  }
}
@media screen and (max-width: 990px) {
  .col {
    margin-bottom: 1rem;
  }
  .col:last-of-type {
    margin-bottom: 0;
  }
}

.masonry > div {
  display: flex;
  flex-direction: column;
}
.masonry-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
.masonry a.masonry-inner:hover {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
}
.masonry-inverted .masonry-inner {
  box-shadow: none;
}

.d-none {
  display: none;
}
.d-flex {
  display: flex;
}
.d-inline-flex {
  display: inline-flex;
}
.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}
.d-inline {
  display: inline;
}
@media screen and (min-width: 991px) {
  .d-r-none {
    display: none;
  }
  .d-r-flex {
    display: flex;
  }
  .d-r-inline-flex {
    display: inline-flex;
  }
  .d-r-block {
    display: block;
  }
  .d-r-inline-block {
    display: inline-block;
  }
  .d-r-inline {
    display: inline;
  }
}

.p-static {
  position: static;
}
.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}
.p-sticky {
  position: sticky;
}

.border {
  border-width: 0;
  border-style: solid;
  border-color: #000000;
}
.border-all-width-0 {
  border-width: 0px;
}
.border-all-width-1 {
  border-width: 1px;
}
.border-all-width-2 {
  border-width: 2px;
}
.border-all-width-3 {
  border-width: 3px;
}
.border-all-width-4 {
  border-width: 4px;
}
.border-all-width-5 {
  border-width: 5px;
}
.border-all-width-6 {
  border-width: 6px;
}
.border-all-width-7 {
  border-width: 7px;
}
.border-all-width-8 {
  border-width: 8px;
}
.border-all-width-9 {
  border-width: 9px;
}
.border-all-width-10 {
  border-width: 10px;
}
.border-top-width-0 {
  border-top-width: 0px;
}
.border-top-width-1 {
  border-top-width: 1px;
}
.border-top-width-2 {
  border-top-width: 2px;
}
.border-top-width-3 {
  border-top-width: 3px;
}
.border-top-width-4 {
  border-top-width: 4px;
}
.border-top-width-5 {
  border-top-width: 5px;
}
.border-top-width-6 {
  border-top-width: 6px;
}
.border-top-width-7 {
  border-top-width: 7px;
}
.border-top-width-8 {
  border-top-width: 8px;
}
.border-top-width-9 {
  border-top-width: 9px;
}
.border-top-width-10 {
  border-top-width: 10px;
}
.border-bottom-width-0 {
  border-bottom-width: 0px;
}
.border-bottom-width-1 {
  border-bottom-width: 1px;
}
.border-bottom-width-2 {
  border-bottom-width: 2px;
}
.border-bottom-width-3 {
  border-bottom-width: 3px;
}
.border-bottom-width-4 {
  border-bottom-width: 4px;
}
.border-bottom-width-5 {
  border-bottom-width: 5px;
}
.border-bottom-width-6 {
  border-bottom-width: 6px;
}
.border-bottom-width-7 {
  border-bottom-width: 7px;
}
.border-bottom-width-8 {
  border-bottom-width: 8px;
}
.border-bottom-width-9 {
  border-bottom-width: 9px;
}
.border-bottom-width-10 {
  border-bottom-width: 10px;
}
.border-left-width-0 {
  border-left-width: 0px;
}
.border-left-width-1 {
  border-left-width: 1px;
}
.border-left-width-2 {
  border-left-width: 2px;
}
.border-left-width-3 {
  border-left-width: 3px;
}
.border-left-width-4 {
  border-left-width: 4px;
}
.border-left-width-5 {
  border-left-width: 5px;
}
.border-left-width-6 {
  border-left-width: 6px;
}
.border-left-width-7 {
  border-left-width: 7px;
}
.border-left-width-8 {
  border-left-width: 8px;
}
.border-left-width-9 {
  border-left-width: 9px;
}
.border-left-width-10 {
  border-left-width: 10px;
}
.border-right-width-0 {
  border-right-width: 0px;
}
.border-right-width-1 {
  border-right-width: 1px;
}
.border-right-width-2 {
  border-right-width: 2px;
}
.border-right-width-3 {
  border-right-width: 3px;
}
.border-right-width-4 {
  border-right-width: 4px;
}
.border-right-width-5 {
  border-right-width: 5px;
}
.border-right-width-6 {
  border-right-width: 6px;
}
.border-right-width-7 {
  border-right-width: 7px;
}
.border-right-width-8 {
  border-right-width: 8px;
}
.border-right-width-9 {
  border-right-width: 9px;
}
.border-right-width-10 {
  border-right-width: 10px;
}
.border-color-yellow {
  border-color: #B2B384;
}
.border-color-purple {
  border-color: #720067;
}
.border-color-white {
  border-color: #fff;
}
.border-color-beige {
  border-color: #CFCFCF;
}
.border-color-gray {
  border-color: #F3F4F7;
}
.border-color-black {
  border-color: #000000;
}
.border-color-black-alt {
  border-color: #2b2b33;
}
.border-color-base {
  border-color: #363636;
}
.border-color-red {
  border-color: #f20042;
}
.border-color-orange {
  border-color: #ff9100;
}
.border-color-scarlet {
  border-color: #f44336;
}
.border-color-green {
  border-color: #ABB582;
}
.border-color-blue {
  border-color: #00b0ff;
}
.border-color-pink {
  border-color: #ea80fc;
}
.border-style-dotted {
  border-style: dotted;
}
.border-style-dashed {
  border-style: dashed;
}
.border-style-solid {
  border-style: solid;
}
.border-style-double {
  border-top-style: double;
  border-bottom-style: double;
  border-left-style: double;
  border-right-style: double;
}
.border-radius-4 {
  border-radius: 4px;
}
.border-radius-5 {
  border-radius: 5px;
}
.border-radius-6 {
  border-radius: 6px;
}
.border-radius-7 {
  border-radius: 7px;
}
.border-radius-8 {
  border-radius: 8px;
}
.border-radius-9 {
  border-radius: 9px;
}
.border-radius-10 {
  border-radius: 10px;
}
.border-radius-rounded {
  border-radius: 50%;
}

.flex-aic {
  align-items: center;
}
.flex-ait {
  align-items: flex-start;
}
.flex-aib {
  align-items: flex-end;
}
.flex-jcs {
  justify-content: flex-start;
}
.flex-jce {
  justify-content: flex-end;
}
.flex-jcc {
  justify-content: center;
}
.flex-jcsb {
  justify-content: space-between;
}
.flex-jcsa {
  justify-content: space-around;
}
.flex-direction-column {
  flex-direction: column;
}
.flex-direction-column-reverse {
  flex-direction: column-reverse;
}
.flex-direction-row {
  flex-direction: row;
}
.flex-direction-row-reverse {
  flex-direction: row-reverse;
}
@media screen and (min-width: 991px) {
  .flex-direction-dt-column {
    flex-direction: column;
  }
  .flex-direction-dt-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-direction-dt-row {
    flex-direction: row;
  }
  .flex-direction-dt-row-reverse {
    flex-direction: row-reverse;
  }
}
@media screen and (min-width: ) {
  .flex-direction-ls-column {
    flex-direction: column;
  }
  .flex-direction-ls-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-direction-ls-row {
    flex-direction: row;
  }
  .flex-direction-ls-row-reverse {
    flex-direction: row-reverse;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .flex-direction-mh-column {
    flex-direction: column;
  }
  .flex-direction-mh-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-direction-mh-row {
    flex-direction: row;
  }
  .flex-direction-mh-row-reverse {
    flex-direction: row-reverse;
  }
}
@media screen and (max-width: 479px) {
  .flex-direction-mv-column {
    flex-direction: column;
  }
  .flex-direction-mv-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-direction-mv-row {
    flex-direction: row;
  }
  .flex-direction-mv-row-reverse {
    flex-direction: row-reverse;
  }
}
.flex-grow {
  flex-grow: 1;
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-uc {
  text-transform: uppercase;
}
.text-lc {
  text-transform: lowercase;
}
.text-cz {
  text-transform: capitalize;
}
@media screen and (min-width: 991px) {
  .text-r-left {
    text-align: left;
  }
  .text-r-center {
    text-align: center;
  }
  .text-r-right {
    text-align: right;
  }
}

.float-container:after {
  content: "";
  display: block;
  clear: both;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}

.font-size-xs {
  font-size: 60%;
}
.font-size-sm {
  font-size: 80%;
}
.font-size-l {
  font-size: 125%;
}
.font-size-xl {
  font-size: 150%;
}
.font-size-xxl {
  font-size: 200%;
}
.font-size-xxxl {
  font-size: 300%;
}
.font-size-xxxxl {
  font-size: 400%;
}
.font-size-xxxxxl {
  font-size: 500%;
}

.line-height-inherit {
  line-height: inherit;
}
.line-height-normal {
  line-height: normal;
}
.line-height-60 {
  line-height: 60%;
}
.line-height-80 {
  line-height: 80%;
}
.line-height-100 {
  line-height: 100%;
}
.line-height-125 {
  line-height: 125%;
}
.line-height-150 {
  line-height: 150%;
}
.line-height-200 {
  line-height: 200%;
}

.ls-min {
  letter-spacing: -0.1em;
}
.ls-condenced {
  letter-spacing: -0.05em;
}
.ls-wide {
  letter-spacing: 0.05em;
}
.ls-max {
  letter-spacing: 0.1em;
}

.hidden {
  display: none !important;
}
@media screen and (max-width: 990px) {
  .hidden-mobile {
    display: none !important;
  }
}
@media screen and (min-width: 991px) {
  .hidden-desktop {
    display: none !important;
  }
}

.visibility-on {
  visibility: visible;
}
.visibility-off {
  visibility: hidden;
}

.overflow-visible {
  overflow: visible;
}
.overflow-scroll {
  overflow: scroll;
}
.overflow-hidden {
  overflow: hidden;
}

.m-auto {
  margin-left: auto;
  margin-right: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}

.mb--1 {
  margin-bottom: -1rem;
}
.mb--2 {
  margin-bottom: -2rem;
}
.mb--3 {
  margin-bottom: -3rem;
}

.no-bg {
  background-image: none !important;
}

.underline {
  text-decoration: underline;
}

.shadow {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.shadow-small {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.shadow-big {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
}

.pr {
  position: relative;
}

.bold {
  font-weight: 700;
}

.media-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
.media-wrapper iframe {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.object-fit-cover {
  object-fit: cover;
}
.object-fit-contain {
  object-fit: contain;
}

.clearfix:after {
  display: table;
  content: "";
  clear: both;
}

.white-space-nowrap {
  white-space: nowrap;
}
.white-space-normal {
  white-space: normal;
}
.white-space-pre {
  white-space: pre;
}

.word-break-normal {
  word-break: normal;
}
.word-break-all {
  word-break: break-all;
}
.word-break-word {
  word-break: break-word;
}

.acc-content {
  display: none;
}
.acc .btn-acc span:first-child {
  display: inline-block;
}
.acc .btn-acc span:last-child {
  display: none;
}
.acc-opened .acc-content {
  display: block;
}
.acc-opened .btn-acc span:first-child {
  display: none;
}
.acc-opened .btn-acc span:last-child {
  display: inline-block;
}

.tab-titles {
  margin-bottom: 0;
}
.tab-titles li {
  list-style-type: none;
  margin-left: 0;
}
.tab-titles li a {
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: block;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
}
.tab-titles li a:not(.active) {
  background-color: inherit !important;
  color: inherit !important;
}
.tab-titles li a:hover {
  opacity: 1;
}
.tab-info {
  padding: 1rem;
}
.tab-info div[data-item-tab] {
  display: none;
}
.tab-info div[data-item-tab].active {
  display: block;
}
.tab-horizontal .tab-titles {
  display: flex;
  overflow-x: auto;
}
.tab-vertical {
  display: flex;
}
.tab-vertical .tab-info {
  flex-grow: 1;
}

.banner-cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.banner-tile {
  background-repeat: repeat;
}
@media screen and (min-width: 991px) {
  .banner-fixed {
    background-attachment: fixed;
  }
}

.opacity-panel {
  position: relative;
}
.opacity-panel-bg {
  position: absolute;
  display: block;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.opacity-panel-content {
  position: relative;
  z-index: 5;
}

.op-0 {
  opacity: 0;
}

.op-1 {
  opacity: 0.1;
}

.op-2 {
  opacity: 0.2;
}

.op-3 {
  opacity: 0.3;
}

.op-4 {
  opacity: 0.4;
}

.op-5 {
  opacity: 0.5;
}

.op-6 {
  opacity: 0.6;
}

.op-7 {
  opacity: 0.7;
}

.op-8 {
  opacity: 0.8;
}

.op-9 {
  opacity: 0.9;
}

.op-10 {
  opacity: 1;
}

.img-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.img-contain {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.text-yellow {
  color: #B2B384;
}
.text-purple {
  color: #720067;
}
.text-white {
  color: #fff;
}
.text-beige {
  color: #CFCFCF;
}
.text-gray {
  color: #F3F4F7;
}
.text-black {
  color: #000000;
}
.text-black-alt {
  color: #2b2b33;
}
.text-base {
  color: #363636;
}
.text-darkgray {
  color: #3b3b3b;
}
.text-orange {
  color: #ff9100;
}
.text-scarlet {
  color: #f44336;
}
.text-red {
  color: #f20042;
}
.text-pink {
  color: #ea80fc;
}
.text-blue {
  color: #00b0ff;
}
.text-darkblue {
  color: #282a2d;
}
.text-green {
  color: #ABB582;
}

.bg-yellow {
  background-color: #B2B384;
}
.bg-purple {
  background-color: #720067;
}
.bg-white {
  background-color: #fff;
}
.bg-beige {
  background-color: #CFCFCF;
}
.bg-gray {
  background-color: #F3F4F7;
}
.bg-gray3 {
  background-color: #EDEDED;
}
.bg-darkgray {
  background-color: #3b3b3b;
}
.bg-black {
  background-color: #000000;
}
.bg-black-alt {
  background-color: #2b2b33;
}
.bg-base {
  background-color: #363636;
}
.bg-orange {
  background-color: #ff9100;
}
.bg-scarlet {
  background-color: #f44336;
}
.bg-red {
  background-color: #f20042;
}
.bg-pink {
  background-color: #ea80fc;
}
.bg-blue {
  background-color: #00b0ff;
}
.bg-green {
  background-color: #ABB582;
}

body.loaded a,
body.loaded button {
  transition: all 0.4s ease-in-out;
}

a,
button {
  font-family: "Ropa Sans", sans-serif;
  transition: none;
}
a:not(.disabled):not(:disabled),
button:not(.disabled):not(:disabled) {
  cursor: pointer;
}
a.disabled, a:disabled,
button.disabled,
button:disabled {
  cursor: default;
  pointer-events: none;
}

a {
  text-decoration: underline;
}
a:not(.btn):hover {
  opacity: 0.75;
}

html {
  font-size: 20px;
  font-family: "Ropa Sans", sans-serif;
}
@media screen and (max-width: 990px) {
  html {
    font-size: 16px;
  }
}

body {
  line-height: 1.5;
  color: #363636;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: normal;
}

h1 {
  font-size: 2.5em;
  letter-spacing: -0.047em;
  line-height: 1.06;
}

h2 {
  font-size: 2.3em;
  line-height: 1.15;
}

h3 {
  font-family: "Amalfi Coast", sans-serif;
  font-size: 2.1em;
  line-height: 1.15;
  letter-spacing: -0.047em;
}

h4 {
  font-size: 1.5em;
  line-height: 1.15;
}

h5 {
  font-size: 1.2em;
}

h6 {
  font-size: 1em;
}

p,
ul,
ol {
  margin-bottom: 1em;
  letter-spacing: 0.05em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.6em;
}

blockquote {
  border-left: 0.5rem solid currentColor;
  padding: 2rem;
  margin-left: 2rem;
  margin-bottom: 1rem;
  font-style: italic;
}
blockquote > *:last-child {
  margin-bottom: 0;
}

section ul li {
  list-style-type: disc;
  margin-left: 1em;
}
section ol li {
  margin-left: 1em;
}

header a,
footer a {
  display: inline-block;
}

.design-block a:not(.default) {
  text-decoration: none;
}
.design-block a:not(.default):not(.btn):hover {
  opacity: 1;
}
.design-block ul:not(.default) li,
.design-block ol:not(.default) li {
  list-style-type: none;
}

img.img-fw {
  width: 100%;
}

a obj {
  pointer-events: none;
}

.upload-btn {
  padding: 0.3rem 0.5rem;
  max-height: 61px;
  text-align: center;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  border: 1px solid #282a2d;
  color: #fff;
  background-color: #282a2d;
  display: inline-block;
}
.upload-btn.has-danger {
  border: 1px solid #f00 !important;
  color: #f00 !important;
  background-color: #fff;
}
.upload-btn.has-danger::before {
  color: #f00;
}
.upload-btn::before {
  color: #fff;
  content: "\e903";
  padding-right: 0.7rem;
  vertical-align: middle;
  display: inline-block;
  font-family: "icomoon" !important;
  font-size: 1.5rem;
  transition: all 0.3s ease-in-out;
}
@media screen and (min-width: 991px) {
  .upload-btn {
    min-width: 240px;
  }
}
.upload-btn:hover {
  border: 1px solid #282a2d;
  background-color: transparent;
  color: #282a2d;
}
.upload-btn:hover::before {
  color: #282a2d;
}

.back-btn {
  padding: 0.8rem 1rem;
  text-align: center;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  border: 1px solid #000000;
  color: #000000;
  background-color: transparent;
  display: inline-block;
}
.back-btn::before {
  color: #fff;
  content: "\e901";
  padding-right: 0.4rem;
  vertical-align: middle;
  display: inline-block;
  font-family: "icomoon" !important;
  font-size: 0.5rem;
  transition: all 0.3s ease-in-out;
}
@media screen and (min-width: 991px) {
  .back-btn {
    min-width: 240px;
  }
}
.back-btn-darkblue {
  border: 1px solid #282a2d;
  color: #282a2d;
}
.back-btn-darkblue:hover {
  background-color: #282a2d;
  color: #fff;
}
.back-btn-darkblue:hover::before {
  color: #fff;
}
.back-btn-darkblue-inverted {
  border: 1px solid #282a2d;
  background-color: #282a2d;
  color: #fff;
}
.back-btn-darkblue-inverted:hover {
  border: 1px solid #282a2d;
  background-color: transparent;
  color: #282a2d;
}
.back-btn-darkblue-inverted:hover::before {
  color: #282a2d;
}

.btn {
  padding: 0.8rem 1.5rem;
  text-align: center;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  border: 1px solid #000000;
  color: #000000;
  background-color: transparent;
  display: inline-block;
}
.btn.no-arrow::after {
  content: "";
}
.btn::after {
  color: #fff;
  content: "\e902";
  padding-left: 0.4rem;
  vertical-align: middle;
  display: inline-block;
  font-family: "icomoon" !important;
  font-size: 0.5rem;
  transition: all 0.3s ease-in-out;
}
.btn.active, .btn:hover {
  background-color: #000000;
  color: #fff;
}
.btn.active::after, .btn:hover::after {
  color: #000000;
}
@media screen and (min-width: 991px) {
  .btn {
    min-width: 240px;
  }
}
.btn.bold {
  font-weight: 700;
}
.btn-small {
  font-size: 0.9rem;
}
@media screen and (min-width: 991px) {
  .btn-small {
    min-width: 182px;
  }
}
.btn-large {
  font-size: 0.9rem;
}
@media screen and (min-width: 991px) {
  .btn-large {
    min-width: 328px;
  }
}
.btn-inverted {
  background-color: #000000;
  color: #fff;
}
.btn-inverted:hover {
  background-color: #fff;
  color: #000000;
}
.btn-white {
  background-color: #fff;
}
.btn-white:hover {
  background-color: #000000;
  color: #fff;
}
.btn-darkblue {
  border: 1px solid #282a2d;
  color: #282a2d;
}
.btn-darkblue:hover {
  background-color: #282a2d;
  color: #fff;
}
.btn-darkblue:hover::after {
  color: #fff;
}
.btn-darkblue-inverted {
  border: 1px solid #282a2d;
  background-color: #282a2d;
  color: #fff;
}
.btn-darkblue-inverted:hover {
  border: 1px solid #282a2d;
  background-color: transparent;
  color: #282a2d;
}
.btn-darkblue-inverted:hover::after {
  color: #282a2d;
}
.btn-darkblue-inverted-light {
  border: 1px solid #282a2d;
  background-color: #282a2d;
  color: #fff;
}
.btn-darkblue-inverted-light:hover {
  border: 1px solid #F3F4F7;
  background-color: transparent;
  color: #F3F4F7;
}
.btn-darkblue-inverted-light:hover::after {
  color: #F3F4F7;
}
.btn-olive {
  border: 1px solid #60654E;
  color: #60654E;
}
.btn-olive:hover {
  background-color: #60654E;
  color: #fff;
}
.btn-olive:hover::after {
  color: #fff;
}
.btn-olive-inverted {
  border: 1px solid #60654E;
  background-color: #60654E;
  color: #fff;
}
.btn-olive-inverted:hover {
  border: 1px solid #60654E;
  background-color: transparent;
  color: #60654E;
}
.btn-olive-inverted:hover::after {
  color: #60654E;
}
.btn-olive-inverted-light {
  border: 1px solid #60654E;
  background-color: #60654E;
  color: #fff;
}
.btn-olive-inverted-light:hover {
  border: 1px solid #F3F4F7;
  background-color: transparent;
  color: #F3F4F7;
}
.btn-olive-inverted-light:hover::after {
  color: #F3F4F7;
}
.btn-gray {
  border: 1px solid #F3F4F7;
  color: #F3F4F7;
}
.btn-gray:hover {
  background-color: #F3F4F7;
  color: #000000;
}
.btn-gray-inverted {
  border: 1px solid #F3F4F7;
  background-color: #F3F4F7;
  color: #000000;
}
.btn-gray-inverted:hover {
  border: 1px solid #F3F4F7;
  background-color: transparent;
  color: #F3F4F7;
}
.btn-darkgray {
  border: 1px solid #363636;
  color: #363636;
}
.btn-darkgray:hover {
  background-color: #363636;
  color: #fff;
}
.btn-darkgray:hover::after {
  color: #fff;
}
.btn-darkgray-inverted {
  border: 1px solid #363636;
  background-color: #363636;
  color: #fff;
}
.btn-darkgray-inverted:hover {
  border: 1px solid #363636;
  background-color: transparent;
  color: #363636;
}
.btn-darkgray-inverted:hover::after {
  color: #363636;
}
.btn-yellow {
  border: 1px solid #B2B384;
  color: #B2B384;
}
.btn-yellow::after {
  color: #B2B384;
}
.btn-yellow:hover {
  background-color: #B2B384;
  color: #000000;
}
.btn-yellow-inverted {
  border: 1px solid #B2B384;
  background-color: #B2B384;
  color: #fff;
}
.btn-yellow-inverted::after {
  color: #fff;
}
.btn-yellow-inverted:hover {
  border: 1px solid #B2B384;
  background-color: transparent;
  color: #B2B384;
}
.btn-yellow-inverted:hover::after {
  color: #B2B384;
}
.btn-purple {
  border: 1px solid #720067;
  color: #720067;
}
.btn-purple:hover {
  background-color: #720067;
  color: #fff;
}
.btn-purple-inverted {
  border: 1px solid #fff;
  background-color: #720067;
  color: #fff;
}
.btn-purple-inverted:hover {
  border: 1px solid #720067;
  background-color: transparent;
  color: #720067;
}
.btn-pink {
  border: 1px solid #ea80fc;
  color: #ea80fc;
}
.btn-pink:hover {
  background-color: #ea80fc;
  color: #363636;
}
.btn-pink-inverted {
  border: 1px solid #ea80fc;
  background-color: #ea80fc;
  color: #363636;
}
.btn-pink-inverted:hover {
  border: 1px solid #ea80fc;
  background-color: transparent;
  color: #ea80fc;
}
.btn-blue {
  border: 1px solid #00b0ff;
  color: #00b0ff;
}
.btn-blue:hover {
  background-color: #00b0ff;
  color: #363636;
}
.btn-blue-inverted {
  border: 1px solid #00b0ff;
  background-color: #00b0ff;
  color: #363636;
}
.btn-blue-inverted:hover {
  border: 1px solid #00b0ff;
  background-color: transparent;
  color: #00b0ff;
}
.btn-green {
  border: 1px solid #ABB582;
  color: #ABB582;
}
.btn-green:hover {
  background-color: #ABB582;
  color: #363636;
}
.btn-green-inverted {
  border: 1px solid #ABB582;
  background-color: #ABB582;
  color: #363636;
}
.btn-green-inverted:hover {
  border: 1px solid #ABB582;
  background-color: transparent;
  color: #ABB582;
}
.btn-scarlet {
  border: 1px solid #f44336;
  color: #f44336;
}
.btn-scarlet:hover {
  background-color: #f44336;
  color: #fff;
}
.btn-scarlet-inverted {
  background-color: #f44336;
  border: 1px solid #f44336;
  color: #fff;
}
.btn-scarlet-inverted:hover {
  border: 1px solid #f44336;
  background-color: transparent;
  color: #f44336;
}
.btn-orange {
  border: 1px solid #ff9100;
  color: #ff9100;
}
.btn-orange:hover {
  background-color: #ff9100;
  color: #fff;
}
.btn-orange-inverted {
  background-color: #ff9100;
  color: #fff;
  border: 1px solid #ff9100;
}
.btn-orange-inverted:hover {
  border: 1px solid #ff9100;
  background-color: transparent;
  color: #ff9100;
}
.btn-red {
  border: 1px solid #f20042;
  color: #f20042;
}
.btn-red:hover {
  background-color: #f20042;
  color: #fff;
}
.btn-red-inverted {
  background-color: #f20042;
  border: 1px solid #f20042;
  color: #fff;
}
.btn-red-inverted:hover {
  border: 1px solid #f20042;
  background-color: transparent;
  color: #f20042;
}
.btn-rounded {
  border-radius: 1rem;
}
.btn[class^=btn-animated], .btn[class*=" btn-animated"] {
  border: none !important;
  position: relative;
  overflow: hidden;
}
.btn[class^=btn-animated] span, .btn[class*=" btn-animated"] span {
  z-index: 5;
  position: relative;
}
.btn[class^=btn-animated]:hover:before, .btn[class*=" btn-animated"]:hover:before {
  right: -50%;
  top: -50%;
}
.btn[class^=btn-animated]:before, .btn[class*=" btn-animated"]:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  top: -5px;
  transition: all 0.7s;
  width: 200%;
  height: 300%;
  right: 100%;
}
.btn[class^=btn-animated][class*=-from-white], .btn[class*=" btn-animated"][class*=-from-white] {
  background-color: #fff;
  color: #000000;
}
.btn[class^=btn-animated][class*=-from-black], .btn[class*=" btn-animated"][class*=-from-black] {
  background-color: #000000;
  color: #fff;
}
.btn[class^=btn-animated][class*=-from-gray], .btn[class*=" btn-animated"][class*=-from-gray] {
  background-color: #F3F4F7;
  color: #000000;
}
.btn[class^=btn-animated][class*=-from-darkgray], .btn[class*=" btn-animated"][class*=-from-darkgray] {
  background-color: #3b3b3b;
  color: #fff;
}
.btn[class^=btn-animated][class*=-from-blue], .btn[class*=" btn-animated"][class*=-from-blue] {
  background-color: #00b0ff;
  color: #000000;
}
.btn[class^=btn-animated][class*=-from-green], .btn[class*=" btn-animated"][class*=-from-green] {
  background-color: #ABB582;
  color: #000000;
}
.btn[class^=btn-animated][class*=-from-pink], .btn[class*=" btn-animated"][class*=-from-pink] {
  background-color: #ea80fc;
  color: #000000;
}
.btn[class^=btn-animated][class*=-from-yellow], .btn[class*=" btn-animated"][class*=-from-yellow] {
  background-color: #B2B384;
  color: #fff;
}
.btn[class^=btn-animated][class*=-from-red], .btn[class*=" btn-animated"][class*=-from-red] {
  background-color: #f20042;
  color: #fff;
}
.btn[class^=btn-animated][class*=-from-orange], .btn[class*=" btn-animated"][class*=-from-orange] {
  background-color: #ff9100;
  color: #fff;
}
.btn[class^=btn-animated][class*=-from-scarlet], .btn[class*=" btn-animated"][class*=-from-scarlet] {
  background-color: #f44336;
  color: #fff;
}
.btn[class^=btn-animated][class*=-from-purple], .btn[class*=" btn-animated"][class*=-from-purple] {
  background-color: #720067;
  color: #fff;
}
.btn[class^=btn-animated][class*=-to-pink]:hover, .btn[class*=" btn-animated"][class*=-to-pink]:hover {
  color: #000000;
}
.btn[class^=btn-animated][class*=-to-pink]:before, .btn[class*=" btn-animated"][class*=-to-pink]:before {
  background-color: #ea80fc;
}
.btn[class^=btn-animated][class*=-to-blue]:hover, .btn[class*=" btn-animated"][class*=-to-blue]:hover {
  color: #000000;
}
.btn[class^=btn-animated][class*=-to-blue]:before, .btn[class*=" btn-animated"][class*=-to-blue]:before {
  background-color: #00b0ff;
}
.btn[class^=btn-animated][class*=-to-green]:hover, .btn[class*=" btn-animated"][class*=-to-green]:hover {
  color: #000000;
}
.btn[class^=btn-animated][class*=-to-green]:before, .btn[class*=" btn-animated"][class*=-to-green]:before {
  background-color: #ABB582;
}
.btn[class^=btn-animated][class*=-to-yellow]:hover, .btn[class*=" btn-animated"][class*=-to-yellow]:hover {
  color: #000000;
}
.btn[class^=btn-animated][class*=-to-yellow]:before, .btn[class*=" btn-animated"][class*=-to-yellow]:before {
  background-color: #B2B384;
}
.btn[class^=btn-animated][class*=-to-red]:hover, .btn[class*=" btn-animated"][class*=-to-red]:hover {
  color: #fff;
}
.btn[class^=btn-animated][class*=-to-red]:before, .btn[class*=" btn-animated"][class*=-to-red]:before {
  background-color: #f20042;
}
.btn[class^=btn-animated][class*=-to-purple]:hover, .btn[class*=" btn-animated"][class*=-to-purple]:hover {
  color: #fff;
}
.btn[class^=btn-animated][class*=-to-purple]:before, .btn[class*=" btn-animated"][class*=-to-purple]:before {
  background-color: #720067;
}
.btn[class^=btn-animated][class*=-to-scarlet]:hover, .btn[class*=" btn-animated"][class*=-to-scarlet]:hover {
  color: #fff;
}
.btn[class^=btn-animated][class*=-to-scarlet]:before, .btn[class*=" btn-animated"][class*=-to-scarlet]:before {
  background-color: #f44336;
}
.btn[class^=btn-animated][class*=-to-orange]:hover, .btn[class*=" btn-animated"][class*=-to-orange]:hover {
  color: #fff;
}
.btn[class^=btn-animated][class*=-to-orange]:before, .btn[class*=" btn-animated"][class*=-to-orange]:before {
  background-color: #ff9100;
}
.btn[class^=btn-animated][class*=-to-white]:hover, .btn[class*=" btn-animated"][class*=-to-white]:hover {
  color: #000000;
}
.btn[class^=btn-animated][class*=-to-white]:before, .btn[class*=" btn-animated"][class*=-to-white]:before {
  background-color: #fff;
}
.btn[class^=btn-animated][class*=-to-black]:hover, .btn[class*=" btn-animated"][class*=-to-black]:hover {
  color: #fff;
}
.btn[class^=btn-animated][class*=-to-black]:before, .btn[class*=" btn-animated"][class*=-to-black]:before {
  background-color: #000000;
}
.btn[class^=btn-animated][class*=-to-gray]:hover, .btn[class*=" btn-animated"][class*=-to-gray]:hover {
  color: #000000;
}
.btn[class^=btn-animated][class*=-to-gray]:before, .btn[class*=" btn-animated"][class*=-to-gray]:before {
  background-color: #F3F4F7;
}
.btn[class^=btn-animated][class*=-to-darkgray]:hover, .btn[class*=" btn-animated"][class*=-to-darkgray]:hover {
  color: #fff;
}
.btn[class^=btn-animated][class*=-to-darkgray]:before, .btn[class*=" btn-animated"][class*=-to-darkgray]:before {
  background-color: #F3F4F7;
}

.table-container {
  overflow-x: auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: inherit;
}
table td,
table th {
  vertical-align: top;
  border: 1px solid #F3F4F7;
  padding: 5px;
}
table th {
  font-weight: 700;
  text-align: center;
}
table.text-left td,
table.text-left th {
  text-align: left;
}
table.text-right td,
table.text-right th {
  text-align: right;
}
table.text-center td,
table.text-center th {
  text-align: center;
}
table.table-inverted {
  color: #CFCFCF;
}
table.table-valign-top td,
table.table-valign-top th {
  vertical-align: top;
}
table.table-valign-middle td,
table.table-valign-middle th {
  vertical-align: middle;
}
table.table-valign-bottom td,
table.table-valign-bottom th {
  vertical-align: bottom;
}

xmp {
  font-size: 0.7rem;
  font-family: monospace;
}

.code-container {
  overflow-x: auto;
  background-color: #F3F4F7;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
body {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
body main {
  flex: 1 1 auto;
}
body.menu-opened main {
  display: none;
}
body.menu-opened .header-logo-wrapper {
  left: 50%;
  transform: translateX(-45%);
}

header {
  margin-bottom: -3rem;
}

.header-hamburger {
  position: relative;
  z-index: 20;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  margin-left: auto;
}
.header-hamburger:focus {
  outline: none;
}
@media screen and (min-width: 991px) {
  .header-hamburger {
    display: none;
  }
}
.header-hamburger svg {
  height: 52px;
  width: auto;
}
.header-hamburger .line {
  fill: none;
  stroke: black;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.header-hamburger .line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.header-hamburger .line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.header-hamburger .line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.header-hamburger.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.header-hamburger.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.header-hamburger.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

.header-container {
  background-color: transparent;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.header-container a {
  text-decoration: none;
  border-bottom: 3px solid transparent;
}
.header-container a:hover, .header-container a.active {
  border-bottom-color: #60654E;
}
.header-container .header-logo-wrapper {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  max-width: 241px;
  background-color: #fff;
  padding: 3rem 2rem 2rem 2rem;
  transition: all 0.3s ease-in-out;
}
.header-container .header-logo-wrapper-image {
  margin-bottom: 0.5rem;
}
.header-container .header-logo-wrapper p {
  font-size: 0.65rem;
  line-height: 1.3;
  letter-spacing: 0.1em;
  margin-bottom: 0;
}
@media screen and (max-width: 990px) {
  .header-container .header-logo-wrapper {
    margin-top: -3rem;
  }
}
@media screen and (max-width: 479px) {
  .header-container .header-logo-wrapper {
    padding: 2rem 1rem 1rem;
  }
}
.header-container .header-logo-wrapper:hover {
  border-bottom-color: transparent;
}
.header-container nav {
  position: relative;
  z-index: 10;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}
.header-container nav ul {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 1.1rem;
}
@media screen and (max-width: 1599px) {
  .header-container nav ul {
    column-gap: 0.5rem;
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 1249px) {
  .header-container nav ul {
    column-gap: 0.3rem;
    font-size: 0.8rem;
  }
}
.header-container nav ul li {
  padding-right: 1.5rem;
}
@media screen and (max-width: 1599px) {
  .header-container nav ul li {
    padding-right: 0.5rem;
  }
}
@media screen and (max-width: 1249px) {
  .header-container nav ul li {
    padding-right: 0.3rem;
  }
}
.header-container nav ul li:last-child {
  padding-right: 0;
}
@media screen and (max-width: 1599px) {
  .header-container nav ul li:last-child {
    padding-right: 0;
  }
}
@media screen and (max-width: 1249px) {
  .header-container nav ul li:last-child {
    padding-right: 0;
  }
}
.header-container nav ul li:last-child a {
  border-bottom: 1px solid #60654E;
  margin-left: 3rem;
  font-size: 0.9rem;
}
@media screen and (max-width: 1599px) {
  .header-container nav ul li:last-child a {
    margin-left: 1rem;
  }
}
@media screen and (max-width: 1249px) {
  .header-container nav ul li:last-child a {
    margin-left: 0.5rem;
  }
}
@media screen and (max-width: 990px) {
  .header-container nav {
    flex: 1 1 100%;
    display: none;
    margin-left: calc(-15px - 2%);
    margin-right: calc(-15px - 2%);
    width: 100%;
  }
  .header-container nav ul {
    margin-top: 140px;
    min-height: calc(100vh - 215px);
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 990px) and (max-width: 384px) {
  .header-container nav ul {
    margin-top: 120px;
    min-height: calc(100vh - 195px);
  }
}
@media screen and (max-width: 990px) {
  .header-container nav ul a {
    width: 100%;
    text-align: center;
  }
}

.footer-wrapper {
  background-color: #60654E;
  padding: 3rem 1rem 0 1rem;
}
.footer-wrapper .logo-wrapper {
  max-width: 370px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
}
.footer-wrapper .logo-wrapper-list {
  margin-top: 1rem;
  font-size: 0.65rem;
  color: #fff;
}
.footer-wrapper .logo-wrapper-list p {
  letter-spacing: 0.1em;
}
.footer-wrapper .logo-wrapper-list ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}
@media screen and (max-width: 990px) {
  .footer-wrapper .logo-wrapper-list ul {
    justify-content: space-evenly;
  }
}
.footer-wrapper .menu-wrapper {
  max-width: 1100px;
  margin: auto;
  margin-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.footer-wrapper .menu-wrapper ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  font-size: 0.85rem;
  color: #fff;
}
@media screen and (max-width: 990px) {
  .footer-wrapper .menu-wrapper ul {
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .footer-wrapper .menu-wrapper ul li {
    margin-bottom: 1rem;
  }
}
.footer-wrapper .social-wrapper {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}
.footer-wrapper .social-wrapper ul {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  color: #fff;
}
.footer-wrapper .social-wrapper ul li {
  padding-right: 1rem;
}
.footer-wrapper .copyright-wrapper {
  text-align: center;
}
.footer-wrapper .copyright-wrapper p {
  font-size: 0.85rem;
  color: #939393;
  margin-bottom: 0;
}
@media screen and (max-width: 479px) {
  .footer-wrapper .copyright-wrapper {
    margin-bottom: 4rem;
  }
}
.footer-wrapper .footer-button {
  text-align: end;
  margin-top: -2rem;
  margin-right: -1rem;
}

form {
  position: relative;
}
form input.has-danger,
form select.has-danger,
form textarea.has-danger {
  border-bottom: 1px solid #f00 !important;
}
form .input-wrapper.has-danger {
  border-color: #f00 !important;
}
form .input-wrapper.has-danger:before, form .input-wrapper.has-danger:after {
  background-color: #f00 !important;
}
form input[type=radio].has-danger + label:before,
form input[type=checkbox].has-danger + label:before {
  border-color: #f00 !important;
}
form ::placeholder {
  color: #363636;
}
form input,
form select,
form textarea {
  font-size: 1rem;
  color: #363636;
  font-family: inherit;
  padding: 1em 1em;
}
form input,
form textarea,
form select,
form .select-wrapper {
  width: 100%;
  border: none;
  background-color: #EDEDED;
}
form input {
  min-height: 3rem;
}
form .select-wrapper {
  min-height: 3rem;
  position: relative;
}
form .select-wrapper:before {
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5em;
  z-index: 10;
  color: #720067;
  pointer-events: none;
}
form .select-wrapper:after {
  position: absolute;
  display: block;
  content: "";
  right: 0;
  top: 0;
  width: 1.6em;
  height: 100%;
  font-size: 2em;
  z-index: 5;
  pointer-events: none;
}
form select {
  min-height: 3rem;
  width: 100%;
  border-radius: 0;
  appearance: none;
}
form select:disabled {
  color: #F3F4F7;
}
form select option [disabled=disabled] {
  color: #F3F4F7;
}
form select:focus-visible {
  outline: transparent;
}
form p.question {
  font-size: calc(24px + 0.45vw);
}
@media screen and (min-width: 1919px) {
  form p.question {
    font-size: 33px;
  }
}
@media screen and (max-width: 479px) {
  form p.question {
    font-size: 24px;
  }
}
form p.c-info {
  font-size: calc(16px + 0.4vw);
  letter-spacing: 0.1em;
}
@media screen and (min-width: 1919px) {
  form p.c-info {
    font-size: 24px;
  }
}
@media screen and (max-width: 479px) {
  form p.c-info {
    font-size: 16px;
  }
}
form label {
  text-align: left;
  display: block;
  padding-bottom: 0.2em;
}
form label.file_upload {
  display: inline-flex;
  align-items: flex-start;
}
form label.file_upload input[type=file] {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  display: none;
}
form label.file_upload mark {
  background-color: transparent;
  color: #fff;
  opacity: 1;
  font-size: 1rem;
  margin-left: 0.5em;
  transition: all 0.3s ease-in-out;
}
form label.file_upload .btn {
  cursor: pointer;
}
form label.file_upload .btn.empty {
  color: #fff;
}
form label.file_upload .btn:not(.empty):hover + mark {
  opacity: 0;
}
form .row {
  padding-top: 0px;
  padding-bottom: 0px;
}
form .row .col.file-upload-container {
  text-align: left;
}
form .row .col.file-upload-container.has-danger {
  border: 1px solid #f00;
}
form .row .col.file-upload-container.has-danger mark {
  color: #f00;
}
@media screen and (max-width: 990px) {
  form .row .col {
    margin-bottom: 15px;
  }
}
form .checkbox input {
  display: none;
}
form .checkbox label {
  position: relative;
  padding-left: 4em;
  line-height: 3em;
  cursor: pointer;
}
form .checkbox label:before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 3em;
  height: 3em;
  background-color: #fff;
}
form .checkbox label:after {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  padding-left: 0.4em;
  content: "";
  font-size: calc(30px + 0.9vw);
  color: #720067;
}
@media screen and (min-width: 1919px) {
  form .checkbox label:after {
    font-size: 48px;
  }
}
@media screen and (max-width: 479px) {
  form .checkbox label:after {
    font-size: 30px;
  }
}
form .checkbox input:checked + label:after {
  content: "V";
}

.main-slider {
  padding-top: 0;
  padding-bottom: 0;
}
.main-slider .fw-slider-header {
  background-color: #fff;
  position: absolute;
  width: 427px;
  height: 96px;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-slider .fw-slider-header h5 {
  margin-bottom: 0;
  color: #282a2d;
  letter-spacing: 0.16em;
}
@media screen and (max-width: 990px) {
  .main-slider .fw-slider-header {
    width: 350px;
    height: 80px;
  }
}
@media screen and (max-width: 767px) {
  .main-slider .fw-slider-header {
    width: 300px;
  }
}
@media screen and (max-width: 479px) {
  .main-slider .fw-slider-header {
    width: 250px;
    height: 70px;
  }
}
.main-slider .fw-slider-social {
  position: absolute;
  right: 0;
  top: 20%;
  width: 34px;
  height: 207px;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  z-index: 5;
}
.main-slider .fw-slider-social-icon {
  background-color: #60654E;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.7rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.main-slider .fw-slider-social-icon:hover {
  filter: brightness(120%);
}

.services {
  padding-top: 0;
  padding-bottom: 0;
}
.services-wrapper {
  display: flex;
}
@media screen and (max-width: 990px) {
  .services-wrapper {
    display: block;
  }
}
.services-wrapper-left {
  flex-basis: 35%;
  background-color: #60654E;
  background-image: url(../../images/icons/marxlayne.svg);
  color: #fff;
  background-repeat: no-repeat;
  background-position: bottom;
  padding-top: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 6rem;
}
@media screen and (max-width: 990px) {
  .services-wrapper-left {
    padding-bottom: 4rem;
  }
}
.services-wrapper-left.service-left-paddings {
  padding: 3rem 1rem;
  display: flex;
  align-items: center;
}
.services-wrapper-left.service-left-paddings h2 {
  letter-spacing: -0.047em;
}
.services-wrapper-left.service-left-paddings .services-wrapper-left-container {
  max-width: 525px;
}
.services-wrapper-left-container {
  max-width: 405px;
  margin-left: auto;
  margin-right: auto;
}
.services-wrapper-left-image {
  text-align: end;
}
@media screen and (max-width: 990px) {
  .services-wrapper-left-image {
    width: 50%;
    margin-left: auto;
  }
}
.services-wrapper-right {
  flex-basis: 65%;
  background-color: rgba(243, 244, 247, 0.4);
  padding: 3rem 2rem 2rem;
}
@media screen and (max-width: 990px) {
  .services-wrapper-right {
    flex-basis: 60%;
  }
}
@media screen and (max-width: 479px) {
  .services-wrapper-right {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.services-wrapper-right .service-right-paddings {
  max-width: 820px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}
.services-wrapper-right-header {
  color: #282a2d;
}
.services-wrapper-right-header h5 {
  letter-spacing: 0.16em;
}
.services-wrapper-right-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}
@media screen and (max-width: 1249px) {
  .services-wrapper-right-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 479px) {
  .services-wrapper-right-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
.services-wrapper-right-item {
  position: relative;
}
.services-wrapper-right-item::before {
  content: "";
  background: rgba(96, 101, 78, 0.72);
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
}
.services-wrapper-right-item:hover {
  opacity: 1 !important;
}
.services-wrapper-right-item:hover::before {
  opacity: 1 !important;
}
.services-wrapper-right-item img {
  width: 100%;
  height: 100%;
}
.services-wrapper-right-item-header {
  position: absolute;
  padding-right: 1rem;
  top: 50%;
  right: 0;
  max-width: 200px;
  transform: translateY(-50%);
}
.services-wrapper-right-link {
  text-align: end;
  margin-top: 2rem;
  color: #6F6F6F;
  font-size: 0.9rem;
}
.services-wrapper-right-link::after {
  content: "\e902";
  font-family: "icomoon" !important;
  vertical-align: middle;
  display: inline-block;
  font-family: "icomoon" !important;
  font-size: 0.5rem;
  transition: all 0.3s ease-in-out;
  padding-left: 0.2rem;
}
.services-logo-inner h5 {
  position: relative;
  z-index: 5;
  font-size: 1.45rem;
  transition: all 0.4s linear;
  margin-bottom: 0;
  padding: 0.5rem;
}
.services-logo-inner:hover {
  background-color: #60654E;
}
.services-logo-inner:hover h5 {
  color: #fff;
}
.services-logo-inner:hover:after {
  opacity: 1;
}
.services-logo-inner:after {
  content: "";
  opacity: 0;
  display: block;
  background-image: url(../../images/inner-logo.svg);
  transition: all 0.4s linear;
  background-size: 80% auto;
  background-position: 50%;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  background-repeat: no-repeat;
}
@media screen and (max-width: 990px) {
  .services-logo-inner {
    height: 0;
    padding-top: 25%;
    padding-bottom: 25%;
  }
}

.industries {
  background-color: #F3F4F7;
}
.industries-header {
  text-align: center;
  color: #282a2d;
}

.response {
  padding-top: 0;
  padding-bottom: 0;
}
.response-wrapper {
  background-image: url(../../images/response-slider/background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.reasons {
  background: linear-gradient(to top, #fdfdfd, #F3F4F7);
  padding-top: 0;
  padding-bottom: 0;
}
.reasons-wrapper {
  max-width: 1920px;
  padding-left: 4rem;
  display: flex;
  justify-content: space-between;
  margin: auto;
}
@media screen and (max-width: 1599px) {
  .reasons-wrapper {
    padding-left: 2rem;
  }
}
@media screen and (max-width: 990px) {
  .reasons-wrapper {
    flex-wrap: wrap;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.reasons-list {
  display: flex;
  column-gap: 1rem;
}
@media screen and (max-width: 1249px) {
  .reasons-list {
    display: block;
  }
}
@media screen and (max-width: 990px) {
  .reasons-list {
    margin: auto;
    order: 1;
  }
}
@media screen and (max-width: 767px) {
  .reasons-list {
    display: block;
    margin: auto;
  }
}
.reasons-list-col {
  border-right: 1px solid #e2e2e2;
  display: flex;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
@media screen and (max-width: 1249px) {
  .reasons-list-col {
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-right: none;
  }
}
@media screen and (max-width: 990px) {
  .reasons-list-col {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .reasons-list-col {
    display: block;
    border-right: none;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.reasons-list-col:last-child {
  border-right: none;
}
.reasons-list-col-inside {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
@media screen and (max-width: 1249px) {
  .reasons-list-col-inside {
    flex-direction: row;
  }
}
@media screen and (max-width: 767px) {
  .reasons-list-col-inside {
    display: block;
  }
}
.reasons-list-item {
  max-width: 335px;
  display: flex;
  align-items: flex-start;
  margin-right: 1rem;
  margin-left: 1rem;
  column-gap: 1rem;
  flex-basis: 50%;
}
@media screen and (max-width: 767px) {
  .reasons-list-item {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
.reasons-list-item-image {
  margin-top: 0.5rem;
  flex-basis: 15%;
  display: flex;
  justify-content: center;
  color: #1b1b1b;
}
.reasons-list-item-info {
  flex-basis: 85%;
}
.reasons-list-item-info p {
  margin-bottom: 0;
}
.reasons-list-item-info-header p {
  color: #282a2d;
  font-size: 1.4rem;
  letter-spacing: -0.047em;
}
.reasons-list-item-info-text {
  font-size: 0.85rem;
  color: #939393;
}
.reasons-image {
  flex-grow: 1;
}
@media screen and (max-width: 990px) {
  .reasons-image {
    flex-grow: 0;
    margin-left: auto;
  }
}
.reasons-image img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 990px) {
  .reasons-image img {
    height: auto;
    width: auto;
  }
}

.process {
  background-color: #60654E;
  background-image: url(../../images/icons/marxlayne.svg);
  background-repeat: no-repeat;
  background-position: right 4rem top 4rem;
  padding-bottom: 0;
}
.process-header-wrapper {
  max-width: 500px;
}
.process-header-wrapper-text {
  font-size: 0.85rem;
  color: #939393;
}

.combine-header h2,
.combine-header h3 {
  display: inline-block;
  letter-spacing: -0.047em;
}
.combine-header h2 {
  margin-right: 0.5rem;
}

.team {
  background-color: #282a2d;
  padding-top: 9rem;
}
@media screen and (max-width: 990px) {
  .team {
    padding-top: 7rem;
  }
}
@media screen and (max-width: 479px) {
  .team {
    padding-top: 5rem;
  }
}
.team-flexbox {
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  padding-left: 2rem;
  padding-right: 0.5rem;
}
@media screen and (max-width: 1399px) {
  .team-flexbox {
    padding-left: 1rem;
  }
}
@media screen and (max-width: 990px) {
  .team-flexbox {
    display: block;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.team-flexbox .process-header-wrapper {
  flex-basis: 18%;
}
@media screen and (max-width: 990px) {
  .team-flexbox .process-header-wrapper {
    padding-left: 2rem;
    margin-bottom: 2rem;
  }
}
@media screen and (max-width: 479px) {
  .team-flexbox .process-header-wrapper {
    padding-left: 1rem;
  }
}

.news {
  position: relative;
  background-image: url(../../images/news-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.news.studies {
  background-image: none;
  background-color: #EDEDED;
}
.news.studies::before {
  opacity: 0.02;
}
.news.studies .news-wrapper-col1 {
  flex-basis: 40%;
}
.news.studies .news-wrapper-col1 .news-wrapper-col1-item-image {
  position: relative;
  display: flex;
}
.news.studies .news-wrapper-col1 .news-wrapper-col1-item-image img {
  flex-grow: 1;
}
.news.studies .news-wrapper-col1 .news-wrapper-col1-item-btn {
  position: absolute;
  right: 0;
  bottom: 0;
}
.news.studies .news-wrapper-col1 .news-wrapper-col1-item-btn:hover {
  border: 1px solid #363636;
  background-color: #363636;
  color: #fff;
}
.news.studies .news-wrapper-col1 .news-wrapper-col1-item-btn:hover::after {
  color: #fff;
}
.news.studies .news-wrapper-col2 {
  flex-basis: 60%;
}
.news::before {
  content: "";
  background-image: url(../../images/vertical-marx.svg);
  opacity: 0.01;
  background-repeat: no-repeat;
  position: absolute;
  width: 25%;
  bottom: 0;
  top: 0;
  left: 0;
}
.news-wrapper {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
}
@media screen and (max-width: 767px) {
  .news-wrapper {
    display: block;
  }
}
.news-wrapper-col1 {
  display: flex;
  flex-basis: 45%;
  flex-grow: 1;
  margin-bottom: 1rem;
}
.news-wrapper-col1-item {
  flex-grow: 1;
  display: flex;
}
.news-wrapper-col1-item-link {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.news-wrapper-col1-item-info {
  position: relative;
  flex-grow: 1;
  background-color: #fff;
  padding: 2rem;
}
.news-wrapper-col1-item-info-header {
  color: #282a2d;
  font-size: 1.2rem;
}
.news-wrapper-col1-item-info-date {
  font-size: 0.75rem;
  font-style: italic;
  color: #60654E;
}
.news-wrapper-col1-item-info-text {
  font-size: 0.85rem;
  color: #363636;
}
.news-wrapper-col1-item-info-btn {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
}
.news-wrapper-col1-item-info-btn:hover {
  border: 1px solid #363636;
  background-color: #363636;
  color: #fff;
}
.news-wrapper-col1-item-info-btn:hover::after {
  color: #fff;
}
.news-wrapper-col2 {
  flex-basis: 45%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  gap: 1rem;
}
@media screen and (max-width: 767px) {
  .news-wrapper-col2 {
    justify-content: space-between;
  }
}
@media screen and (max-width: 479px) {
  .news-wrapper-col2 {
    display: block;
  }
}
.news-wrapper-col2-item {
  flex-basis: 45%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
@media screen and (max-width: 479px) {
  .news-wrapper-col2-item {
    margin-bottom: 1rem;
  }
}
.news-wrapper-col2-item:hover .news-wrapper-col2-item-image {
  opacity: 1 !important;
}
.news-wrapper-col2-item:hover .news-wrapper-col2-item-image::before, .news-wrapper-col2-item:hover .news-wrapper-col2-item-image::after {
  opacity: 1 !important;
}
.news-wrapper-col2-item:hover .news-wrapper-col2-item-info {
  background-color: #363636;
}
.news-wrapper-col2-item:hover .news-wrapper-col2-item-info-text p {
  color: #fff;
}
.news-wrapper-col2-item:hover .news-wrapper-col2-item-info-date {
  color: #B2B384;
}
.news-wrapper-col2-item-image {
  position: relative;
  display: flex;
}
.news-wrapper-col2-item-image::before {
  content: "";
  background: rgba(96, 101, 78, 0.72);
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
}
.news-wrapper-col2-item-image::after {
  content: "\e904";
  font-family: "icomoon" !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 73px;
  height: 73px;
  background-color: #ABB582;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  color: #fff;
  font-size: 1.5rem;
  transition: all 0.3s ease-in-out;
}
.news-wrapper-col2-item-image img {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.news-wrapper-col2-item-info {
  flex-grow: 1;
  transition: all 0.3s ease-in-out;
  background-color: #fff;
  padding: 1rem;
}
.news-wrapper-col2-item-info p {
  transition: all 0.3s ease-in-out;
  margin-bottom: 0.5rem;
}
.news-wrapper-col2-item-info-text p {
  color: #282a2d;
  letter-spacing: 0em;
}
.news-wrapper-col2-item-info-date {
  font-size: 0.75rem;
  font-style: italic;
  color: #60654E;
}

.history {
  position: relative;
  background-image: url(../../images/history-slider/history-background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 7rem;
  padding-bottom: 5rem;
}
.history:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(54, 54, 54, 0.44);
}
.history-header {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 35%;
  background-color: #363636;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media screen and (max-width: 990px) {
  .history-header {
    width: 40%;
  }
}
@media screen and (max-width: 479px) {
  .history-header {
    width: fit-content;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
.history-header h5 {
  margin-bottom: 0;
  letter-spacing: 0.16em;
}
.history-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  max-width: 1550px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media screen and (max-width: 990px) {
  .history-wrapper {
    display: block;
  }
}
@media screen and (max-width: 479px) {
  .history-wrapper {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.history-wrapper-leftside {
  flex-basis: 55%;
}
@media screen and (max-width: 990px) {
  .history-wrapper-leftside {
    max-width: 80%;
    margin: auto;
  }
}
@media screen and (max-width: 767px) {
  .history-wrapper-leftside {
    max-width: 100%;
  }
}
.history-wrapper-rightside {
  flex-basis: 45%;
  max-width: 400px;
  text-align: end;
  color: #fff;
}
@media screen and (max-width: 990px) {
  .history-wrapper-rightside {
    margin-left: auto;
    margin-right: 3rem;
    margin-top: 3rem;
  }
}
.history-wrapper-rightside-text {
  font-size: 1.5rem;
}
.history-wrapper-rightside-text p {
  line-height: 1.1;
  letter-spacing: -0.047em;
  margin-bottom: 0.5rem;
}
.history-wrapper-rightside-sign {
  font-size: 1.2rem;
  color: #C5CCAA;
}
.history-wrapper-rightside-sign p {
  letter-spacing: -0.077em;
}

.inside-history {
  background-color: rgba(243, 244, 247, 0.4);
}
.inside-history-wrapper {
  max-width: 1920px;
  margin: auto;
}
.inside-history-flexbox {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 990px) {
  .inside-history-flexbox {
    display: block;
  }
}
.inside-history-flexbox-col1 {
  flex-basis: 40%;
  display: flex;
}
.inside-history-flexbox-col1 img {
  flex-grow: 1;
  width: 100%;
  object-fit: cover;
}
.inside-history-flexbox-col2 {
  flex-basis: 60%;
  padding: 3rem 5rem;
}
@media screen and (max-width: 1249px) {
  .inside-history-flexbox-col2 {
    padding: 2rem;
  }
}
@media screen and (max-width: 479px) {
  .inside-history-flexbox-col2 {
    padding: 2rem 1rem;
  }
}
.inside-history-flexbox-col2 h2 {
  letter-spacing: -0.047em;
  color: #282a2d;
}
.inside-history-flexbox-col2 h5 {
  letter-spacing: 0.16em;
  color: #282a2d;
}
.inside-history-flexbox-col2-container {
  max-width: 750px;
  margin: auto;
}
.inside-history-flexbox-col2-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5rem;
}
.inside-history-flexbox-col2-header h2 {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .inside-history-flexbox-col2-header {
    display: block;
    margin-bottom: 2rem;
  }
  .inside-history-flexbox-col2-header h2 {
    margin-bottom: 2rem;
  }
}

.responsibility {
  position: relative;
  background-color: #60654E;
  padding-top: 6rem;
}
.responsibility-wrapper {
  color: #fff;
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  margin-bottom: 2rem;
}
@media screen and (max-width: 767px) {
  .responsibility-wrapper {
    display: block;
  }
}
.responsibility-wrapper-header {
  flex-basis: 35%;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .responsibility-wrapper-header {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
.responsibility-wrapper-text {
  flex-basis: 65%;
}

.opportunities {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
@media screen and (max-width: 990px) {
  .opportunities {
    background-image: none !important;
    background-color: #F3F4F7;
  }
}
.opportunities-wrapper {
  margin-top: 2rem;
  max-width: 700px;
}
.opportunities-wrapper h2,
.opportunities-wrapper h3,
.opportunities-wrapper h5 {
  color: #282a2d;
}
.opportunities-wrapper h2,
.opportunities-wrapper h3 {
  letter-spacing: -0.047em;
}
.opportunities-wrapper h2 {
  margin-bottom: 0.5rem;
}
.opportunities-wrapper h5 {
  letter-spacing: 0.16em;
  margin-bottom: 1.5rem;
}
.opportunities-wrapper-link {
  max-width: 560px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 479px) {
  .opportunities-wrapper-link {
    display: inline-block;
  }
  .opportunities-wrapper-link a {
    margin-top: 2rem;
  }
}

.expertise {
  padding-bottom: 3rem;
}
.expertise-bg {
  background-color: #60654E;
  background-image: url(../../images/icons/marxlayne.svg);
  background-repeat: no-repeat;
  background-position: bottom left 10%;
}
.expertise-wrapper {
  color: #fff;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 1350px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
}
@media screen and (max-width: 767px) {
  .expertise-wrapper {
    display: block;
  }
}
@media screen and (max-width: 479px) {
  .expertise-wrapper {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.expertise-wrapper-header {
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.expertise-wrapper-header h2 {
  letter-spacing: -0.047em;
  margin-bottom: 0;
}
.expertise-wrapper-info {
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.expertise .masonry > div > a {
  height: 0;
  padding-top: 50%;
}
.expertise .services-wrapper-right-item:hover .services-wrapper-right-item-header {
  color: #fff;
}
.expertise .services-wrapper-right-item:hover .services-wrapper-right-item-header::after, .expertise .services-wrapper-right-item:hover .services-wrapper-right-item-header::before {
  opacity: 1;
}
.expertise .services-wrapper-right-item .services-wrapper-right-item-header {
  max-width: none;
  position: absolute;
  z-index: 5;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}
.expertise .services-wrapper-right-item .services-wrapper-right-item-header h5 {
  z-index: 5;
  margin-bottom: 0;
}

.areas {
  background-color: #363636;
}

.case {
  padding-top: 0;
  padding-bottom: 0;
}
.case-wrapper {
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
  background-color: #F3F4F7;
}
.case-wrapper::before {
  content: "";
  background-image: url(../../images/vertical-marx.svg);
  opacity: 0.02;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 25%;
  bottom: 50%;
  top: 0;
  left: 0;
}
.case-list {
  position: relative;
  z-index: 5;
  max-width: 1055px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.case-list-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  transition: all 0.3s ease-in-out;
}
.case-list-container:after {
  content: "";
  position: absolute;
  width: 2px;
  top: 20%;
  bottom: 20%;
  right: 0;
  background-color: #B2B384;
  transform: translateX(50%);
}
.case-list-container:last-child:after {
  content: "";
  background-color: transparent;
}
.case-list-container a {
  transition: none !important;
}
.case-list-container.case-list-active-link {
  background-color: #B2B384;
  color: #fff;
}
.case-list-container:hover {
  background-color: #B2B384;
  color: #fff;
}
.case-new {
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
}
.case-new .flex-wrapper {
  display: flex;
}
@media screen and (max-width: 990px) {
  .case-new .flex-wrapper {
    display: block;
  }
}
.case-new-dual {
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  padding-top: 2rem;
  margin-bottom: 2rem;
}
@media screen and (max-width: 767px) {
  .case-new-dual {
    display: block;
  }
}
.case-new-dual-link {
  display: flex;
  flex-direction: column;
  max-width: 570px;
  flex-grow: 1;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}
.case-new-dual-link-image {
  position: relative;
  display: flex;
}
.case-new-dual-link-image img {
  width: 100%;
}
.case-new-dual-link-btn {
  position: absolute;
  right: 0;
  bottom: 0;
}
.case-new-dual-link-btn:hover {
  border: 1px solid #363636;
  background-color: #363636;
  color: #fff;
}
.case-new-dual-link-btn:hover::after {
  color: #fff;
}
.case-new-dual-link-date {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #60654E;
  color: #fff;
  font-size: 0.75rem;
  font-style: italic;
  padding: 0.4rem 2rem;
}
.case-new-dual-link-tag {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  column-gap: 1rem;
  color: #fff;
  font-size: 0.75rem;
  font-style: italic;
  display: flex;
  column-gap: 1rem;
}
.case-new-dual-link-tag-block {
  background-color: #282a2d;
  padding: 0.4rem 2rem;
}
@media screen and (max-width: 479px) {
  .case-new-dual-link-tag-block {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.case-new-banner {
  margin-right: 1rem;
  text-align: center;
}
@media screen and (max-width: 990px) {
  .case-new-banner {
    margin-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .case-new-banner {
    max-width: 570px;
    margin: auto;
  }
}
.case-new-banner-item {
  color: #fff;
  background-color: #60654E;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  max-width: 141px;
  height: 628px;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.case-new-banner-item h1 {
  margin-bottom: 0;
}
@media screen and (max-width: 1249px) {
  .case-new-banner-item {
    padding: 0 1.5rem;
  }
}
@media screen and (max-width: 990px) {
  .case-new-banner-item {
    writing-mode: horizontal-tb;
    transform: rotate(0deg);
    height: auto;
    max-width: 100%;
    padding: 1rem;
  }
}

.advantage {
  position: relative;
  background-image: url(../../images/background-advantage.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 11rem;
  padding-bottom: 11rem;
}
.advantage::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(54, 54, 54, 0.91);
}
.advantage.advantage-olive::after {
  content: "";
  background-color: rgba(96, 101, 78, 0.91);
}
@media screen and (max-width: 990px) {
  .advantage {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}
.advantage-wrapper {
  position: relative;
  z-index: 5;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
}
@media screen and (max-width: 990px) {
  .advantage-wrapper {
    display: block;
  }
}
.advantage-wrapper .btn-large {
  width: 443px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.65rem;
}
.advantage-wrapper .btn-large::after {
  font-size: 1.5rem;
}
@media screen and (max-width: 1249px) {
  .advantage-wrapper .btn-large {
    width: 350px;
    height: 80px;
    font-size: 1.25rem;
  }
  .advantage-wrapper .btn-large::after {
    font-size: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .advantage-wrapper .btn-large {
    width: auto;
    max-width: 350px;
    height: 70px;
  }
}
.advantage-wrapper-info p {
  font-size: 1.4rem;
  letter-spacing: 0.02em;
  margin-bottom: 0;
}
.advantage-wrapper-info p:first-child {
  font-size: 2.95rem;
  letter-spacing: -0.047em;
}
@media screen and (max-width: 990px) {
  .advantage-wrapper-info {
    margin-bottom: 2rem;
  }
  .advantage-wrapper-info p {
    font-size: 1rem;
  }
  .advantage-wrapper-info p:first-child {
    font-size: 2.25rem;
  }
}

.thoughts {
  position: relative;
  background-color: #F3F4F7;
}
.thoughts::before {
  content: "";
  background-image: url(../../images/vertical-marx.svg);
  opacity: 0.02;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 25%;
  bottom: 50%;
  top: 0;
  left: 0;
}
.thoughts-link-image {
  position: relative;
}
.thoughts-link-image::before {
  content: "";
  background: rgba(96, 101, 78, 0.72);
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
}
.thoughts-link-image::after {
  content: "\e904";
  font-family: "icomoon" !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 73px;
  height: 73px;
  background-color: #ABB582;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  color: #fff;
  font-size: 1.5rem;
  transition: all 0.3s ease-in-out;
}
.thoughts-link-image img {
  max-height: 17.5rem;
  object-fit: cover;
}
.thoughts-link-info {
  transition: all 0.3s ease-in-out;
}
.thoughts-link:hover .thoughts-link-image {
  opacity: 1 !important;
}
.thoughts-link:hover .thoughts-link-image::before, .thoughts-link:hover .thoughts-link-image::after {
  opacity: 1 !important;
}
.thoughts-link:hover .thoughts-link-info {
  background-color: #363636;
}
.thoughts-link:hover .thoughts-link-info-text p,
.thoughts-link:hover .thoughts-link-info-text h5, .thoughts-link:hover .thoughts-link-info-header p,
.thoughts-link:hover .thoughts-link-info-header h5 {
  color: #fff;
}
.thoughts-link:hover .thoughts-link-info-date {
  color: #B2B384;
}

.thoughts-inside {
  position: relative;
  background-color: #F3F4F7;
  padding-top: 14rem;
}
.thoughts-inside::before {
  content: "";
  background-image: url(../../images/vertical-marx.svg);
  opacity: 0.02;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 25%;
  bottom: 20%;
  top: 0;
  left: 0;
}
.thoughts-inside-back-link {
  position: relative;
  font-size: 0.7rem;
  color: rgba(54, 54, 54, 0.5);
}
.thoughts-inside-back-link::before {
  content: "\e901";
  color: rgba(54, 54, 54, 0.5);
  padding-right: 0.4rem;
  vertical-align: middle;
  display: inline-block;
  font-family: "icomoon" !important;
  font-size: 0.5rem;
  transition: all 0.3s ease-in-out;
}
.thoughts-inside-wrapper {
  position: relative;
  z-index: 5;
  max-width: 1400px;
  margin: auto;
}
.thoughts-inside-flexbox {
  display: flex;
  justify-content: center;
  column-gap: 1rem;
  margin-bottom: 2rem;
}
@media screen and (max-width: 990px) {
  .thoughts-inside-flexbox {
    display: block;
  }
}
.thoughts-inside-flexbox-transform {
  flex-grow: 1;
  transform: translateX(-5%);
}
@media screen and (max-width: 990px) {
  .thoughts-inside-flexbox-transform {
    transform: translateX(0);
  }
}
.thoughts-inside-flexbox-col1 {
  flex-basis: 58%;
  display: flex;
}
.thoughts-inside-flexbox-col1 img {
  flex-grow: 1;
  width: 100%;
  object-fit: cover;
}
.thoughts-inside-flexbox-col2 {
  flex-basis: 42%;
  padding: 3rem 3rem 2rem 1rem;
}
@media screen and (max-width: 990px) {
  .thoughts-inside-flexbox-col2 {
    padding: 3rem 1rem 0 1rem;
  }
}
.thoughts-inside-flexbox-col2-date {
  background-color: #60654E;
  color: #fff;
  font-size: 0.75rem;
  font-style: italic;
  max-width: fit-content;
  padding: 0.2rem 1.7rem;
  margin-bottom: 2rem;
}
.thoughts-inside-flexbox-col2-date p {
  margin-bottom: 0;
}
.thoughts-inside-flexbox-col2-header {
  font-size: 1.75rem;
  color: #60654E;
  line-height: 1;
  letter-spacing: 0em;
}
@media screen and (min-width: 991px) {
  .thoughts-inside-flexbox .media-wrapper {
    padding-bottom: 32%;
  }
}
.thoughts-inside-container {
  padding: 1rem;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.thoughts-inside-share {
  font-size: 1.5rem;
  color: #60654E;
  letter-spacing: 0em;
}
.thoughts-inside-share p {
  margin-bottom: 0;
}
@media screen and (max-width: 990px) {
  .thoughts-inside-share {
    margin-bottom: 2rem;
  }
}
.thoughts-inside-social {
  display: flex;
  column-gap: 1rem;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 990px) {
  .thoughts-inside-social {
    justify-content: flex-start;
  }
}
.thoughts-inside-social-icon {
  background-color: #60654E;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.25rem;
  height: 59px;
  width: 59px;
}
.thoughts-inside-social-icon:hover {
  filter: brightness(120%);
}
@media screen and (max-width: 990px) {
  .thoughts-inside-social-icon {
    height: 50px;
    width: 50px;
  }
}
@media screen and (max-width: 479px) {
  .thoughts-inside-social-icon {
    height: 43px;
    width: 43px;
  }
}
.thoughts-inside-arrows {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
}
@media screen and (max-width: 767px) {
  .thoughts-inside-arrows {
    align-items: flex-start;
  }
}
.thoughts-inside-arrows-wrapper {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  max-width: 550px;
  text-align: start;
}
@media screen and (max-width: 767px) {
  .thoughts-inside-arrows-wrapper {
    flex-wrap: wrap;
  }
  .thoughts-inside-arrows-wrapper:last-child {
    justify-content: flex-end;
  }
}
.thoughts-inside-arrows-wrapper p {
  flex-basis: 83%;
  margin-bottom: 0;
  font-size: 1.2rem;
  color: #282a2d;
  letter-spacing: 0em;
}
@media screen and (max-width: 767px) {
  .thoughts-inside-arrows-wrapper p {
    order: 1;
  }
}
.thoughts-inside-arrows-wrapper:last-child {
  text-align: end;
}
.thoughts-inside-arrows-wrapper .olive-prev-arrow {
  background-color: #60654E;
  width: 75px;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.thoughts-inside-arrows-wrapper .olive-prev-arrow::before {
  content: "\e901";
  font-family: "icomoon" !important;
  color: #fff;
  font-size: 1rem;
}
.thoughts-inside-arrows-wrapper .olive-prev-arrow:hover {
  filter: brightness(120%);
}
@media screen and (max-width: 767px) {
  .thoughts-inside-arrows-wrapper .olive-prev-arrow {
    width: 65px;
    height: 63px;
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 479px) {
  .thoughts-inside-arrows-wrapper .olive-prev-arrow {
    width: 60px;
    height: 58px;
  }
}
.thoughts-inside-arrows-wrapper .olive-next-arrow {
  background-color: #60654E;
  width: 75px;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.thoughts-inside-arrows-wrapper .olive-next-arrow::before {
  content: "\e902";
  font-family: "icomoon" !important;
  color: #fff;
  font-size: 1rem;
}
.thoughts-inside-arrows-wrapper .olive-next-arrow:hover {
  filter: brightness(120%);
}
@media screen and (max-width: 767px) {
  .thoughts-inside-arrows-wrapper .olive-next-arrow {
    width: 65px;
    height: 63px;
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 479px) {
  .thoughts-inside-arrows-wrapper .olive-next-arrow {
    width: 60px;
    height: 58px;
  }
}

.allnews {
  background-color: #F3F4F7;
  position: relative;
}
.allnews::after {
  content: "";
  background-image: url(../../images/background-news.jpg);
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.allnews-wrapper {
  position: relative;
  z-index: 5;
}

.contact {
  padding-top: 0;
  padding-bottom: 0;
}
.contact.talk .contact-wrapper {
  align-items: flex-start;
  background-color: #F3F4F7;
}
.contact.talk .contact-wrapper-leftside-phone h3 {
  margin-top: 2rem;
}
.contact.talk .contact-wrapper .contact-wrapper-rightside-container {
  padding-left: 1rem;
  max-width: 980px;
}
.contact.talk .contact-wrapper .contact-wrapper-rightside-container .contact-form-row-item {
  max-width: 100%;
}
.contact.talk .contact-wrapper .contact-wrapper-rightside-container .contact-form-row-text {
  flex-basis: 12%;
}
.contact-wrapper {
  display: flex;
}
@media screen and (max-width: 990px) {
  .contact-wrapper {
    display: block;
  }
}
.contact-wrapper-leftside {
  flex-basis: 33%;
  background-color: #60654E;
  color: #fff;
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact-wrapper-leftside p {
  margin-bottom: 0;
}
.contact-wrapper-leftside h2 {
  margin-bottom: 0.5rem;
  letter-spacing: -0.047em;
}
.contact-wrapper-leftside-phone {
  margin-bottom: 3rem;
}
.contact-wrapper-leftside-phone p {
  font-size: 2.15rem;
}
.contact-wrapper-leftside-adress {
  margin-bottom: 2rem;
}
.contact-wrapper-rightside {
  position: relative;
  flex-basis: 67%;
}
.contact-wrapper-rightside-social {
  position: absolute;
  left: 0;
  top: 20%;
  width: 34px;
  height: 207px;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  z-index: 5;
}
.contact-wrapper-rightside-social-icon {
  background-color: #B2B384;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.7rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
@media screen and (max-width: 767px) {
  .contact-wrapper-rightside-social-icon {
    width: 34px;
    height: 34px;
  }
}
.contact-wrapper-rightside-social-icon:hover {
  filter: brightness(120%);
}
@media screen and (max-width: 767px) {
  .contact-wrapper-rightside-social {
    top: 0;
    right: 10%;
    left: auto;
    height: 34px;
    width: 207px;
    flex-direction: row;
    column-gap: 0.5rem;
  }
}
.contact-wrapper-rightside-container {
  padding: 2rem 1rem 1rem 2.5rem;
  max-width: 935px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 767px) {
  .contact-wrapper-rightside-container {
    padding-left: 1rem;
  }
}
@media screen and (max-width: 479px) {
  .contact-wrapper-rightside-container {
    padding-top: 3rem;
  }
}
.contact-wrapper-rightside-container .contact-form-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.5rem;
  margin-bottom: 1.5rem;
}
.contact-wrapper-rightside-container .contact-form-row:last-child {
  justify-content: flex-end;
}
@media screen and (max-width: 767px) {
  .contact-wrapper-rightside-container .contact-form-row {
    display: block;
  }
}
.contact-wrapper-rightside-container .contact-form-row-text {
  flex-basis: 15%;
  text-align: end;
}
.contact-wrapper-rightside-container .contact-form-row-text p {
  font-size: 1.1rem;
  font-style: italic;
  color: #60654E;
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .contact-wrapper-rightside-container .contact-form-row-text p {
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
}
.contact-wrapper-rightside-container .contact-form-row-item {
  flex-grow: 1;
  max-width: 725px;
}
.contact-wrapper-rightside-container .contact-form-row-item .selector-wrapper {
  position: relative;
  flex-basis: 42%;
}
.contact-wrapper-rightside-container .contact-form-row-item .selector-wrapper:after {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  color: #fff;
  font-weight: 600;
  position: absolute;
  height: 3rem;
  width: 58px;
  right: 3px;
  top: 3px;
  background-color: #B2B384;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  pointer-events: none;
}
@media screen and (max-width: 990px) {
  .contact-wrapper-rightside-container .contact-form-row-item .selector-wrapper:after {
    width: 43px;
  }
}

.team-inside {
  padding-top: 0;
  padding-bottom: 0;
}
.team-inside-wrapper {
  background-color: #282a2d;
  padding: 4rem 2rem;
  display: flex;
  column-gap: 1rem;
  justify-content: space-between;
  align-items: flex-start;
}
@media screen and (max-width: 990px) {
  .team-inside-wrapper {
    display: block;
  }
}
.team-inside-wrapper-leftside {
  flex-basis: 18%;
  margin-bottom: 2rem;
}
.team-inside-wrapper-leftside p {
  color: #939393;
  font-size: 0.85rem;
  margin-bottom: 2rem;
}
@media screen and (max-width: 1249px) {
  .team-inside-wrapper-leftside .btn {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media screen and (max-width: 1249px) {
  .team-inside-wrapper-leftside .btn-large {
    min-width: 245px !important;
  }
}
.team-inside-wrapper-rightside {
  flex-basis: 80%;
}
.team-inside-card-image {
  display: flex;
}
.team-inside-card-image img {
  width: 100%;
  height: 100%;
}
.team-inside-card-info {
  background-color: #FEFEFE;
  padding: 1rem;
  flex-grow: 1;
}
.team-inside-card-info p {
  margin-bottom: 0;
}
.team-inside-card-info-text {
  font-size: 1.25rem;
  color: #282a2d;
  letter-spacing: 0.025em;
}
.team-inside-card-info-sign {
  font-size: 0.85rem;
  color: #939393;
}

.internship-back-link {
  position: relative;
  font-size: 0.7rem;
  color: rgba(54, 54, 54, 0.5);
}
.internship-back-link::before {
  color: #363636;
  content: "\e901";
  padding-right: 0.4rem;
  vertical-align: middle;
  display: inline-block;
  font-family: "icomoon" !important;
  font-size: 0.5rem;
  transition: all 0.3s ease-in-out;
}
.internship .form-wrapper {
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
}
.internship .form-wrapper-header h2 {
  letter-spacing: -0.047em;
  color: #282a2d;
  text-align: center;
}
.internship-form-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1rem;
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 767px) {
  .internship-form-row {
    display: block;
  }
}
.internship-form-row-text {
  flex-basis: 17%;
  text-align: end;
}
.internship-form-row-text p {
  font-size: 1.1rem;
  font-style: italic;
  color: #60654E;
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .internship-form-row-text p {
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
}
.internship-form-row-item {
  flex-grow: 1;
  max-width: 870px;
}
.internship-form-row-item .selector-wrapper {
  position: relative;
  flex-basis: 42%;
}
.internship-form-row-item .selector-wrapper:after {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  color: #fff;
  font-weight: 600;
  position: absolute;
  height: 54px;
  width: 58px;
  right: 3px;
  top: 3px;
  background-color: #B2B384;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  pointer-events: none;
}
@media screen and (max-width: 990px) {
  .internship-form-row-item .selector-wrapper:after {
    height: 41px;
    width: 43px;
  }
}
.internship-form-upload {
  position: relative;
  cursor: pointer;
  display: inline-block;
}
.internship-form-upload.has-danger {
  border-bottom: 1px solid #f00 !important;
}
.internship-form-upload-invis {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  font-size: 0.0005rem;
}

.job {
  background-color: rgba(128, 128, 0, 0.15);
}
.job-vacancy {
  max-width: 900px;
  margin-bottom: 4rem;
}
.job-vacancy.inside {
  max-width: none;
}
.job-vacancy h2 {
  letter-spacing: -0.047em;
  color: #282a2d;
  margin-bottom: 0;
}
.job-vacancy h5 {
  letter-spacing: 0.16em;
  margin-bottom: 1.5rem;
  color: #60654E;
}

.examples {
  background-color: #363636;
}
.examples .hide-card {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.examples-flexbox {
  display: flex;
  column-gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
}
.examples-flexbox-item {
  position: relative;
  width: 230px;
  background-color: #6F6F6F;
  color: #fff;
  font-size: 0.7rem;
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
  padding-left: 1rem;
  padding-right: 2.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
}
.examples-flexbox-item:before {
  content: "\f068";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  width: 37px;
  height: 37px;
  background-color: #60654E;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  color: #fff;
}
@media screen and (max-width: 990px) {
  .examples-flexbox-item:before {
    width: 30px;
    height: 30px;
  }
}
.examples-flexbox-item.active {
  background-color: #B2B384;
  color: #363636;
  transition: all 0.3s ease-in-out;
}
.examples-flexbox-item.active:before {
  content: "\f067";
}
.examples-flexbox-item p {
  margin-bottom: 0;
  letter-spacing: 0.02em;
}

.relations {
  background-color: rgba(128, 128, 0, 0.15);
}
.relations-flexbox {
  display: flex;
  column-gap: 1rem;
  justify-content: space-between;
}
@media screen and (max-width: 990px) {
  .relations-flexbox {
    flex-direction: column;
  }
}
.relations-flexbox-col1 {
  flex-basis: 70%;
}
@media screen and (max-width: 990px) {
  .relations-flexbox-col1 {
    order: 1;
  }
}
.relations-flexbox-col1 h2 {
  letter-spacing: -0.047em;
  color: #282a2d;
}
.relations-flexbox-col1 h5 {
  letter-spacing: 0.16em;
  color: #282a2d;
}
@media screen and (max-width: 384px) {
  .relations-flexbox-col1 .btn-olive-inverted {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.relations-flexbox-col2 {
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
}
@media screen and (max-width: 990px) {
  .relations-flexbox-col2 {
    margin-bottom: 2rem;
  }
}
.relations-flexbox-col2-text {
  max-width: 400px;
  text-align: center;
}
.relations-flexbox-col2-text p {
  letter-spacing: 0.02em;
  color: #000000;
}

.hospitality {
  padding-top: 0;
  padding-bottom: 0;
}
.hospitality-wrapper {
  max-width: 1920px;
  margin: auto;
}
.hospitality-flexbox {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 990px) {
  .hospitality-flexbox {
    display: block;
  }
}
.hospitality-flexbox-col1 {
  flex-basis: 40%;
  display: flex;
}
.hospitality-flexbox-col1 img {
  flex-grow: 1;
  width: 100%;
  object-fit: cover;
}
.hospitality-flexbox-col2 {
  background-color: rgba(243, 244, 247, 0.4);
  flex-basis: 60%;
  padding: 3rem 5rem;
}
@media screen and (max-width: 1249px) {
  .hospitality-flexbox-col2 {
    padding: 2rem;
  }
}
@media screen and (max-width: 479px) {
  .hospitality-flexbox-col2 {
    padding: 2rem 1rem;
  }
}
.hospitality-flexbox-col2 h2 {
  letter-spacing: -0.047em;
  color: #282a2d;
}
.hospitality-flexbox-col2 h5 {
  letter-spacing: 0.16em;
  color: #282a2d;
}
.hospitality-flexbox-col2-container {
  max-width: 750px;
  margin: auto;
}
.hospitality-flexbox-col2-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5rem;
}
.hospitality-flexbox-col2-header h2 {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .hospitality-flexbox-col2-header {
    display: block;
    margin-bottom: 2rem;
  }
  .hospitality-flexbox-col2-header h2 {
    margin-bottom: 2rem;
  }
}

.case-strategy {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 3rem;
}
.case-strategy-wrapper {
  max-width: 1920px;
  margin: auto;
}
.case-strategy-flexbox {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 990px) {
  .case-strategy-flexbox {
    display: block;
  }
}
.case-strategy-flexbox-col1 {
  flex-basis: 40%;
  display: flex;
}
.case-strategy-flexbox-col1 img {
  flex-grow: 1;
  width: 100%;
  object-fit: cover;
}
.case-strategy-flexbox-col2 {
  background-color: rgba(243, 244, 247, 0.4);
  flex-basis: 60%;
  padding: 3rem 5rem;
}
@media screen and (max-width: 1249px) {
  .case-strategy-flexbox-col2 {
    padding: 2rem;
  }
}
@media screen and (max-width: 479px) {
  .case-strategy-flexbox-col2 {
    padding: 2rem 1rem;
  }
}
.case-strategy-flexbox-col2 h2 {
  letter-spacing: -0.047em;
  color: #282a2d;
}
.case-strategy-flexbox-col2 h5 {
  letter-spacing: 0.16em;
  color: #282a2d;
}
.case-strategy-flexbox-col2-container {
  max-width: 750px;
  margin: auto;
}
.case-strategy-flexbox-col2-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5rem;
}
.case-strategy-flexbox-col2-header h2 {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .case-strategy-flexbox-col2-header {
    display: block;
    margin-bottom: 2rem;
  }
  .case-strategy-flexbox-col2-header h2 {
    margin-bottom: 2rem;
  }
}

.decades {
  padding-top: 0;
  padding-bottom: 0;
}
.decades-flexbox {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 990px) {
  .decades-flexbox {
    display: block;
  }
}
.decades-flexbox-col1 {
  flex-basis: 60%;
  background-color: #363636;
  padding: 3rem 2rem 3rem 5rem;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .decades-flexbox-col1 {
    padding-left: 2rem;
  }
}
@media screen and (max-width: 479px) {
  .decades-flexbox-col1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.decades-flexbox-col2 {
  background-color: #60654E;
  flex-basis: 40%;
  padding: 3rem 1rem;
}

.digital {
  padding-top: 0;
  padding-bottom: 0;
}
.digital-flexbox {
  display: flex;
  justify-content: space-between;
  background-color: rgba(232, 232, 232, 0.55);
}
@media screen and (max-width: 990px) {
  .digital-flexbox {
    display: block;
  }
}
.digital-flexbox-col1 {
  flex-basis: 60%;
}
.digital-flexbox-col1-paddings {
  padding: 3rem 2rem 3rem 5rem;
}
.digital-flexbox-col1-paddings ul {
  margin-bottom: 0;
}
.digital-flexbox-col1-paddings ul li {
  list-style-type: none;
  margin-left: 0;
}
@media screen and (max-width: 1249px) {
  .digital-flexbox-col1-paddings {
    padding: 2rem;
  }
}
@media screen and (max-width: 479px) {
  .digital-flexbox-col1-paddings {
    padding: 2rem 1rem;
  }
}
.digital-flexbox-col1-paddings h2 {
  letter-spacing: -0.047em;
}
.digital-flexbox-col1-paddings h5 {
  letter-spacing: 0.16em;
}
.digital-flexbox-col2 {
  flex-basis: 40%;
  display: flex;
}
.digital-flexbox-col2 img {
  flex-grow: 1;
  object-fit: cover;
}

.insight {
  background-color: #363636;
}
.insight-back-link {
  position: relative;
  font-size: 0.7rem;
  color: rgba(243, 244, 247, 0.5);
}
.insight-back-link::before {
  content: "\e901";
  color: rgba(243, 244, 247, 0.5);
  padding-right: 0.4rem;
  vertical-align: middle;
  display: inline-block;
  font-family: "icomoon" !important;
  font-size: 0.5rem;
  transition: all 0.3s ease-in-out;
}
.insight-block {
  color: #fff;
  margin-bottom: 4rem;
}
.insight-block p {
  margin-left: 1rem;
}
.insight-block h2 {
  letter-spacing: -0.047em;
  color: #FBFFD1;
}
.insight-block h5 {
  letter-spacing: 0.16em;
  color: #FBFFD1;
}

.case-gallery {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #363636;
}
.case-gallery-block {
  color: #fff;
  position: relative;
  padding: 3rem 1rem;
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  max-width: 1650px;
  margin-left: auto;
  margin-right: auto;
}
.case-gallery-block h5 {
  margin-bottom: 0;
}
.case-gallery-block h5:last-child {
  text-align: end;
}
@media screen and (max-width: 767px) {
  .case-gallery-block h5 {
    margin-bottom: 1rem;
  }
}
.case-gallery-block a {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}
@media screen and (max-width: 767px) {
  .case-gallery-block a {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}
@media screen and (max-width: 767px) {
  .case-gallery-block a:last-child {
    flex-direction: column;
    align-items: flex-end;
  }
}
.case-gallery-block a .olive-prev-arrow {
  background-color: #60654E;
  width: 75px;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.case-gallery-block a .olive-prev-arrow::before {
  content: "\e901";
  font-family: "icomoon" !important;
  color: #fff;
  font-size: 1rem;
}
.case-gallery-block a .olive-prev-arrow:hover {
  filter: brightness(120%);
}
@media screen and (max-width: 767px) {
  .case-gallery-block a .olive-prev-arrow {
    width: 65px;
    height: 63px;
  }
}
@media screen and (max-width: 479px) {
  .case-gallery-block a .olive-prev-arrow {
    width: 60px;
    height: 58px;
  }
}
.case-gallery-block a .olive-next-arrow {
  background-color: #60654E;
  width: 75px;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.case-gallery-block a .olive-next-arrow::before {
  content: "\e902";
  font-family: "icomoon" !important;
  color: #fff;
  font-size: 1rem;
}
.case-gallery-block a .olive-next-arrow:hover {
  filter: brightness(120%);
}
@media screen and (max-width: 767px) {
  .case-gallery-block a .olive-next-arrow {
    width: 65px;
    height: 63px;
  }
}
@media screen and (max-width: 479px) {
  .case-gallery-block a .olive-next-arrow {
    width: 60px;
    height: 58px;
  }
}

.wysiwyg h2 {
  margin-bottom: 0;
}
.wysiwyg ul li {
  position: relative;
  list-style-type: none;
  margin-left: 2rem;
  margin-bottom: 0.3rem;
}
.wysiwyg ul li::before {
  content: "";
  position: absolute;
  width: 1.3rem;
  height: 0.15rem;
  left: -2rem;
  top: 15px;
  transform: translateY(-50%);
  background-color: #B2B384;
}
@media screen and (max-width: 990px) {
  .wysiwyg ul li::before {
    top: 11px;
  }
}

.wysiwyg-news h5 {
  font-size: 1.15rem;
  color: #9fa87d;
  letter-spacing: 0.02em;
}

.slider-wrapper {
  position: relative !important;
}
.slider-wrapper .slick-track,
.slider-wrapper .slick-list {
  -webkit-perspective: 2000;
  -webkit-backface-visibility: hidden;
}
.slider-wrapper.init {
  min-height: 200px;
  max-height: 30vh;
}
.slider-wrapper.init .slick-slider {
  opacity: 0;
}
.slider-wrapper .preloader-overlay {
  position: absolute;
  background: transparent;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 5;
}
.slider-wrapper .preloader-overlay .preloader {
  position: absolute !important;
  top: 50%;
  left: 50%;
  width: 6rem;
  height: 6rem;
  margin-top: -3rem;
  margin-left: -3rem;
  background-image: url(../../images/fw-slider/new-loading.gif);
  background-repeat: no-repeat;
  background-size: cover;
}
.slider-wrapper .prev-arrow,
.slider-wrapper .next-arrow {
  position: absolute;
  text-decoration: none;
  transition: 300ms ease-in-out;
  z-index: 5;
}
.slider-wrapper .olive-prev-arrow {
  right: 75px;
  background-color: #60654E;
  width: 75px;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider-wrapper .olive-prev-arrow.icon-arrow-left:before {
  content: "\e901";
  font-family: "icomoon" !important;
  color: #fff;
  font-size: 1rem;
}
.slider-wrapper .olive-prev-arrow:hover {
  filter: brightness(120%);
}
.slider-wrapper .olive-prev-arrow::after {
  content: "";
  position: absolute;
  right: 0;
  top: 14%;
  bottom: 14%;
  width: 1px;
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  .slider-wrapper .olive-prev-arrow {
    width: 65px;
    height: 63px;
    right: 65px;
  }
}
@media screen and (max-width: 479px) {
  .slider-wrapper .olive-prev-arrow {
    width: 60px;
    height: 58px;
    right: 60px;
  }
}
.slider-wrapper .olive-next-arrow {
  background-color: #60654E;
  width: 75px;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
}
.slider-wrapper .olive-next-arrow.icon-arrow-right:before {
  content: "\e902";
  font-family: "icomoon" !important;
  color: #fff;
  font-size: 1rem;
}
.slider-wrapper .olive-next-arrow:hover {
  filter: brightness(120%);
}
@media screen and (max-width: 767px) {
  .slider-wrapper .olive-next-arrow {
    width: 65px;
    height: 63px;
  }
}
@media screen and (max-width: 479px) {
  .slider-wrapper .olive-next-arrow {
    width: 60px;
    height: 58px;
  }
}
.slider-wrapper .white-prev-arrow {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  width: 75px;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider-wrapper .white-prev-arrow.icon-arrow-left:before {
  content: "\e901";
  font-family: "icomoon" !important;
  color: #282a2d;
  font-size: 1rem;
}
.slider-wrapper .white-prev-arrow:hover {
  filter: brightness(120%);
}
@media screen and (max-width: 990px) {
  .slider-wrapper .white-prev-arrow {
    width: 70px;
    height: 68px;
  }
}
@media screen and (max-width: 767px) {
  .slider-wrapper .white-prev-arrow {
    width: 65px;
    height: 63px;
  }
}
@media screen and (max-width: 479px) {
  .slider-wrapper .white-prev-arrow {
    width: 50px;
    height: 48px;
  }
}
.slider-wrapper .white-next-arrow {
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  width: 75px;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider-wrapper .white-next-arrow.icon-arrow-right:before {
  content: "\e902";
  font-family: "icomoon" !important;
  color: #282a2d;
  font-size: 1rem;
}
.slider-wrapper .white-next-arrow:hover {
  filter: brightness(120%);
}
@media screen and (max-width: 990px) {
  .slider-wrapper .white-next-arrow {
    width: 70px;
    height: 68px;
  }
}
@media screen and (max-width: 767px) {
  .slider-wrapper .white-next-arrow {
    width: 65px;
    height: 63px;
  }
}
@media screen and (max-width: 479px) {
  .slider-wrapper .white-next-arrow {
    width: 50px;
    height: 48px;
  }
}
.slider-wrapper .transp-prev-arrow {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 30px;
}
.slider-wrapper .transp-prev-arrow.icon-arrow-left:before {
  content: "\e901";
  font-family: "icomoon" !important;
  color: #CFCFCF;
  font-size: 1.25rem;
}
.slider-wrapper .transp-prev-arrow:hover {
  filter: brightness(120%);
}
.slider-wrapper .transp-next-arrow {
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 30px;
}
.slider-wrapper .transp-next-arrow.icon-arrow-right:before {
  content: "\e902";
  font-family: "icomoon" !important;
  color: #CFCFCF;
  font-size: 1.25rem;
}
.slider-wrapper .transp-next-arrow:hover {
  filter: brightness(120%);
}
.slider-wrapper .white-top-arrow {
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  width: 75px;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider-wrapper .white-top-arrow.icon-arrow-left:before {
  content: "\e903";
  font-family: "icomoon" !important;
  color: #cfcfcf;
  font-size: 1rem;
}
.slider-wrapper .white-top-arrow::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 14%;
  right: 14%;
  height: 1px;
  background-color: #cfcfcf;
}
.slider-wrapper .white-top-arrow:hover {
  filter: brightness(120%);
}
@media screen and (max-width: 990px) {
  .slider-wrapper .white-top-arrow {
    width: 70px;
    height: 68px;
  }
}
@media screen and (max-width: 767px) {
  .slider-wrapper .white-top-arrow {
    width: 65px;
    height: 63px;
    right: 65px;
  }
}
@media screen and (max-width: 479px) {
  .slider-wrapper .white-top-arrow {
    width: 50px;
    height: 48px;
    right: 50px;
  }
}
.slider-wrapper .white-bot-arrow {
  top: calc(50% + 72px);
  transform: translateY(-50%);
  background-color: #fff;
  width: 75px;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 990px) {
  .slider-wrapper .white-bot-arrow {
    top: calc(50% + 68px);
  }
}
@media screen and (max-width: 767px) {
  .slider-wrapper .white-bot-arrow {
    top: calc(50% + 62px);
  }
}
@media screen and (max-width: 479px) {
  .slider-wrapper .white-bot-arrow {
    top: calc(50% + 48px);
  }
}
.slider-wrapper .white-bot-arrow.icon-arrow-right:before {
  content: "\e900";
  font-family: "icomoon" !important;
  color: #cfcfcf;
  font-size: 1rem;
}
.slider-wrapper .white-bot-arrow:hover {
  filter: brightness(120%);
}
@media screen and (max-width: 990px) {
  .slider-wrapper .white-bot-arrow {
    width: 70px;
    height: 68px;
  }
}
@media screen and (max-width: 767px) {
  .slider-wrapper .white-bot-arrow {
    width: 65px;
    height: 63px;
  }
}
@media screen and (max-width: 479px) {
  .slider-wrapper .white-bot-arrow {
    width: 50px;
    height: 48px;
  }
}
.slider-wrapper .olive-top-arrow {
  top: 50%;
  transform: translateY(-50%);
  background-color: #60654E;
  width: 75px;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider-wrapper .olive-top-arrow.icon-arrow-left:before {
  content: "\e903";
  font-family: "icomoon" !important;
  color: #fff;
  font-size: 1rem;
}
.slider-wrapper .olive-top-arrow::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 14%;
  right: 14%;
  height: 1px;
  background-color: #fff;
}
.slider-wrapper .olive-top-arrow:hover {
  filter: brightness(120%);
}
@media screen and (max-width: 990px) {
  .slider-wrapper .olive-top-arrow {
    width: 70px;
    height: 68px;
  }
}
@media screen and (max-width: 767px) {
  .slider-wrapper .olive-top-arrow {
    width: 65px;
    height: 63px;
  }
}
@media screen and (max-width: 479px) {
  .slider-wrapper .olive-top-arrow {
    width: 50px;
    height: 48px;
  }
}
.slider-wrapper .olive-bot-arrow {
  top: calc(50% + 72px);
  transform: translateY(-50%);
  background-color: #60654E;
  width: 75px;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 990px) {
  .slider-wrapper .olive-bot-arrow {
    top: calc(50% + 68px);
  }
}
@media screen and (max-width: 767px) {
  .slider-wrapper .olive-bot-arrow {
    top: calc(50% + 62px);
  }
}
@media screen and (max-width: 479px) {
  .slider-wrapper .olive-bot-arrow {
    top: calc(50% + 48px);
  }
}
.slider-wrapper .olive-bot-arrow.icon-arrow-right:before {
  content: "\e900";
  font-family: "icomoon" !important;
  color: #fff;
  font-size: 1rem;
}
.slider-wrapper .olive-bot-arrow:hover {
  filter: brightness(120%);
}
@media screen and (max-width: 990px) {
  .slider-wrapper .olive-bot-arrow {
    width: 70px;
    height: 68px;
  }
}
@media screen and (max-width: 767px) {
  .slider-wrapper .olive-bot-arrow {
    width: 65px;
    height: 63px;
  }
}
@media screen and (max-width: 479px) {
  .slider-wrapper .olive-bot-arrow {
    width: 50px;
    height: 48px;
  }
}

.main-slider .slider-wrapper:after {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  bottom: 60%;
  left: 0;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.92) 0, rgba(255, 255, 255, 0.92) 20%, transparent 100%);
}

.fw-slider {
  overflow: hidden;
}
.fw-slider .prev-arrow,
.fw-slider .next-arrow {
  bottom: 96px;
}
@media screen and (max-width: 990px) {
  .fw-slider .prev-arrow,
  .fw-slider .next-arrow {
    bottom: 80px;
  }
}
@media screen and (max-width: 479px) {
  .fw-slider .prev-arrow,
  .fw-slider .next-arrow {
    bottom: 70px;
  }
}
.fw-slider .slick-track {
  display: flex;
}
.fw-slider .slick-track .slick-slide {
  position: relative;
  display: flex;
  height: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.fw-slider .slick-track .slick-slide img {
  object-fit: cover;
  width: 100%;
}

.second-top-slider {
  overflow: hidden;
}
.second-top-slider .prev-arrow,
.second-top-slider .next-arrow {
  bottom: 0;
}
.second-top-slider .slick-track {
  display: flex;
}
.second-top-slider .slick-track .slick-slide {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: inherit;
  background-repeat: no-repeat;
  width: 65%;
  background-position: right;
}
@media screen and (max-width: 990px) {
  .second-top-slider .slick-track .slick-slide {
    background-size: cover;
  }
}
.second-top-slider .slick-track .slick-slide:before {
  content: "";
  position: absolute;
  left: 0;
  right: 15%;
  top: 0;
  bottom: 0;
  z-index: 5;
  background: linear-gradient(to right, #f3f4f6 40%, rgba(253, 253, 253, 0));
}
.second-top-slider .slick-track .slick-slide::after {
  content: "";
  position: absolute;
  left: 30%;
  right: 30%;
  top: 0;
  bottom: 0;
  z-index: 10;
  background: linear-gradient(to right, #f3f4f6 50%, rgba(253, 253, 253, 0));
}
@media screen and (max-width: 990px) {
  .second-top-slider .slick-track .slick-slide::after {
    background: none;
  }
}
.second-top-slider .slick-track .slick-slide img {
  object-fit: cover;
  width: 100%;
}
.second-top-slider-slide-container {
  z-index: 20;
  max-width: 700px;
  margin-top: 4rem;
  margin-bottom: 4rem;
  margin-right: 1rem;
  margin-left: 300px;
}
.second-top-slider-slide-container h2,
.second-top-slider-slide-container h3,
.second-top-slider-slide-container h5 {
  color: #282a2d;
}
.second-top-slider-slide-container h2,
.second-top-slider-slide-container h3 {
  letter-spacing: -0.047em;
}
.second-top-slider-slide-container h2 {
  margin-bottom: 0.5rem;
}
.second-top-slider-slide-container h5 {
  letter-spacing: 0.16em;
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 990px) {
  .second-top-slider-slide-container {
    margin-left: 260px;
  }
}
@media screen and (max-width: 767px) {
  .second-top-slider-slide-container {
    margin: 15rem 1rem 3rem 1rem;
  }
}

.fw-slider-large .slick-slide,
.second-top-slider-large .slick-slide,
.case-slider-large .slick-slide {
  min-height: 80vh;
}

.fw-slider-middle .slick-slide,
.second-top-slider-middle .slick-slide,
.case-slider-middle .slick-slide {
  min-height: 60vh;
}

.fw-slider-small .slick-slide,
.second-top-slider-small .slick-slide,
.case-slider-small .slick-slide {
  min-height: 40vh;
}

.industries-slider.slider-container {
  position: relative;
  max-width: 1780px;
  margin: auto;
  padding-left: 56px;
  padding-right: 56px;
}
@media screen and (max-width: 767px) {
  .industries-slider.slider-container {
    padding-left: 51px;
    padding-right: 51px;
  }
}
@media screen and (max-width: 479px) {
  .industries-slider.slider-container {
    padding-left: 45px;
    padding-right: 45px;
  }
}
.industries-slider .slick-track {
  display: flex;
  justify-content: space-between;
}
.industries-slider .slick-slide {
  max-width: 370px;
  margin: 0 1rem;
}
.industries-slider-slide {
  position: relative;
  border-radius: 5px;
  display: block;
}
.industries-slider-slide::before {
  content: "";
  background: rgba(96, 101, 78, 0.72);
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
}
.industries-slider-slide::after {
  content: "\e904";
  font-family: "icomoon" !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 73px;
  height: 73px;
  background-color: #ABB582;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  color: #fff;
  font-size: 1.5rem;
  transition: all 0.3s ease-in-out;
}
.industries-slider-slide:hover {
  opacity: 1 !important;
}
.industries-slider-slide:hover::before, .industries-slider-slide:hover::after {
  opacity: 1 !important;
}
.industries-slider-slide-image::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 40%;
  background: linear-gradient(to top, #37391a, rgba(226, 244, 25, 0));
}
.industries-slider-slide-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.industries-slider-slide-info {
  background-color: #363636;
  color: #fff;
  width: 84%;
  padding: 1rem 3.5rem 1rem 1rem;
  position: absolute;
  bottom: 6%;
  left: 50%;
  transform: translateX(-50%);
}
.industries-slider-slide-info p {
  letter-spacing: 0.02em;
  margin-bottom: 0;
}
@media screen and (max-width: 1249px) {
  .industries-slider-slide-info p {
    font-size: 0.8rem;
  }
}
.industries-slider-slide-info-text p {
  font-size: 0.8rem;
}
@media screen and (max-width: 1249px) {
  .industries-slider-slide-info-text p {
    font-size: 0.7rem;
  }
}
.industries-slider-slide-info::before {
  content: "";
  position: absolute;
  left: 0;
  width: 4px;
  height: 49%;
  background-color: #B2B384;
}
.industries-slider-slide-info::after {
  content: "\e905";
  font-family: "icomoon" !important;
  position: absolute;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #60654E;
  border-radius: 5px;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 1249px) {
  .industries-slider-slide-info::after {
    width: 45px;
    height: 45px;
  }
}
@media screen and (max-width: 767px) {
  .industries-slider-slide-info::after {
    width: 40px;
    height: 40px;
  }
}

.response-slider .white-top-arrow {
  left: 0;
  top: 50%;
}
.response-slider .white-bot-arrow {
  left: 0;
}
.response-slider .slick-track {
  display: flex;
  flex-direction: column;
}
.response-slider .slick-track .slick-slide {
  display: flex;
  height: inherit;
}
.response-slider-wrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  padding-left: 80px;
  flex-grow: 1;
}
@media screen and (max-width: 990px) {
  .response-slider-wrapper {
    display: block;
    padding-left: 50px;
    margin: auto;
  }
}
@media screen and (max-width: 479px) {
  .response-slider-wrapper {
    padding-left: 35px;
  }
}
.response-slider-slide {
  background-color: #fff;
  max-width: 522px;
  padding: 1rem 1rem;
  flex-basis: 50%;
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
@media screen and (max-width: 990px) {
  .response-slider-slide {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 479px) {
  .response-slider-slide {
    display: block;
  }
}
.response-slider-slide-image {
  flex-basis: 25%;
  display: flex;
  justify-content: center;
  align-items: start;
}
.response-slider-slide-info {
  flex-basis: 75%;
  font-size: 1.05rem;
  color: #6F6F6F;
}
.response-slider-slide-info-sign {
  font-size: 0.9rem;
  color: #60654E;
}
.response-slider-slide-info-position {
  font-size: 0.9rem;
  font-style: italic;
  color: #A1A1A1;
}
.response-slider-slide p {
  letter-spacing: 0em;
  margin-bottom: 0;
}

.process-wrapper {
  transform: translateY(20%);
}

.process-slider-container {
  max-width: 1000px;
  margin: auto;
  padding-right: 80px;
}
@media screen and (max-width: 767px) {
  .process-slider-container {
    padding-right: 70px;
  }
  .process-slider-container .white-top-arrow {
    top: 40%;
  }
  .process-slider-container .white-bot-arrow {
    top: calc(40% + 62px);
  }
}
@media screen and (max-width: 479px) {
  .process-slider-container {
    padding-right: 55px;
  }
  .process-slider-container .white-bot-arrow {
    top: calc(40% + 48px);
  }
}
.process-slider-container .white-top-arrow,
.process-slider-container .white-bot-arrow {
  right: 0;
}
.process-slider-container .process-slider-slide {
  position: relative;
  padding-left: 2rem;
}
@media screen and (max-width: 767px) {
  .process-slider-container .process-slider-slide {
    padding-left: 0;
    padding-top: 2rem;
  }
}
.process-slider-container .process-slider-slide-text {
  position: absolute;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  bottom: 26%;
  left: 0px;
  font-size: 1.5rem;
  color: #282a2d;
  letter-spacing: 0.16em;
}
.process-slider-container .process-slider-slide-text p {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .process-slider-container .process-slider-slide-text {
    top: 0;
    left: 5%;
    writing-mode: horizontal-tb;
    transform: rotate(0);
  }
}
.process-slider-container .process-slider-slide-image {
  flex-grow: 1;
}

.team-slider-container {
  max-width: 85%;
}
@media screen and (max-width: 1599px) {
  .team-slider-container {
    max-width: 82%;
  }
}
@media screen and (max-width: 1399px) {
  .team-slider-container {
    max-width: 78%;
  }
}
@media screen and (max-width: 990px) {
  .team-slider-container {
    max-width: 100%;
  }
}
.team-slider-container .team-slider {
  padding-left: 2rem;
  padding-right: 2rem;
}
.team-slider-container .team-slider .slick-track {
  display: flex;
  justify-content: space-between;
}
.team-slider-container .team-slider-slide {
  max-width: 335px;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}
.team-slider-container .team-slider-slide-card-image {
  display: flex;
}
.team-slider-container .team-slider-slide-card-image img {
  width: 100%;
  height: 100%;
}
.team-slider-container .team-slider-slide-card-info {
  background-color: #FEFEFE;
  padding: 1rem;
}
.team-slider-container .team-slider-slide-card-info p {
  margin-bottom: 0;
}
.team-slider-container .team-slider-slide-card-info-text {
  font-size: 1.25rem;
  color: #282a2d;
  letter-spacing: 0.025em;
}
.team-slider-container .team-slider-slide-card-info-sign {
  font-size: 0.85rem;
  color: #939393;
}

.history-slider-container .history-slider-slide {
  padding-left: 5rem;
}
@media screen and (max-width: 990px) {
  .history-slider-container .history-slider-slide img {
    margin: auto;
  }
}
@media screen and (max-width: 479px) {
  .history-slider-container .history-slider-slide {
    padding-left: 3.5rem;
  }
}

.case-slider .olive-prev-arrow {
  left: 3rem;
  bottom: 0;
  transform: translateY(149%);
}
@media screen and (max-width: 990px) {
  .case-slider .olive-prev-arrow {
    left: 2rem;
    transform: translateY(129%);
  }
}
@media screen and (max-width: 767px) {
  .case-slider .olive-prev-arrow {
    transform: translateY(143%);
  }
}
@media screen and (max-width: 479px) {
  .case-slider .olive-prev-arrow {
    transform: translateY(167%);
    left: 1rem;
  }
}
@media screen and (max-width: 384px) {
  .case-slider .olive-prev-arrow {
    left: 0.5rem;
  }
}
.case-slider .olive-prev-arrow::after {
  content: "";
  width: 0;
}
.case-slider .olive-next-arrow {
  right: 3rem;
  bottom: 0;
  transform: translateY(149%);
}
@media screen and (max-width: 990px) {
  .case-slider .olive-next-arrow {
    right: 2rem;
    transform: translateY(129%);
  }
}
@media screen and (max-width: 767px) {
  .case-slider .olive-next-arrow {
    transform: translateY(143%);
  }
}
@media screen and (max-width: 479px) {
  .case-slider .olive-next-arrow {
    transform: translateY(167%);
    right: 1rem;
  }
}
@media screen and (max-width: 384px) {
  .case-slider .olive-next-arrow {
    right: 0.5rem;
  }
}