body {
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	main {
		flex: 1 1 auto;
	}
	&.menu-opened {
		main {
			display: none;
		}
		.header-logo-wrapper {
			left: 50%;
			transform: translateX(-45%);
		}
	}
}

header {
	margin-bottom: -3rem;
}

.header-hamburger {
	position: relative;
	z-index: 20;
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: flex;
	padding: 0;
	margin-left: auto;
	&:focus {
		outline: none;
	}
	@include bup(md) {
		display: none;
	}
	svg {
		height: 52px;
		width: auto;
	}
	.line {
		fill: none;
		stroke: black;
		stroke-width: 6;
		transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
			stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
		&1 {
			stroke-dasharray: 60 207;
			stroke-width: 6;
		}
		&2 {
			stroke-dasharray: 60 60;
			stroke-width: 6;
		}
		&3 {
			stroke-dasharray: 60 207;
			stroke-width: 6;
		}
	}
	&.opened {
		.line {
			&1 {
				stroke-dasharray: 90 207;
				stroke-dashoffset: -134;
				stroke-width: 6;
			}
			&2 {
				stroke-dasharray: 1 60;
				stroke-dashoffset: -30;
				stroke-width: 6;
			}
			&3 {
				stroke-dasharray: 90 207;
				stroke-dashoffset: -134;
				stroke-width: 6;
			}
		}
	}
}

.header-container {
	background-color: transparent;
	position: relative;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	a {
		text-decoration: none;
		border-bottom: 3px solid transparent;

		&:hover,
		&.active {
			border-bottom-color: $olive;
		}
	}

	.header-logo-wrapper {
		position: absolute;
		z-index: 10;
		left: 0;
		top: 0;
		max-width: 241px;
		background-color: $white;
		padding: 3rem 2rem 2rem 2rem;
		@include transition;
		&-image {
			margin-bottom: 0.5rem;
		}
		p {
			font-size: rem(13);
			line-height: 1.3;
			@include letter-spacing(100);
			margin-bottom: 0;
		}
		@include bdown(md) {
			margin-top: -3rem;
		}
		@include bdown(xs) {
			padding: 2rem 1rem 1rem;
		}
		&:hover {
			border-bottom-color: transparent;
		}
	}
	nav {
		position: relative;
		z-index: 10;
		flex-grow: 1;
		display: flex;
		justify-content: flex-end;
		ul {
			display: flex;
			align-items: center;
			margin: 0;

			font-size: rem(22);
			@include bdown(hg) {
				column-gap: 0.5rem;
				font-size: rem(18);
			}
			@include bdown(nm) {
				column-gap: 0.3rem;
				font-size: rem(16);
			}
			li {
				padding-right: 1.5rem;
				@include bdown(hg) {
					padding-right: 0.5rem;
				}
				@include bdown(nm) {
					padding-right: 0.3rem;
				}
				&:last-child {
					padding-right: 0;
					@include bdown(hg) {
						padding-right: 0;
					}
					@include bdown(nm) {
						padding-right: 0;
					}
					a {
						border-bottom: 1px solid $olive;
						margin-left: 3rem;
						font-size: rem(18);
						@include bdown(hg) {
							margin-left: 1rem;
						}
						@include bdown(nm) {
							margin-left: 0.5rem;
						}
					}
				}
			}
		}
		@include bdown(md) {
			flex: 1 1 100%;
			display: none;
			margin-left: calc(-15px - 2%);
			margin-right: calc(-15px - 2%);
			width: 100%;
			ul {
				margin-top: 140px;
				min-height: calc(100vh - 215px);
				flex-direction: column;
				justify-content: space-evenly;
				margin-bottom: 5rem;
				@include bdown(xxs) {
					margin-top: 120px;
					min-height: calc(100vh - 195px);
				}
				a {
					width: 100%;
					text-align: center;
				}
			}
		}
	}
}
