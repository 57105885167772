.footer-wrapper {
	background-color: $olive;
	padding: 3rem 1rem 0 1rem;
	.logo-wrapper {
		max-width: 370px;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 3rem;
		&-list {
			margin-top: 1rem;
			font-size: rem(13);
			color: $white;
			p {
				@include letter-spacing(100);
			}
			ul {
				display: flex;
				justify-content: space-between;
				align-items: center;
				column-gap: 1rem;
				@include bdown(md) {
					justify-content: space-evenly;
				}
			}
		}
	}
	.menu-wrapper {
		max-width: 1100px;
		margin: auto;
		margin-bottom: 2rem;
		padding-left: 2rem;
		padding-right: 2rem;
		ul {
			display: flex;
			justify-content: space-between;
			align-items: center;
			column-gap: 1rem;
			font-size: rem(17);
			color: $white;
			@include bdown(md) {
				justify-content: space-evenly;
				flex-wrap: wrap;
				li {
					margin-bottom: 1rem;
				}
			}
		}
	}
	.social-wrapper {
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 2rem;
		ul {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: rem(22);
			color: $white;
			li {
				padding-right: 1rem;
			}
		}
	}
	.copyright-wrapper {
		text-align: center;
		p {
			font-size: rem(17);
			color: $gray2;
			margin-bottom: 0;
		}
        @include bdown (xs) {
            margin-bottom: 4rem;
        }
                
	}
	.footer-button {
		text-align: end;
		margin-top: -2rem;
		margin-right: -1rem;
	}
}
