.text {
	&-yellow {
		color: $yellow;
	}
	&-purple {
		color: $purple;
	}
	&-white {
		color: $white;
	}
	&-beige {
		color: $beige;
	}
	&-gray {
		color: $gray;
	}
	&-black {
		color: $black;
	}
	&-black-alt {
		color: $blackalt;
	}
	&-base {
		color: $base;
	}
	&-darkgray {
		color: $darkgray;
	}
	&-orange {
		color: $orange;
	}
	&-scarlet {
		color: $scarlet;
	}
	&-red {
		color: $red;
	}
	&-pink {
		color: $pink;
	}
	&-blue {
		color: $blue;
	}
	&-darkblue {
		color: $darkblue;
	}
	&-green {
		color: $green;
	}
}
.bg {
	&-yellow {
		background-color: $yellow;
	}
	&-purple {
		background-color: $purple;
	}
	&-white {
		background-color: $white;
	}
	&-beige {
		background-color: $beige;
	}
	&-gray {
		background-color: $gray;
	}
	&-gray3 {
		background-color: $gray3;
	}
	&-darkgray {
		background-color: $darkgray;
	}
	&-black {
		background-color: $black;
	}
	&-black-alt {
		background-color: $blackalt;
	}
	&-base {
		background-color: $base;
	}
	&-orange {
		background-color: $orange;
	}
	&-scarlet {
		background-color: $scarlet;
	}
	&-red {
		background-color: $red;
	}
	&-pink {
		background-color: $pink;
	}
	&-blue {
		background-color: $blue;
	}
	&-green {
		background-color: $green;
	}
}
body.loaded {
	a,
	button {
		transition: all 0.4s ease-in-out;
	}
}
a,
button {
	font-family: $ropa;
	&:not(.disabled):not(:disabled) {
		cursor: pointer;
	}
	&.disabled,
	&:disabled {
		cursor: default;
		pointer-events: none;
	}
	transition: none;
}
a {
	text-decoration: underline;
	&:not(.btn) {
		&:hover {
			opacity: 0.75;
		}
	}
}
html {
	font-size: 20px;
	font-family: $ropa;

	@include bdown(md) {
		font-size: 16px;
	}
}
body {
	line-height: 1.5;
	color: $base;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: normal;
}
h1 {
	font-size: em(50);
	@include letter-spacing(-47);
	line-height: 1.06;
}
h2 {
	font-size: em(46);
	line-height: 1.15;
}
h3 {
	font-family: $amalfi;
	font-size: em(42);
	line-height: 1.15;
	@include letter-spacing(-47);
}
h4 {
	font-size: em(30);
	line-height: 1.15;
}
h5 {
	font-size: em(24);
}
h6 {
	font-size: 1em;
}
p,
ul,
ol {
	margin-bottom: 1em;
	@include letter-spacing(50);
}
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 0.6em;
}
blockquote {
	border-left: rem(10) solid currentColor;
	padding: 2rem;
	margin-left: 2rem;
	margin-bottom: 1rem;
	font-style: italic;
	& > *:last-child {
		margin-bottom: 0;
	}
}
section {
	ul {
		li {
			list-style-type: disc;
			margin-left: 1em;
		}
	}
	ol {
		li {
			margin-left: 1em;
		}
	}
}
header a,
footer a {
	display: inline-block;
}
.design-block {
	a:not(.default) {
		text-decoration: none;
		&:not(.btn) {
			&:hover {
				opacity: 1;
			}
		}
	}
	ul:not(.default),
	ol:not(.default) {
		li {
			list-style-type: none;
		}
	}
}
img {
	&.img-fw {
		width: 100%;
	}
}
a {
	obj {
		pointer-events: none;
	}
}

.upload-btn {
	padding: 0.3rem 0.5rem;
	max-height: 61px;
	text-align: center;
	text-decoration: none;
	font-size: 0.9rem;
	font-weight: 400;
	@include letter-spacing(50);
	border: 1px solid $darkblue;
	color: $white;
	background-color: $darkblue;
	display: inline-block;
	&.has-danger {
		border: 1px solid #f00 !important;
		color: #f00 !important;
		background-color: $white;
		&::before {
			color: #f00;
		}
	}
	&::before {
		color: $white;
		content: "\e903";
		padding-right: 0.7rem;
		vertical-align: middle;
		display: inline-block;
		font-family: $icomoon;
		font-size: rem(30);
		@include transition;
	}
	@include bup(md) {
		min-width: 240px;
	}
	&:hover {
		border: 1px solid $darkblue;
		background-color: transparent;
		color: $darkblue;
		&::before {
			color: $darkblue;
		}
	}
}

.back-btn {
	padding: 0.8rem 1rem;
	text-align: center;
	text-decoration: none;
	font-size: 0.9rem;
	font-weight: 400;
	@include letter-spacing(50);
	border: 1px solid $black;
	color: $black;
	background-color: transparent;
	display: inline-block;
	&::before {
		color: $white;
		content: "\e901";
		padding-right: 0.4rem;
		vertical-align: middle;
		display: inline-block;
		font-family: $icomoon;
		font-size: rem(10);
		@include transition;
	}
	@include bup(md) {
		min-width: 240px;
	}
	&-darkblue {
		border: 1px solid $darkblue;
		color: $darkblue;
		&:hover {
			background-color: $darkblue;
			color: $white;
			&::before {
				color: $white;
			}
		}
		&-inverted {
			border: 1px solid $darkblue;
			background-color: $darkblue;
			color: $white;
			&:hover {
				border: 1px solid $darkblue;
				background-color: transparent;
				color: $darkblue;
				&::before {
					color: $darkblue;
				}
			}
		}
	}
}

.btn {
	padding: 0.8rem 1.5rem;
	text-align: center;
	text-decoration: none;
	font-size: 0.9rem;
	font-weight: 400;
	// @include fluid-font(14px);
	@include letter-spacing(50);
	border: 1px solid $black;
	color: $black;
	background-color: transparent;
	display: inline-block;
	&.no-arrow {
		&::after {
			content: "";
		}
	}
	&::after {
		color: $white;
		content: "\e902";
		padding-left: 0.4rem;
		vertical-align: middle;
		display: inline-block;
		font-family: $icomoon;
		font-size: rem(10);
		@include transition;
	}
	&.active,
	&:hover {
		background-color: $black;
		color: $white;
		&::after {
			color: $black;
		}
	}
	@include bup(md) {
		min-width: 240px;
	}
	&.bold {
		font-weight: 700;
	}
	&-small {
		// @include fluid-font(12px);
		font-size: 0.9rem;
		@include bup(md) {
			min-width: 182px;
		}
	}
	&-large {
		// @include fluid-font(20px);
		font-size: 0.9rem;

		@include bup(md) {
			min-width: 328px;
		}
	}
	&-inverted {
		background-color: $black;
		color: #fff;
		&:hover {
			background-color: $white;
			color: $black;
		}
	}
	&-white {
		background-color: $white;
		&:hover {
			background-color: $black;
			color: $white;
		}
	}
	&-darkblue {
		border: 1px solid $darkblue;
		color: $darkblue;
		&:hover {
			background-color: $darkblue;
			color: $white;
			&::after {
				color: $white;
			}
		}
		&-inverted {
			border: 1px solid $darkblue;
			background-color: $darkblue;
			color: $white;
			&:hover {
				border: 1px solid $darkblue;
				background-color: transparent;
				color: $darkblue;
				&::after {
					color: $darkblue;
				}
			}
			&-light {
				border: 1px solid $darkblue;
				background-color: $darkblue;
				color: $white;
				&:hover {
					border: 1px solid $gray;
					background-color: transparent;
					color: $gray;
					&::after {
						color: $gray;
					}
				}
			}
		}
	}
	&-olive {
		border: 1px solid $olive;
		color: $olive;
		&:hover {
			background-color: $olive;
			color: $white;
			&::after {
				color: $white;
			}
		}
		&-inverted {
			border: 1px solid $olive;
			background-color: $olive;
			color: $white;
			&:hover {
				border: 1px solid $olive;
				background-color: transparent;
				color: $olive;
				&::after {
					color: $olive;
				}
			}
			&-light {
				border: 1px solid $olive;
				background-color: $olive;
				color: $white;
				&:hover {
					border: 1px solid $gray;
					background-color: transparent;
					color: $gray;
					&::after {
						color: $gray;
					}
				}
			}
		}
	}

	&-gray {
		border: 1px solid $gray;
		color: $gray;
		&:hover {
			background-color: $gray;
			color: $black;
		}
		&-inverted {
			border: 1px solid $gray;
			background-color: $gray;
			color: $black;
			&:hover {
				border: 1px solid $gray;
				// background-color: $black;
				background-color: transparent;
				color: $gray;
			}
		}
	}
	&-darkgray {
		border: 1px solid $base;
		color: $base;
		&:hover {
			background-color: $base;
			color: $white;
			&::after {
				color: $white;
			}
		}
		&-inverted {
			border: 1px solid $base;
			background-color: $base;
			color: $white;
			&:hover {
				border: 1px solid $base;
				background-color: transparent;
				color: $base;
				&::after {
					color: $base;
				}
			}
		}
	}
	&-yellow {
		border: 1px solid $yellow;
		color: $yellow;
		&::after {
			color: $yellow;
		}
		&:hover {
			background-color: $yellow;
			color: $black;
		}
		&-inverted {
			border: 1px solid $yellow;
			background-color: $yellow;
			color: $white;
			&::after {
				color: $white;
			}
			&:hover {
				border: 1px solid $yellow;
				// background-color: $black;
				background-color: transparent;
				color: $yellow;
				&::after {
					color: $yellow;
				}
			}
		}
	}
	&-purple {
		border: 1px solid $purple;
		color: $purple;
		&:hover {
			background-color: $purple;
			color: $white;
		}
		&-inverted {
			border: 1px solid $white;
			background-color: $purple;
			color: $white;
			&:hover {
				border: 1px solid $purple;
				// background-color: $white;
				background-color: transparent;
				color: $purple;
			}
		}
	}
	&-pink {
		border: 1px solid $pink;
		color: $pink;
		&:hover {
			background-color: $pink;
			color: $base;
		}
		&-inverted {
			// border: 1px solid $white;
			border: 1px solid $pink;
			background-color: $pink;
			color: $base;
			&:hover {
				border: 1px solid $pink;
				// background-color: $white;
				background-color: transparent;
				color: $pink;
			}
		}
	}
	&-blue {
		border: 1px solid $blue;
		color: $blue;
		&:hover {
			background-color: $blue;
			color: $base;
		}
		&-inverted {
			// border: 1px solid $white;
			border: 1px solid $blue;
			background-color: $blue;
			color: $base;
			&:hover {
				border: 1px solid $blue;
				// background-color: $white;
				background-color: transparent;
				color: $blue;
			}
		}
	}
	&-green {
		border: 1px solid $green;
		color: $green;
		&:hover {
			background-color: $green;
			color: $base;
		}
		&-inverted {
			// border: 1px solid $white;
			border: 1px solid $green;
			background-color: $green;
			color: $base;
			&:hover {
				border: 1px solid $green;
				// background-color: $white;
				background-color: transparent;
				color: $green;
			}
		}
	}
	&-scarlet {
		border: 1px solid $scarlet;
		color: $scarlet;
		&:hover {
			background-color: $scarlet;
			color: $white;
		}
		&-inverted {
			background-color: $scarlet;
			border: 1px solid $scarlet;
			color: $white;
			&:hover {
				border: 1px solid $scarlet;
				// background-color: $white;
				background-color: transparent;
				color: $scarlet;
			}
		}
	}
	&-orange {
		border: 1px solid $orange;
		color: $orange;
		&:hover {
			background-color: $orange;
			color: $white;
		}
		&-inverted {
			background-color: $orange;
			color: $white;
			border: 1px solid $orange;
			&:hover {
				border: 1px solid $orange;
				// background-color: $white;
				background-color: transparent;
				color: $orange;
			}
		}
	}
	&-red {
		border: 1px solid $red;
		color: $red;
		&:hover {
			background-color: $red;
			color: $white;
		}
		&-inverted {
			background-color: $red;
			border: 1px solid $red;
			color: $white;
			&:hover {
				border: 1px solid $red;
				// background-color: $white;
				background-color: transparent;
				color: $red;
			}
		}
	}
	&-rounded {
		border-radius: 1rem;
	}
	&[class^="btn-animated"],
	&[class*=" btn-animated"] {
		border: none !important;
		position: relative;
		overflow: hidden;
		span {
			z-index: 5;
			position: relative;
		}
		&:hover {
			&:before {
				right: -50%;
				top: -50%;
			}
		}
		// overflow: hidden;
		&:before {
			content: "";
			display: block;
			position: absolute;
			z-index: 1;
			border-radius: 50%;
			top: -5px;
			transition: all 0.7s;
			width: 200%;
			height: 300%;
			right: 100%;
		}
		&[class*="-from-white"] {
			background-color: $white;
			color: $black;
		}
		&[class*="-from-black"] {
			background-color: $black;
			color: $white;
		}
		&[class*="-from-gray"] {
			background-color: $gray;
			color: $black;
		}
		&[class*="-from-darkgray"] {
			background-color: $darkgray;
			color: $white;
		}

		&[class*="-from-blue"] {
			background-color: $blue;
			color: $black;
		}
		&[class*="-from-green"] {
			background-color: $green;
			color: $black;
		}
		&[class*="-from-pink"] {
			background-color: $pink;
			color: $black;
		}
		&[class*="-from-yellow"] {
			background-color: $yellow;
			color: $white;
		}

		&[class*="-from-red"] {
			background-color: $red;
			color: $white;
		}
		&[class*="-from-orange"] {
			background-color: $orange;
			color: $white;
		}
		&[class*="-from-scarlet"] {
			background-color: $scarlet;
			color: $white;
		}
		&[class*="-from-purple"] {
			background-color: $purple;
			color: $white;
		}

		&[class*="-to-pink"] {
			&:hover {
				color: $black;
			}
			&:before {
				background-color: $pink;
			}
		}
		&[class*="-to-blue"] {
			&:hover {
				color: $black;
			}
			&:before {
				background-color: $blue;
			}
		}
		&[class*="-to-green"] {
			&:hover {
				color: $black;
			}
			&:before {
				background-color: $green;
			}
		}
		&[class*="-to-yellow"] {
			&:hover {
				color: $black;
			}
			&:before {
				background-color: $yellow;
			}
		}

		&[class*="-to-red"] {
			&:hover {
				color: $white;
			}
			&:before {
				background-color: $red;
			}
		}
		&[class*="-to-purple"] {
			&:hover {
				color: $white;
			}
			&:before {
				background-color: $purple;
			}
		}
		&[class*="-to-scarlet"] {
			&:hover {
				color: $white;
			}
			&:before {
				background-color: $scarlet;
			}
		}
		&[class*="-to-orange"] {
			&:hover {
				color: $white;
			}
			&:before {
				background-color: $orange;
			}
		}

		&[class*="-to-white"] {
			&:hover {
				color: $black;
			}
			&:before {
				background-color: $white;
			}
		}
		&[class*="-to-black"] {
			&:hover {
				color: $white;
			}
			&:before {
				background-color: $black;
			}
		}
		&[class*="-to-gray"] {
			&:hover {
				color: $black;
			}
			&:before {
				background-color: $gray;
			}
		}
		&[class*="-to-darkgray"] {
			&:hover {
				color: $white;
			}
			&:before {
				background-color: $gray;
			}
		}
	}
}
.table-container {
	overflow-x: auto;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
	font-size: inherit;
	td,
	th {
		vertical-align: top;
		border: 1px solid $gray;
		padding: 5px;
	}
	th {
		font-weight: 700;
		text-align: center;
	}
	&.text {
		&-left {
			td,
			th {
				text-align: left;
			}
		}
		&-right {
			td,
			th {
				text-align: right;
			}
		}
		&-center {
			td,
			th {
				text-align: center;
			}
		}
	}
	&.table-inverted {
		color: $beige;
	}
	&.table-valign {
		&-top {
			td,
			th {
				vertical-align: top;
			}
		}
		&-middle {
			td,
			th {
				vertical-align: middle;
			}
		}
		&-bottom {
			td,
			th {
				vertical-align: bottom;
			}
		}
	}
}
xmp {
	font-size: 0.7rem;
	font-family: monospace;
}
.code-container {
	overflow-x: auto;
	background-color: $gray;
}

@keyframes moveInBottom {
	0% {
		opacity: 0;
		transform: translateY(30px);
	}

	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}
