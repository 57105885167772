.slider-wrapper {
	position: relative !important;

	.slick-track,
	.slick-list {
		-webkit-perspective: 2000;
		-webkit-backface-visibility: hidden;
	}

	&.init {
		min-height: 200px;
		max-height: 30vh;
		.slick-slider {
			opacity: 0;
		}
	}
	.preloader-overlay {
		position: absolute;
		background: transparent;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: 5;

		.preloader {
			position: absolute !important;
			top: 50%;
			left: 50%;
			width: 6rem;
			height: 6rem;
			margin-top: -3rem;
			margin-left: -3rem;
			background-image: url(../../images/fw-slider/new-loading.gif);
			background-repeat: no-repeat;
			background-size: cover;
		}
	}

	.prev-arrow,
	.next-arrow {
		position: absolute;
		text-decoration: none;
		transition: 300ms ease-in-out;
		z-index: 5;
	}

	.olive-prev-arrow {
		right: 75px;
		background-color: $olive;
		width: 75px;
		height: 73px;
		display: flex;
		align-items: center;
		justify-content: center;

		&.icon-arrow-left:before {
			content: "\e901";
			font-family: $icomoon;
			color: $white;
			font-size: rem(20);
		}
		&:hover {
			filter: brightness(120%);
		}
		&::after {
			content: "";
			position: absolute;
			right: 0;
			top: 14%;
			bottom: 14%;
			width: 1px;
			background-color: #fff;
		}

		@include bdown(sm) {
			width: 65px;
			height: 63px;
			right: 65px;
		}

		@include bdown(xs) {
			width: 60px;
			height: 58px;
			right: 60px;
		}
	}

	.olive-next-arrow {
		background-color: $olive;
		width: 75px;
		height: 73px;
		display: flex;
		align-items: center;
		justify-content: center;
		right: 0;
		&.icon-arrow-right:before {
			content: "\e902";
			font-family: $icomoon;
			color: $white;
			font-size: rem(20);
		}

		&:hover {
			filter: brightness(120%);
		}

		@include bdown(sm) {
			width: 65px;
			height: 63px;
		}

		@include bdown(xs) {
			width: 60px;
			height: 58px;
		}
	}

	.white-prev-arrow {
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		background-color: $white;
		width: 75px;
		height: 73px;
		display: flex;
		align-items: center;
		justify-content: center;

		&.icon-arrow-left:before {
			content: "\e901";
			font-family: $icomoon;
			color: $darkblue;
			font-size: rem(20);
		}
		&:hover {
			filter: brightness(120%);
		}
		@include bdown(md) {
			width: 70px;
			height: 68px;
		}

		@include bdown(sm) {
			width: 65px;
			height: 63px;
		}

		@include bdown(xs) {
			width: 50px;
			height: 48px;
		}
	}

	.white-next-arrow {
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		background-color: $white;
		width: 75px;
		height: 73px;
		display: flex;
		align-items: center;
		justify-content: center;
		&.icon-arrow-right:before {
			content: "\e902";
			font-family: $icomoon;
			color: $darkblue;
			font-size: rem(20);
		}

		&:hover {
			filter: brightness(120%);
		}
		@include bdown(md) {
			width: 70px;
			height: 68px;
		}

		@include bdown(sm) {
			width: 65px;
			height: 63px;
		}

		@include bdown(xs) {
			width: 50px;
			height: 48px;
		}
	}

	.transp-prev-arrow {
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		height: 30px;

		&.icon-arrow-left:before {
			content: "\e901";
			font-family: $icomoon;
			color: $beige;
			font-size: rem(25);
		}
		&:hover {
			filter: brightness(120%);
		}
	}

	.transp-next-arrow {
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		height: 30px;

		&.icon-arrow-right:before {
			content: "\e902";
			font-family: $icomoon;
			color: $beige;
			font-size: rem(25);
		}

		&:hover {
			filter: brightness(120%);
		}
	}

	.white-top-arrow {
		top: 50%;
		transform: translateY(-50%);
		background-color: $white;
		width: 75px;
		height: 73px;
		display: flex;
		align-items: center;
		justify-content: center;

		&.icon-arrow-left:before {
			content: "\e903";
			font-family: $icomoon;
			color: #cfcfcf;
			font-size: rem(20);
		}

		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 14%;
			right: 14%;
			height: 1px;
			background-color: #cfcfcf;
		}

		&:hover {
			filter: brightness(120%);
		}
		@include bdown(md) {
			width: 70px;
			height: 68px;
		}

		@include bdown(sm) {
			width: 65px;
			height: 63px;
			right: 65px;
		}

		@include bdown(xs) {
			width: 50px;
			height: 48px;
			right: 50px;
		}
	}

	.white-bot-arrow {
		top: calc(50% + 72px);
		transform: translateY(-50%);
		background-color: $white;
		width: 75px;
		height: 73px;
		display: flex;
		align-items: center;
		justify-content: center;
		@include bdown(md) {
			top: calc(50% + 68px);
		}
		@include bdown(sm) {
			top: calc(50% + 62px);
		}
		@include bdown(xs) {
			top: calc(50% + 48px);
		}
		&.icon-arrow-right:before {
			content: "\e900";
			font-family: $icomoon;
			color: #cfcfcf;
			font-size: rem(20);
		}

		&:hover {
			filter: brightness(120%);
		}
		@include bdown(md) {
			width: 70px;
			height: 68px;
		}

		@include bdown(sm) {
			width: 65px;
			height: 63px;
		}

		@include bdown(xs) {
			width: 50px;
			height: 48px;
		}
	}

	.olive-top-arrow {
		top: 50%;
		transform: translateY(-50%);
		background-color: $olive;
		width: 75px;
		height: 73px;
		display: flex;
		align-items: center;
		justify-content: center;

		&.icon-arrow-left:before {
			content: "\e903";
			font-family: $icomoon;
			color: $white;
			font-size: rem(20);
		}

		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 14%;
			right: 14%;
			height: 1px;
			background-color: $white;
		}

		&:hover {
			filter: brightness(120%);
		}
		@include bdown(md) {
			width: 70px;
			height: 68px;
		}

		@include bdown(sm) {
			width: 65px;
			height: 63px;
		}

		@include bdown(xs) {
			width: 50px;
			height: 48px;
		}
	}

	.olive-bot-arrow {
		top: calc(50% + 72px);
		transform: translateY(-50%);
		background-color: $olive;
		width: 75px;
		height: 73px;
		display: flex;
		align-items: center;
		justify-content: center;
		@include bdown(md) {
			top: calc(50% + 68px);
		}
		@include bdown(sm) {
			top: calc(50% + 62px);
		}
		@include bdown(xs) {
			top: calc(50% + 48px);
		}
		&.icon-arrow-right:before {
			content: "\e900";
			font-family: $icomoon;
			color: $white;
			font-size: rem(20);
		}

		&:hover {
			filter: brightness(120%);
		}
		@include bdown(md) {
			width: 70px;
			height: 68px;
		}

		@include bdown(sm) {
			width: 65px;
			height: 63px;
		}

		@include bdown(xs) {
			width: 50px;
			height: 48px;
		}
	}
}
.main-slider {
	.slider-wrapper {
		&:after {
			position: absolute;
			display: block;
			content: "";
			top: 0;
			bottom: 60%;
			left: 0;
			width: 100%;
			background-image: linear-gradient(
				to bottom,
				rgba(255, 255, 255, 0.92) 0,
				rgba(255, 255, 255, 0.92) 20%,
				transparent 100%
			);
		}
	}
}
.fw-slider {
	overflow: hidden;
	.prev-arrow,
	.next-arrow {
		bottom: 96px;
		@include bdown(md) {
			bottom: 80px;
		}
		@include bdown(xs) {
			bottom: 70px;
		}
	}
	.slick-track {
		display: flex;

		.slick-slide {
			position: relative;
			display: flex;
			height: inherit;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;

			img {
				object-fit: cover;
				width: 100%;
			}
		}
	}
}

.second-top-slider {
	overflow: hidden;
	.prev-arrow,
	.next-arrow {
		bottom: 0;
	}
	.slick-track {
		display: flex;

		.slick-slide {
			position: relative;
			display: flex;
			align-items: flex-end;
			height: inherit;
			background-repeat: no-repeat;
			width: 65%;
			background-position: right;
			@include bdown(md) {
				background-size: cover;
			}
			&:before {
				content: "";
				position: absolute;
				left: 0;
				right: 15%;
				top: 0;
				bottom: 0;
				z-index: 5;
				background: linear-gradient(
					to right,
					#f3f4f6 40%,
					rgba(#fdfdfd, 0)
				);
			}
			&::after {
				content: "";
				position: absolute;
				left: 30%;
				right: 30%;
				top: 0;
				bottom: 0;
				z-index: 10;
				background: linear-gradient(
					to right,
					#f3f4f6 50%,
					rgba(#fdfdfd, 0)
				);
				@include bdown(md) {
					background: none;
				}
			}
			img {
				object-fit: cover;
				width: 100%;
			}
		}
	}
	&-slide-container {
		z-index: 20;
		max-width: 700px;
		margin-top: 4rem;
		margin-bottom: 4rem;
		margin-right: 1rem;
		margin-left: 300px;
		h2,
		h3,
		h5 {
			color: $darkblue;
		}
		h2,
		h3 {
			@include letter-spacing(-47);
		}
		h2 {
			margin-bottom: 0.5rem;
		}
		h5 {
			@include letter-spacing(160);
			margin-bottom: 1.5rem;
		}
		@include bdown(md) {
			margin-left: 260px;
		}
		@include bdown(sm) {
			margin: 15rem 1rem 3rem 1rem;
		}
	}
}

.fw-slider-large,
.second-top-slider-large,
.case-slider-large {
	.slick-slide {
		min-height: 80vh;
	}
}

.fw-slider-middle,
.second-top-slider-middle,
.case-slider-middle {
	.slick-slide {
		min-height: 60vh;
	}
}

.fw-slider-small,
.second-top-slider-small,
.case-slider-small {
	.slick-slide {
		min-height: 40vh;
	}
}

.industries-slider {
	&.slider-container {
		position: relative;
		max-width: 1780px;
		margin: auto;
		padding-left: 56px;
		padding-right: 56px;
		@include bdown(sm) {
			padding-left: 51px;
			padding-right: 51px;
		}
		@include bdown(xs) {
			padding-left: 45px;
			padding-right: 45px;
		}
	}
	.slick-track {
		display: flex;
		justify-content: space-between;
	}
	.slick-slide {
		max-width: 370px;
		margin: 0 1rem;
	}

	&-slide {
		position: relative;
		border-radius: 5px;
		display: block;
		&::before {
			content: "";
			background: rgba($color: $olive, $alpha: 0.72);
			position: absolute;
			height: 100%;
			width: 100%;
			opacity: 0;
			top: 0;
			left: 0;
			@include transition;
		}
		&::after {
			content: "\e904";
			font-family: $icomoon;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 73px;
			height: 73px;
			background-color: $green;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			opacity: 0;
			color: $white;
			font-size: rem(30);
			@include transition;
		}
		&:hover {
			opacity: 1 !important;
			&::before,
			&::after {
				opacity: 1 !important;
			}
		}
		&-image {
			&::after {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				right: 0;
				top: 40%;
				background: linear-gradient(
					to top,
					rgba(#37391a, 1),
					rgba(#e2f419, 0)
				);
			}

			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
				border-radius: 5px;
			}
		}

		&-info {
			background-color: $base;
			color: $white;
			width: 84%;
			padding: 1rem 3.5rem 1rem 1rem;
			position: absolute;
			bottom: 6%;
			left: 50%;
			transform: translateX(-50%);

			p {
				@include letter-spacing(20);
				margin-bottom: 0;
				@include bdown(nm) {
					font-size: rem(16);
				}
			}
			&-text {
				p {
					font-size: rem(16);

					@include bdown(nm) {
						font-size: rem(14);
					}
				}
			}
			&::before {
				content: "";
				position: absolute;
				left: 0;
				width: 4px;
				height: 49%;
				background-color: $yellow;
			}
			&::after {
				content: "\e905";
				font-family: $icomoon;
				position: absolute;
				width: 50px;
				height: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: $white;
				background-color: $olive;
				border-radius: 5px;
				right: 5%;
				top: 50%;
				transform: translateY(-50%);
				@include bdown(nm) {
					width: 45px;
					height: 45px;
				}
				@include bdown(sm) {
					width: 40px;
					height: 40px;
				}
			}
		}
	}
}

.response-slider {
	.white-top-arrow {
		left: 0;
		top: 50%;
	}

	.white-bot-arrow {
		left: 0;
	}
	.slick-track {
		display: flex;
		flex-direction: column;
		.slick-slide {
			display: flex;
			height: inherit;
		}
	}

	&-wrapper {
		display: flex;
		justify-content: space-between;
		column-gap: 1rem;
		padding-left: 80px;
		flex-grow: 1;
		@include bdown(md) {
			display: block;
			padding-left: 50px;
			margin: auto;
		}
		@include bdown(xs) {
			padding-left: 35px;
		}
	}
	&-slide {
		background-color: $white;
		max-width: 522px;
		padding: 1rem 1rem;
		flex-basis: 50%;
		display: flex;
		margin-bottom: 1rem;
		margin-top: 1rem;

		@include bdown(md) {
			margin-left: auto;
			margin-right: auto;
		}
		@include bdown(xs) {
			display: block;
		}
		&-image {
			flex-basis: 25%;
			display: flex;
			justify-content: center;
			align-items: start;
		}
		&-info {
			flex-basis: 75%;
			font-size: rem(21);
			color: $grayalt;
			&-sign {
				font-size: rem(18);
				color: $olive;
			}
			&-position {
				font-size: rem(18);
				font-style: italic;
				color: $gray1;
			}
		}
		p {
			@include letter-spacing(0);
			margin-bottom: 0;
		}
	}
}

.process-wrapper {
	transform: translateY(20%);
}

.process-slider-container {
	max-width: 1000px;
	margin: auto;
	padding-right: 80px;
	@include bdown(sm) {
		padding-right: 70px;
		.white-top-arrow {
			top: 40%;
		}
		.white-bot-arrow {
			top: calc(40% + 62px);
		}
	}
	@include bdown(xs) {
		padding-right: 55px;
		.white-bot-arrow {
			top: calc(40% + 48px);
		}
	}
	.white-top-arrow,
	.white-bot-arrow {
		right: 0;
	}

	.process-slider {
		&-slide {
			position: relative;
			padding-left: 2rem;
			@include bdown(sm) {
				padding-left: 0;
				padding-top: 2rem;
			}
			&-text {
				position: absolute;
				writing-mode: vertical-lr;
				transform: rotate(180deg);
				bottom: 26%;
				left: 0px;
				font-size: rem(30);
				color: $darkblue;
				p {
					margin-bottom: 0;
				}
				@include letter-spacing(160);
				@include bdown(sm) {
					top: 0;
					left: 5%;
					writing-mode: horizontal-tb;
					transform: rotate(0);
				}
			}
			&-image {
				flex-grow: 1;
			}
		}
	}
}

.team-slider-container {
	max-width: 85%;
	@include bdown(hg) {
		max-width: 82%;
	}
	@include bdown(lg) {
		max-width: 78%;
	}
	@include bdown(md) {
		max-width: 100%;
	}
	.team-slider {
		padding-left: 2rem;
		padding-right: 2rem;
		.slick-track {
			display: flex;
			justify-content: space-between;
		}

		&-slide {
			max-width: 335px;
			margin-left: 0.3rem;
			margin-right: 0.3rem;
			&-card {
				&-image {
					display: flex;
					img {
						width: 100%;
						height: 100%;
					}
				}
				&-info {
					background-color: $whitealt;
					padding: 1rem;
					p {
						margin-bottom: 0;
					}
					&-text {
						font-size: rem(25);
						color: $darkblue;
						@include letter-spacing(25);
					}
					&-sign {
						font-size: rem(17);
						color: $gray2;
					}
				}
			}
		}
	}
}

.history-slider-container {
	.history-slider-slide {
		padding-left: 5rem;

		@include bdown(md) {
			img {
				margin: auto;
			}
		}
		@include bdown(xs) {
			padding-left: 3.5rem;
		}
	}
}

.case-slider {
	.olive-prev-arrow {
		left: 3rem;
		bottom: 0;
		transform: translateY(149%);
		@include bdown(md) {
			left: 2rem;
			transform: translateY(129%);
		}
		@include bdown(sm) {
			transform: translateY(143%);
		}
		@include bdown(xs) {
			transform: translateY(167%);
			left: 1rem;
		}
		@include bdown(xxs) {
			left: 0.5rem;
		}
		&::after {
			content: "";
			width: 0;
		}
	}

	.olive-next-arrow {
		right: 3rem;
		bottom: 0;
		transform: translateY(149%);
		@include bdown(md) {
			right: 2rem;
			transform: translateY(129%);
		}
		@include bdown(sm) {
			transform: translateY(143%);
		}
		@include bdown(xs) {
			transform: translateY(167%);
			right: 1rem;
		}
		@include bdown(xxs) {
			right: 0.5rem;
		}
	}
}
